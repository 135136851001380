import {useUser} from "@/components/Providers/JWTProvider.tsx";
import {useCallback} from "react";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";

type ScriptResult = {
    message: string;
    errorCode: number;
    data: unknown;
}

const useCashPayment = () => {
    const {user} = useUser();
    const {cart, refreshCart, cartPaymentSuccess} = useCartContext();

    return useCallback(async (
        tenderedCents: number,
        payNowAmount: number,
    ) : Promise<string | undefined> => {
        if (!user || !window.FileMaker || !cart) {
            console.error("invalid context", !user || !window.FileMaker || !cart);
            return "An error occurred (-1)";
        }

        try {
            const fmCallBack = new Promise<string>((resolve) => {

                let resolved = false;
                window.FileMakerCallback = (response : string) => {
                    resolved = true;
                    resolve(`${response}`);
                };
                setTimeout(() => {
                    if (!resolved) {
                        resolve(JSON.stringify({
                            message: "FileMaker Call timed out",
                            errorCode: 100,
                            data: null,
                        }))
                    }
                }, 10_000);//wait 10 seconds
            });
            window.FileMaker.PerformScript("patchCartTenderedCents", JSON.stringify({
                cartId: cart?.id,
                tenderedCents: tenderedCents,
            }));

            const fmResponse = JSON.parse(await fmCallBack) as ScriptResult;
            if (fmResponse.errorCode !== 0) {
                console.error('fmResponse', fmResponse);
                return fmResponse.message ? `${fmResponse.message}` : "There was an error setting the tendered cash";
            }

            const url = new URL('/v1/payment/complete-cash-transaction', import.meta.env.VITE_APP_API_ENDPOINT);
            const response = await user.apiFetch(url, {
                method: "POST",
                body: JSON.stringify({
                    cartId: cart.id,
                    payNowAmount,
                }),
            });

            if (response.ok) {
                await cartPaymentSuccess(`Cash payment of $${payNowAmount.toString()} submitted.`);
                await refreshCart();
                return undefined;
            }

            const responseBody = await response.json();
            if ('hint' in responseBody && typeof responseBody.hint === 'string') {
                return responseBody.hint;
            }
        } catch (e) {
            console.error('error in useCashPayment', e);
        }
        return "There was an error.";
    }, [user, cart]);
}

export default useCashPayment;
