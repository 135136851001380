import React, {type ReactElement, useEffect, useMemo, useState} from "react";
import {Alert, Box, Button, Card, CircularProgress, Divider, Typography} from "@mui/material";
import CartPaymentForm, {buildPayAmount, stripePromise} from "@/components/Forms/CartPaymentForm.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {CreateSetupIntentResponse} from "@/types/CreateSetupIntentResponse.ts";
import {SetupIntent} from "@/types/SetupIntent.tsx";
import useStripeCreateSetupIntent from "@/hooks/useStripeCreateSetupIntent.tsx";
import {Elements} from "@stripe/react-stripe-js";
import TotalRow from "@/components/Cart/TotalRow.tsx";
import CartSummaryList from "@/components/Cart/CartSummaryList.tsx";
import {useUser} from "@/components/Providers/JWTProvider.tsx";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {getPaymentFormSchema} from "@/components/Forms/PaymentForm.tsx";
import {ProgramsProviderContext} from "@/components/Providers/ProgramsProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {z} from "zod";
import {useNavigate} from "@tanstack/react-router";
import TanMuiLink from "@/components/TanMuiLink.tsx";
import useCashPayment from "@/hooks/useCashPayment.ts";
import StandardPage from "@/components/StandardPage.tsx";
import {CheckCircle2} from "lucide-react";
import {CONNECTED, READY, useStripeTerminal} from "@/components/Providers/StripeTerminalProvider.tsx";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";
import useHandleMailInSubmit from "@/components/Cart/useHandleMailInSubmit.ts";
import {closeFMWindow} from "@/utils/fmScripts.ts";

type Props = {
    totalCost : number;
    cartIsEmpty : boolean;
    errorMessage: string;
    isPayButtonDisabled: boolean;
    triggerPayment: () => void;
};

const CartPannel = (
    {totalCost, cartIsEmpty, errorMessage, isPayButtonDisabled, triggerPayment} : Props
) => {
    return <Box sx={{
        width: {xs: '100%', md: '40%'},
        pl: {md: 2},
    }}>
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                px: 2,
                pt: 2,
            }}
        >
            <Typography
                variant={'h5'}
                sx={{
                    mx: 1,
                    py: 1,
                    color: '#000',
                }}
            >
                Summary
            </Typography>
            <Divider
                sx={{
                    mx: 1,
                }}
            />
            <CartSummaryList/>
            <TotalRow
                totalCost={totalCost}
                cartIsEmpty={cartIsEmpty}
                errorMessage={errorMessage}
                isPayButtonDisabled={isPayButtonDisabled}
                triggerPayment={triggerPayment}
                cartPage
            />
        </Card>
    </Box>
}

const CartPage = () : ReactElement => {
    const {isEmployee, isStudentOrCM, user} = useUser();
    const {getCartAlertStatus, resetCartAlertStatus, getCartTotal, cartIsEmpty, cart} = useCartContext();
    const totalCost = getCartTotal();
    const cartStatus = getCartAlertStatus();
    const [isSetup, setIsSetup] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
    const createSetupIntent = useStripeCreateSetupIntent();
    const [programsState] = React.useContext(ProgramsProviderContext);
    const {allAssessedFees} = useFeeProvider();
    const handelMailInSubmit = useHandleMailInSubmit();
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const [submitStripeValues, setSubmitStripeValues] = useState<any | null>(null);
    const [stripeErrorMessage, setStripeErrorMessage] = useState('');
    const processCashPayment = useCashPayment();
    const {paymentStatus, connected} = useStripeTerminal();
    const cartHasFees = useMemo(() => {
        return cart?.cartItems.some((item) => item.feeId !== undefined) ?? false;
    }, [cart]);

    const minimumPayment = useMemo(() => {
        if (!cart?.cartItems) {
            return 0;
        }

        return cart.cartItems.reduce((minimum, item) => {

            if (item.itemType === 'registration') {
                const course = programsState.programs
                    .flatMap(program => program.courses)
                    .find(course => course.courseSelectionUUID === item.courseSectionId);

                if (course) {
                    return minimum + (course.programAllowPartial ? course.cost * 0.1 : course.cost);
                }
            }

            if (item.itemType === 'fee') {
                const assessedFee = allAssessedFees
                    ?.find(assessedFee => assessedFee.id === item.assessedFeeId)

                if (assessedFee) {
                    return minimum + (assessedFee.fee.allowPartialPayment ? assessedFee.assessedCost * 0.1 : assessedFee.assessedCost);
                }
            }

            return minimum;
        }, 0);
    }, [programsState, cart, allAssessedFees]);

    const allowPartialPayment = useMemo(() => {
        if (!cart?.cartItems) {
            return false;
        }

        return cart.cartItems.some(item => {
            if (item.itemType === 'registration') {
                const course = programsState.programs
                    .flatMap(program => program.courses)
                    .find(course => course.courseSelectionUUID === item.courseSectionId);

                if (course && course.programAllowPartial) {
                    return true
                }
            }

            if (item.itemType === 'fee') {
                const assessedFee = allAssessedFees
                    ?.find(assessedFee => assessedFee.id === item.assessedFeeId)

                if (assessedFee?.fee.allowPartialPayment) {
                    return true
                }
            }

            return false;
        });
    }, [programsState, cart, allAssessedFees]);

    const initialValues = useMemo(() => ({
        paymentMethod: '',
        paymentType: allowPartialPayment ? '' : 'full',
        partialPaymentAmount: 0,
    }), [allowPartialPayment]);

    const schema = useMemo(
        () => getPaymentFormSchema(totalCost, minimumPayment)
        ,[totalCost, minimumPayment]
    );

    type CartPaymentFormSchemaType = z.infer<typeof schema>;

    const form = useForm<CartPaymentFormSchemaType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema)
    });
    const paymentTypeWatch = form.watch('paymentType');
    const paymentMethodWatch = form.watch('paymentMethod');
    const partialPaymentAmountWatch = form.watch('partialPaymentAmount');

    let isPayButtonDisabled = (!paymentTypeWatch && totalCost > 0)
        || (!paymentMethodWatch && totalCost > 0)
        || (paymentTypeWatch === 'partial' && (!partialPaymentAmountWatch || isNaN(partialPaymentAmountWatch)))
        || (paymentMethodWatch === 'terminal' && (connected !== CONNECTED || paymentStatus !== READY || stripeErrorMessage !== ''))
        || isProcessing;

    const payAmountRaw = paymentTypeWatch === 'partial' ? (partialPaymentAmountWatch ?? 0) : totalCost;
    const payAmount = payAmountRaw > 0 ? payAmountRaw : totalCost;

    useEffect(() => {
        void getSetupIntent();
        setIsSetup(true);
    }, [isSetup]);

    const getSetupIntent = async () => {
        setErrorMessage('');

        if (isSetup) {
            return;
        }

        const response = await createSetupIntent();

        if (response && response.ok) {
            const json = await response.json() as CreateSetupIntentResponse;

            if (json.errorCode !== 0) {
                setErrorMessage(json.message);
                return;
            }

            setSetupIntent(json.data as SetupIntent);

            return;
        } else {
            console.error('error getting setup intent', response);
            setErrorMessage('An unknown error has occurred.');
        }
    };

    const handleMailInSubmit = async (payNowAmount: number) => {
        setIsProcessing(true);
        try {
            await handelMailInSubmit(payNowAmount);
        } finally {
            setIsProcessing(false);
        }
    };

    if (cartIsEmpty) {
        return <StandardPage>
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    minHeight: '80vh',
                }}
            >
              <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      backgroundColor: '#fff',
                      px: 2,
                      pt: 2,
                      borderRadius: 1,
                  }}
              >
                <Box>
                    {isStudentOrCM && user.userType !== 'guest_user' && <>Your cart is currently empty. You can add registrations by visiting the <TanMuiLink
                        to="/programs"
                        sx={{
                            display: 'inline',
                        }}
                    >Register section.</TanMuiLink>
                    </>}
                    {isStudentOrCM && user.userType === 'guest_user' && <>Your cart is currently empty. You can add fees by visiting the <TanMuiLink
                        to="/fees"
                        sx={{
                            display: 'inline',
                        }}
                    >Fees section.</TanMuiLink>
                    </>
                    }
                    {isEmployee && <>Your cart is currently empty. You can add fees by visiting the <TanMuiLink
                        to="/instructor/fees"
                        sx={{
                            display: 'inline',
                        }}
                    >fees section.</TanMuiLink>
                    </>}
                </Box>
              </Box>
            </Box>
        </StandardPage>;
    }

    if (totalCost === 0) {
        return <StandardPage>
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    px: {xs: '15px', md: '49.5px'},
                    maxHeight: {md: '80vh'},
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '60%'},
                        px: 2,
                        pt: 2,
                        overflowY: 'auto',
                        backgroundColor: '#fff',
                        mb: 2,
                        borderRadius: 1,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <CheckCircle2 color="#16A34A" size={24} />
                        <Typography variant="h5" color="black">
                            Ready for Submission
                        </Typography>
                    </Box>
                    <Alert severity="info" sx={{ mb: 3 }}>
                        Your cart contains only free items. No payment is required. You can submit your order directly.
                    </Alert>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="success"
                            size="large"
                            sx={{
                                minWidth: 200,
                            }}
                            disabled={isProcessing || isPayButtonDisabled}
                            onClick={() => {
                                handleMailInSubmit(0).catch((e) => {
                                    console.error('error with mailin', e);
                                });
                            }}
                        >
                            {isProcessing && <><CircularProgress
                                size='1rem'
                                sx={{
                                    mr: 1,
                                    color: '#fff',
                                }}
                            /> Processing</>}
                            {!isProcessing && 'SUBMIT ORDER'}
                        </Button>
                    </Box>
                </Box>
                <CartPannel
                    totalCost={totalCost}
                    cartIsEmpty={cartIsEmpty}
                    errorMessage={errorMessage}
                    isPayButtonDisabled={isPayButtonDisabled}
                    triggerPayment={() => {
                        handleMailInSubmit(0).catch((e) => {
                            console.error('error with mailin', e);
                        });
                    }}
                />
            </Box>
        </StandardPage>
    }

    return <StandardPage>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(async (values, event) => {
                    event?.stopPropagation();

                    if (isPayButtonDisabled) {
                        return;
                    }

                    const payNowAmount = buildPayAmount(
                        values.paymentType,
                        totalCost,
                        values.partialPaymentAmount?.toString()
                    );

                    setStripeErrorMessage('');
                    setIsProcessing(true);
                    if (values.paymentMethod === 'stripe') {
                        setSubmitStripeValues(values);
                    } else if (values.paymentMethod === 'mail') {
                        await handleMailInSubmit(payNowAmount);
                    } else if (values.paymentMethod === 'cash') {
                        const response = await processCashPayment(
                            (values.tendered ?? 0) * 100,
                            payNowAmount,
                        );
                        if (response) {
                            form.setError('tendered', {
                                message: response,
                                type: 'custom',
                            });
                        } else {
                            closeFMWindow();
                            await navigate({
                                to: isEmployee ? '/instructor/fees' : cartHasFees ? '/fees' : '/my-registrations',
                                search: {purchased : 'purchased'},
                            });
                        }
                        setIsProcessing(false);

                    }
                })}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'row'},
                        px: {xs: '15px', md: '49.5px'},
                        maxHeight: {md: '80vh'},
                    }}
                >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '60%'},
                        px: 2,
                        pt: 2,
                        overflowY: 'auto',
                        backgroundColor: '#fff',
                        mb: 2,
                        borderRadius: 1,
                    }}
                >
                    <Typography
                        variant={'h5'}
                        sx={{
                            pt: 1,
                            pb: 1,
                            color: '#000',
                        }}
                    >
                        Payment Method
                    </Typography>
                    <Divider/>

                    {setupIntent && <Elements stripe={stripePromise} options={{
                        clientSecret: setupIntent.clientSecret,
                        appearance: {
                            theme: 'stripe',
                            variables: {
                                fontWeightNormal: '500',
                                borderRadius: '8px',
                                colorPrimary: '#000',
                                // @ts-ignore
                                tabIconSelectedColor: '#fff',
                                gridRowSpacing: '16px'
                            },
                            rules: {
                                '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
                                    boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
                                },
                                '.BlockDivider': {
                                    backgroundColor: '#ebebeb'
                                },
                                '.Tab--selected, .Tab--selected:hover': {
                                    backgroundColor: '#631d79',
                                    color: '#fff'
                                }
                            }
                        }
                    }}>
                        <CartPaymentForm
                            cost={totalCost}
                            allowPartialPayment={allowPartialPayment}
                            isProcessing={isProcessing}
                            setIsProcessing={setIsProcessing}
                            stripeErrorMessage={stripeErrorMessage}
                            setStripeErrorMessage={setStripeErrorMessage}
                            submitStripeValues={submitStripeValues}
                            setSubmitStripeValues={setSubmitStripeValues}
                            setupIntent={setupIntent}
                            cartHasFees={cartHasFees}
                            isPayButtonDisabled={isPayButtonDisabled}
                        />
                    </Elements>}
                </Box>
              <CartPannel
                  totalCost={payAmount}
                  cartIsEmpty={cartIsEmpty}
                  errorMessage={errorMessage}
                  isPayButtonDisabled={isPayButtonDisabled}
                  triggerPayment={() => {
                      form.handleSubmit(() => {})
                  }}
              />
            </Box></form></FormProvider>
            <GlenSnackBar onClose={resetCartAlertStatus} show={cartStatus.showAlert} severity={cartStatus.alertVariant} message={cartStatus.message}/>
        </StandardPage>
};

export default CartPage;
