import React, {useEffect, useState} from "react";
import {ProgramCourse, ProgramsProviderContext} from "../Providers/ProgramsProvider";
import ModalBase from "./ModalBase";
import PaymentForm from "@/components/Forms/PaymentForm.tsx";
import {CreateSetupIntentResponse} from "@/types/CreateSetupIntentResponse.ts";
import {SetupIntent} from "@/types/SetupIntent.tsx";
import useStripeCreateSetupIntent from "@/hooks/useStripeCreateSetupIntent.tsx";
import {stripePromise} from "@/components/Forms/CartPaymentForm.tsx";
import {Elements} from "@stripe/react-stripe-js";
import {Grid} from "@mui/material";

type Props = {
    course: ProgramCourse;
    programName: string;
    show: boolean;
    handleClose: (showCancelModal?: boolean, showConfirmModal?: boolean) => void;
    setConfirmationId: (confirmationId: string) => void;
    switchPayment?: boolean;
    courseHasCreditsApplied: boolean;
};

const PaymentModal = ({
    course,
    programName,
    show,
    handleClose,
    setConfirmationId,
    switchPayment,
    courseHasCreditsApplied,
} : Props) => {
    const [, performRegistrationAction] = React.useContext(ProgramsProviderContext);
    const [cancellationPending, setCancellationPending] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
    const createSetupIntent = useStripeCreateSetupIntent();

    useEffect(() => {
        void getSetupIntent();
    }, [show]);

    const getSetupIntent = async () => {
        setErrorMessage('');

        if (show) {
            const response = await createSetupIntent();

            if (response && response.ok) {
                const json = await response.json() as CreateSetupIntentResponse;

                if (json.errorCode !== 0) {
                    setErrorMessage(json.message);
                    return;
                }

                setSetupIntent(json.data as SetupIntent);

                return;
            } else {
                setErrorMessage('An unknown error has occurred.');
            }
        }
    };

    const cancelRegistration = async (showCancelModal: boolean, reason : string) => {
        if (performRegistrationAction) {
            // cancel registration
            await performRegistrationAction(course, programName, reason);
        }

        localStorage.removeItem('registration-course-' + course.courseSelectionUUID);
        setCancellationPending(false);
        handleClose(showCancelModal);
    }

    const handleLocalCancelOrClose = async () => {
        if(!courseHasCreditsApplied) {
            if (cancellationPending) {
                await cancelRegistration(false, 'Clicked cancel on payment');
            } else {
                if (!switchPayment) {
                    setCancellationPending(true);
                } else {
                    handleClose(false, false);
                }
            }
        } else {
            handleClose(false, false);
        }
    }

    return (
        <ModalBase
            show={show}
            title="Payment Options"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleLocalCancelOrClose}
        >
            {setupIntent && <Elements stripe={stripePromise} options={{
                clientSecret: setupIntent.clientSecret,
                appearance: {
                    theme: 'stripe',
                    variables: {
                        fontWeightNormal: '500',
                        borderRadius: '8px',
                        colorPrimary: '#000',
                        // @ts-ignore
                        tabIconSelectedColor: '#fff',
                        gridRowSpacing: '16px'
                    },
                    rules: {
                        '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
                            boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
                        },
                        '.BlockDivider': {
                            backgroundColor: '#ebebeb'
                        },
                        '.Tab--selected, .Tab--selected:hover': {
                            backgroundColor: '#631d79',
                            color: '#fff'
                        }
                    }
                }
            }}>
            <PaymentForm
                course={course}
                programName={programName}
                handleClose={handleClose}
                handleLocalCancelOrClose={handleLocalCancelOrClose}
                setConfirmationId={setConfirmationId}
                courseHasCreditsApplied={courseHasCreditsApplied}
                switchPayment={switchPayment}
                show={show}
                cancellationPending={cancellationPending}
                setCancellationPending={setCancellationPending}
            />
            </Elements>}
            {errorMessage && <Grid item xs={12} sx={{color: 'red'}}>
                {errorMessage}
            </Grid>}
        </ModalBase >);
}

export default PaymentModal;
