import {useElements, useStripe} from "@stripe/react-stripe-js";
import {Dispatch, SetStateAction, useEffect, useMemo} from "react";
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import {Box, Button, CircularProgress, Divider, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {formatter} from "@/Helpers/formatter.ts";
import PaymentFormPaymentAmount from "@/components/Forms/Fields/PaymentFormPaymentAmount.tsx";
import StripeForm from "@/components/StripeForm.tsx";
import {loadStripe} from "@stripe/stripe-js";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import ButtonCollection from "@/components/Forms/Fields/ButtonCollection.tsx";
import {StripeTerminal} from "../Cart/StripeTerminal";
import {SetupIntent} from "@/types/SetupIntent.tsx";
import {useFormContext} from "react-hook-form";
import {useStripeTerminal} from "@/components/Providers/StripeTerminalProvider.tsx";
import CashTender from "@/components/Cart/CashTender.tsx";
import {PaymentFormSchemaType} from "@/components/Forms/PaymentForm.tsx";

export const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_KEY as string);

stripePromise.catch(e => console.error('stripePromise', e));

type Props = {
    cartHasFees: boolean;
    cost: number;
    allowPartialPayment: boolean;
    isProcessing: boolean;
    setIsProcessing: Dispatch<SetStateAction<boolean>>;
    stripeErrorMessage: string;
    setStripeErrorMessage: Dispatch<SetStateAction<string>>;
    submitStripeValues: any;
    setSubmitStripeValues: Dispatch<SetStateAction<any>>;
    setupIntent: SetupIntent;
    isPayButtonDisabled: boolean;
};

const ErrorFallback = ({error, resetErrorBoundary}: FallbackProps) => {
    return (
        <div role="alert">
            <p>There was a issue loading the credit card payments</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
};

export const buildPayAmount = (paymentType: string | undefined, cost: number, partialPaymentAmount: string | undefined): number => {
    return paymentType && partialPaymentAmount && paymentType === 'partial' ? parseFloat(partialPaymentAmount) : cost;
}

const CartPaymentForm = ({
    cartHasFees,
    cost,
    allowPartialPayment,
    isProcessing,
    setIsProcessing,
    stripeErrorMessage,
    setStripeErrorMessage,
    submitStripeValues,
    setSubmitStripeValues,
    setupIntent,
    isPayButtonDisabled,
}: Props) => {
    const {cart, getOriginalCartTotal} = useCartContext();
    const {inFileMaker, terminal} = useStripeTerminal();
    const stripe = useStripe();
    const elements = useElements();

    const form = useFormContext<PaymentFormSchemaType>();
    const formHasErrors = Object.keys(form.formState.errors).length > 0;
    const paymentMethodWatch = form.watch('paymentMethod');
    const paymentTypeWatch = form.watch('paymentType');
    const partialPaymentAmountWatch = form.watch('partialPaymentAmount');

    useEffect(() => {
        if (paymentMethodWatch) {
            form.setValue('paymentMethod', '');
        }
    }, [inFileMaker]);

    let payNowAmount = buildPayAmount(
        paymentTypeWatch,
        cost,
        partialPaymentAmountWatch?.toString()
    );

    useEffect(() => {
        if (stripeErrorMessage !== '') {
            setIsProcessing(false);
            setSubmitStripeValues(null);
        }
    }, [stripeErrorMessage]);

    useEffect(() => {
        if (paymentTypeWatch === 'partial' && !inFileMaker) {
            form.setValue('paymentMethod', 'stripe');
        }
    }, [paymentTypeWatch])

    const paymentMethods = useMemo(() => {
        return [
            paymentTypeWatch !== 'partial' && !cartHasFees ? {
                value: 'mail',
                label: 'Mail a Paper Check',
                disabled: !paymentTypeWatch || paymentTypeWatch === 'partial',
                hidden: paymentTypeWatch === 'partial'
            } : undefined,
            inFileMaker ? undefined : {
                value: 'stripe',
                label: 'Pay Online',
                disabled: !paymentTypeWatch || cost < .5,
                selected: paymentTypeWatch === 'partial'
            },
            inFileMaker ? {
                value: 'terminal',
                label: 'Terminal',
                disabled: !paymentTypeWatch || cost < .5 || !terminal,
            } : undefined,
            inFileMaker ? {
                value: 'cash',
                label: 'Cash',
                disabled: !paymentTypeWatch || cost <= 0,
            } : undefined,
        ].filter(method => !!method)
    }, [inFileMaker, paymentTypeWatch, terminal, cartHasFees]);

    useEffect(() => {
        if (paymentMethods.length === 1) {
            form.setValue('paymentMethod', paymentMethods[0]?.value)
        } else if (paymentMethods.length > 1) {
            if (paymentMethodWatch && !paymentMethods.some(m => m?.value === paymentMethodWatch)) {
                form.setValue('paymentMethod', '');
            }
        }
    }, [paymentMethods]);

    return (
        <>
            <Box>
                <Grid container>
                    {allowPartialPayment && <Grid size={{xs: 12}}>
                        <Box sx={{
                            fontWeight: 800,
                            fontSize: '120%',
                            pt: 2,
                            pb: 1,
                            color: '#000',
                        }}>
                            Payment Options
                        </Box>
                    </Grid>}
                    {allowPartialPayment && <Grid size={{xs: 12}}>
                        <ButtonCollection
                            name="paymentType"
                            options={[
                                {
                                    value: 'full',
                                    label: `Pay ${formatter.format(cost)} in Full`
                                },
                                {
                                    value: 'partial',
                                    label: 'Partial Payment',
                                    disabled: cost < .5 || cost < (.1 * getOriginalCartTotal())
                                }
                            ]}
                            sx={{
                                my: 1,
                            }}
                        />
                    </Grid>}
                    {paymentTypeWatch === 'partial' && <Grid size={{xs: 12}}>
                        <PaymentFormPaymentAmount name="partialPaymentAmount" label="Payment Amount"/>
                        <Grid size={{xs: 12}}>* Partial payment amount will be evenly distributed to each
                            registration</Grid>
                    </Grid>}
                    <Grid size={{xs: 12}}>
                        {allowPartialPayment && <Divider sx={{mt: 2}}/>}
                        <Box
                            sx={{
                                fontWeight: 800,
                                fontSize: '120%',
                                pt: 2,
                                pb: 1,
                                color: '#000',
                            }}
                        >
                            Payment Detail
                        </Box>
                        {paymentMethods.length > 1 && <ButtonCollection
                            name="paymentMethod"
                            options={paymentMethods}
                            sx={{
                                display: paymentTypeWatch === 'partial' && !inFileMaker ? 'none' : 'block',
                                mt: 1,
                                mb: 2,
                            }}
                        />}
                    </Grid>
                    {paymentMethodWatch === 'mail' && <Grid size={{xs: 12}}>
                        <Box>
                            <div>Submit all checks to:</div>
                            <div>Glenbrook High School District 225</div>
                            <div>ATTN: Enrollment Specialist</div>
                            <div>3801 W Lake Ave.</div>
                            <div>Glenview, IL 60026</div>
                            <div>BE SURE TO INDICATE THE PARTICIPANT'S NAME <span
                                style={{textDecoration: 'underline'}}>AND</span> ID NUMBER
                            </div>
                        </Box>
                    </Grid>}
                    {(paymentMethodWatch === 'stripe') &&
                            <Grid size={{xs: 12}}>
                                {
                                    (stripe === null || elements === null) ?
                                        <p>Loading credit card payment form. If this message persists, try
                                            reloading the page or clearing your browser cache.</p> :
                                        <ErrorBoundary
                                            FallbackComponent={ErrorFallback}
                                            onReset={() => {
                                                form.setValue('paymentMethod', '');
                                            }}
                                        >
                                            {cart && <StripeForm
                                                cartId={cart.id}
                                                courseSectionId={null}
                                                submitValues={submitStripeValues}
                                                payNowAmount={payNowAmount}
                                                setIsProcessing={setIsProcessing}
                                                stripeErrorMessage={stripeErrorMessage}
                                                setStripeErrorMessage={setStripeErrorMessage}
                                                cartHasFees={cartHasFees}
                                            />}
                                        </ErrorBoundary>
                                }
                            </Grid>}
                    {(paymentMethodWatch === 'terminal') &&
                      <StripeTerminal
                          setupIntent={setupIntent}
                          cost={cost}
                          courseSectionId={null}
                          isProcessing={isProcessing}
                          setIsProcessing={setIsProcessing}
                          stripeErrorMessage={stripeErrorMessage}
                          setStripeErrorMessage={setStripeErrorMessage}
                          cartHasFees={cartHasFees}
                      />}
                    {(paymentMethodWatch === 'cash') && <CashTender/>}
                </Grid>

                <Grid size={{xs: 12}} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        mt: 2,
                    }}
                >
                    {isProcessing && <Button
                        disabled={true}
                        sx={{
                            backgroundColor: '#631d79',
                            color: '#fff',
                            borderRadius: '8px',
                            fontWeight: 800,
                            p: 2,
                            mb: 2,
                            '&:hover': {
                                backgroundColor: '#631d79',
                            }
                        }}
                    >
                        <CircularProgress
                            size='1rem'
                            sx={{
                                mr: 1,
                                color: '#fff',
                            }}
                        />
                        <Box sx={{color: '#fff'}}>{`Processing Payment`}</Box>
                    </Button>}

                    {!isProcessing && isPayButtonDisabled &&
                        <Tooltip title={formHasErrors ? 'Please fix the validation issues above' : ''}>
                            <Button
                                variant='contained'
                                type={'submit'}
                                disabled={isPayButtonDisabled}
                                sx={{
                                    backgroundColor: '#631d79',
                                    color: '#fff',
                                    p: 2,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    '&:hover': {
                                        backgroundColor: '#631d79',
                                    }
                                }}
                            >
                                {`Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                            </Button>
                        </Tooltip>}

                    {!isProcessing && !isPayButtonDisabled && (form.formState.errors || stripeErrorMessage !== '') &&
                        <Tooltip title={formHasErrors ? 'Please fix the validation issues above' : ''}>
                            <Button
                                variant='contained'
                                type={'submit'}
                                sx={{
                                    backgroundColor: '#631d79',
                                    color: '#fff',
                                    p: 2,
                                    borderRadius: '8px',
                                    fontWeight: 800,
                                    mb: 2,
                                    '&:hover': {
                                        backgroundColor: '#631d79',
                                    }
                                }}
                            >
                                {payNowAmount === 0 && `Checkout Now`}
                                {payNowAmount !== 0 && `Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                            </Button>
                        </Tooltip>}

                    {!isProcessing && !isPayButtonDisabled && !(form.formState.errors || stripeErrorMessage !== '') &&
                        <Button
                            variant='contained'
                            type={'submit'}
                            sx={{
                                backgroundColor: '#631d79',
                                color: '#fff',
                                p: 2,
                                borderRadius: '8px',
                                fontWeight: 800,
                                mb: 2,
                                '&:hover': {
                                    backgroundColor: '#631d79',
                                }
                            }}
                        >
                            {`Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                        </Button>}
                </Grid>
            </Box>
        </>);
}

export default CartPaymentForm;
