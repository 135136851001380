import React from 'react';
import NavBase from './NavBase';
import {Nav, Navbar} from "react-bootstrap";
import {jwtContext} from "../Providers/JWTProvider";
import NavButton from "@/components/Navigation/NavButton.tsx";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactsIcon from '@mui/icons-material/Contacts';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import CartTimeoutBanner from "@/components/CartTimeoutBanner.tsx";
import CartNavButton from "@/components/Navigation/CartNavButton.tsx";
import FeesNavButton from "@/components/Navigation/FeesNavButton.tsx";
import {useStripeTerminal} from "@/components/Providers/StripeTerminalProvider.tsx";

type Props = {
    showNav ?: boolean;
}

const NavHeader = ({showNav} : Props) => {
    const user = React.useContext(jwtContext);
    const {inFileMaker} = useStripeTerminal();

    return (<>
            <NavBase>
                <div className="text-right d-flex flex-row">
                    <Navbar.Toggle aria-controls="chris-nav" />
                    {showNav && (<Navbar.Collapse id="glenbrook-nav" className="">
                        <Nav className="mr-auto">
                            {user && <FeesNavButton/>}

                            {user && (<CartNavButton/>)}

                            {user?.userType !== 'guest_user' && <NavButton
                                text='Register'
                                link='/programs'
                                icon={<PersonAddIcon/>}
                            />}

                            {user?.userType !== 'guest_user' && <NavButton
                                text='My Registrations'
                                link='/my-registrations'
                                icon={<ContactsIcon/>}
                            />}

                            {user?.userType === 'community_member' && (
                                <NavButton
                                    text='My Profile'
                                    link='/edit-profile'
                                    icon={<PersonIcon/>}
                                />
                            )}
                            {user?.userType !== 'guest_user' && <NavButton
                                text='Instructions'
                                link='/instructions'
                                icon={<InfoIcon/>}
                            />}
                            {inFileMaker !== true && <NavButton
                                text='Sign Out'
                                link='/signout'
                                icon={<LogoutIcon/>}
                            />}
                        </Nav>
                    </Navbar.Collapse>)}
                </div>
            </NavBase>
            {user && (
                <CartTimeoutBanner/>
            )}
        </>
    );
};

export default NavHeader;
