import NoticeProvider, {useNotice} from '@/components/Providers/NoticeProvider';
import RegisterGuestForm from "@/components/Forms/RegisterGuestForm.tsx";
import MainDisplayContainer from "@/components/Layout/MainDisplayContainer.tsx";
import {Stack} from "@mui/material";
import SignInHeader from "@/components/Navigation/SignInHeader.tsx";
import ReactMarkdown from "react-markdown";

const RegisterGuest = () => {
    const [notice] = useNotice();
    return (
        <NoticeProvider>
            <SignInHeader showNav={true} />
            <MainDisplayContainer>
                <Stack
                    sx={{
                        px: {lg: 2},
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Stack
                        sx={{
                            maxWidth: '500px',
                            p:2,
                            backgroundColor: '#fff',
                            borderRadius: 1,
                        }}
                    >
                        <ReactMarkdown className="markdown">{notice.guestLogInMessage}</ReactMarkdown>
                        <RegisterGuestForm />
                    </Stack>
                </Stack>
            </MainDisplayContainer>
        </NoticeProvider>
    );
};

export default RegisterGuest;
