import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {Button, Card, Divider, Grid, IconButton} from "@mui/material";
import {FeeProposalFormValues, FeeProposalRequestSchemaType} from "@/components/Forms/FeeProposalRequestForm.tsx";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import {RhfTextField} from "mui-rhf-integration";
import MenuItem from "@mui/material/MenuItem";
import {FeeQuestion} from "@/types/FeeQuestion.ts";

type Props = {
    form: UseFormReturn<FeeProposalFormValues, any, FeeProposalRequestSchemaType>;
};

const FeeQuestionsForm = ({form}: Props) => {
    const {control, watch} = form;
    const {fields, append, remove} = useFieldArray({control, name: 'questions'});
    const watchFieldArray = watch("questions");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    return <Grid container >
        {controlledFields.map((question: FeeQuestion, index: number) => (
            <Card key={`question-${question.id}`} sx={{width: '100%', p: 1, mb: 2}}>
                <Grid container item xs={12} >
                    <Grid xs={12} item sx={{p: 1}}>
                        <RhfTextField
                            control={form.control}
                            name={`questions.${index}.question`}
                            label={'Question'}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid xs={12} md={3} item sx={{p: 1}}>
                        <RhfTextField
                            select
                            control={form.control}
                            name={`questions.${index}.responseType`}
                            label='Response Type'
                            sx={{
                                width: '100%',
                            }}
                        >
                            <MenuItem key='question-value-list' value='Value List'>Value List</MenuItem>
                            <MenuItem key='question-free-from' value='Free Form'>Free Form</MenuItem>
                        </RhfTextField>
                    </Grid>
                    <Grid xs={12} md={9} item sx={{p: 1, display: 'flex', flexDirection: 'row', justifyContent: {xs: 'center', md: 'flex-start'}}}>
                        {question.responseType === 'Value List' && <RhfTextField
                            control={form.control}
                            name={`questions.${index}.answerList`}
                            label={'Value List Options'}
                            sx={{
                                width: '100%',
                            }}
                        />}
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                                remove(index);
                            }}
                        >
                            <RemoveCircleIcon/>
                        </IconButton>
                    </Grid>
                    <Divider/>
                </Grid>
            </Card>
        ))}
        <Grid item xs={12}>
            <Button
                variant='outlined'
                onClick={() => {
                    append({
                        question: '',
                        responseType: '',
                        answerList: '',
                    })
                }}
                sx={{
                    backgroundColor: '#631d79',
                    color: '#fff',
                    p: 1,
                    borderRadius: '8px',
                    fontWeight: 800,
                    mb: 2,
                    mr: 2,
                    '&:hover': {
                        backgroundColor: '#631d79',
                    }
                }}
            >
                Add Question
            </Button>
        </Grid>
    </Grid>
};

export default FeeQuestionsForm;
