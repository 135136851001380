import {useState} from "react";
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import {apiEndpoint} from "../Providers/JWTProvider";
import ModalBase from "./ModalBase";
import {z} from "zod";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid2";
import {Alert, Button} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";

type Props = {
    show: boolean;
    handleClose: () => void;
    handleYes: () => void;
    isStudent: boolean;
};

const RecoverEmailModal = ({
    show,
    handleClose,
    isStudent = false
}: Props) => {
    const [loading, setLoading] = useState(false);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

    const personVerbiage = isStudent ? `Student` : `Community Member`;

    const internalClose = () => {
        handleClose();
        setLoading(false);
        setShowConfirmationMessage(false);
    };

    const initialValues = {
        email: '',
    };

    const schema = z.object({
        email: z.string().email().transform(val => val === null ? '' : val)
    });

    type RecoverEmailSchemaType = z.infer<typeof schema>;

    const form = useForm<RecoverEmailSchemaType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema),
    });

    const handleRecoverRegistration = async (values: RecoverEmailSchemaType) => {
        setLoading(true);
        const url = isStudent ? new URL(`/v1/student/recover-student`, apiEndpoint) : new URL(`/v1/community/recover-member`, apiEndpoint);
        let init: RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'PUT'};
        init.body = JSON.stringify(values);
        fetch(url.toString(), init);
        setLoading(false);
        setShowConfirmationMessage(true);
    };

    return (
        <ModalBase
            show={show}
            title={`${personVerbiage} ID`}
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={internalClose}
        >
            {loading && <CircularLoadingIndicator/>}
            {!loading && showConfirmationMessage && <Alert severity={'success'} className="rounded-0">
                <span className="justify-content-center d-flex">
                    If located, an email will be sent to the address on file with your {personVerbiage} ID.
                </span>
            </Alert>}
            {!loading && !showConfirmationMessage && <>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(async (values) => {
                        setLoading(true);
                        await handleRecoverRegistration(values);
                    })}>
                        <Grid container>
                            <Grid size={{xs: 12}} sx={{my: 2}}>
                                Please enter the email address that was used to register below to recover your {personVerbiage} ID.
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid size={{xs: 12}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'email'}
                                    placeholder={'example@website.com'}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid size={{xs: 12}}><hr /></Grid>
                        <Grid container>
                            <Grid size={{xs: 12}} className="d-flex flex-row justify-content-start justify-content-lg-end">
                                <Button
                                    variant="outlined"
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={async () => {
                                        handleClose();
                                    }}
                                >Cancel</Button>
                                <Button
                                    variant="contained"
                                    style={{backgroundColor: '#631d79', color: 'white'}}
                                    type="submit"
                                    className="d-flex flex-row align-content-center"
                                    sx={{mx: 2}}
                                >Recover</Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </>}
        </ModalBase>
    );
}

export default RecoverEmailModal;
