import {Alert, type AlertColor, type AlertPropsColorOverrides, Snackbar} from "@mui/material";
import type {OverridableStringUnion} from "@mui/types";

type Props = {
    show: boolean;
    onClose: (() => unknown) | null | undefined;
    autoHideDuration?: number;
    severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    message: string | null;
}

const GlenSnackBar = ({
    show,
    onClose,
    autoHideDuration,
    severity,
    message,
} : Props) => {
    return <Snackbar
        open={show && message !== null}
        autoHideDuration={autoHideDuration ?? 10_000}
        onClose={onClose ?? undefined}
    >
        <Alert
            onClose={onClose ?? undefined}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {message}
        </Alert>
    </Snackbar>
}

export default GlenSnackBar;
