import {useCallback} from 'react';
import {useUser} from "@/components/Providers/JWTProvider.tsx";

const useGetPurchaseHistory = () : () => Promise<Response | void> => {
    const {user} = useUser();

    return useCallback(async () : Promise<Response | void> => {
        const url = new URL('/v1/purchase-history', import.meta.env.VITE_APP_API_ENDPOINT);

        if (user){
            return await user.apiFetch(url.toString(), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        } else {
            console.error('missing user');
        }
    }, []);
};

export default useGetPurchaseHistory;
