import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {RhfTextField} from "mui-rhf-integration";
import './FeeQuestionsForm.css';
import {BaseSyntheticEvent, useState} from "react";
import {apiEndpoint, PartialJWTUser, persistUser} from "@/components/Providers/JWTProvider.tsx";
import {Login} from "@mui/icons-material";
import PrimaryButton from "@/components/PrimaryButton.tsx";
import {useNavigate, useRouter} from "@tanstack/react-router";

const registerSchema = z.object({
    nameFirst: z.string(),
    nameLast: z.string(),
    email: z.string().email(),
});

export type GuestRegistrationSchemaType = z.infer<typeof registerSchema>;

const RegisterGuestForm = () => {
    const navigate = useNavigate();
    const {history} = useRouter();
    const [errorMessage, setErrorMessage] = useState<string|undefined>();

    let defaultValues = {};

    const form = useForm<GuestRegistrationSchemaType>({
        defaultValues: defaultValues,
        resolver: zodResolver(registerSchema),
    });

    const onSubmit: SubmitHandler<GuestRegistrationSchemaType> = async (
        data: GuestRegistrationSchemaType,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        setErrorMessage(undefined);

        const url = new URL(`/v1/community/register-guest`, apiEndpoint);
        const response = await fetch(url.toString(), {
            headers: new Headers({'Content-Type': 'application/json'}),
            method: 'PUT',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            console.error(response);
            setErrorMessage('There was an error logging you in.');
        }

        persistUser(await response.json() as PartialJWTUser);
        await navigate({to: '/fees'});
    }

    return <>
        <FormProvider {...form}>
            <form onSubmit={
                form.handleSubmit(async (data, event) => {
                    await onSubmit(data, event);
                })
            }>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        borderRadius: '8px',
                    }}
                >
                  <Grid size={{xs: 12, md: 6}}>
                    <RhfTextField
                        control={form.control}
                        name={'nameFirst'}
                        label={'First Name'}
                        sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                        }}
                    />
                  </Grid>
                  <Grid size={{xs: 12, md: 6}}>
                      <RhfTextField
                          control={form.control}
                          name={'nameLast'}
                          label={'Last Name'}
                          sx={{
                              width: '100%',
                              backgroundColor: '#fff',
                          }}
                      />
                  </Grid>
                  <Grid size={{xs: 12}}>
                      <RhfTextField
                          control={form.control}
                          name={'email'}
                          label={'Email'}
                          sx={{
                              width: '100%',
                              backgroundColor: '#fff',
                          }}
                      />
                  </Grid>
                </Grid>
                {errorMessage !== undefined && <Grid size={{xs: 12}} sx={{pt: 2}}>
                    <Typography>{errorMessage}</Typography>
                </Grid>}
                <Grid size={{xs: 12}} sx={{pt: 2}}>
                    <PrimaryButton
                        sx={{width: '100%'}}
                        onClick={form.handleSubmit(onSubmit)}
                    >
                        <Login sx={{mr: 1}}/> Login
                    </PrimaryButton>
                    <Button variant="outlined" fullWidth onClick={() => {
                        history.back();
                    }} sx={{ mt: 2 }}>
                        Back
                    </Button>
                </Grid>
            </form>
        </FormProvider>
    </>
};

export default RegisterGuestForm;

