import React from 'react';
import NavHeader from "../components/Navigation/NavHeader";
import {
    Program,
    ProgramsProviderContext,
    STATUS_PARTIAL,
    STATUS_REGISTERED,
    STATUS_REGISTERED_WITH_CREDITS,
    STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT,
    STATUS_WAITLISTED
} from "../components/Providers/ProgramsProvider";
import ProgramCourses from "../components/Program/ProgramCourses";
import NoRegisteredCourses from "../components/Program/NoRegisteredCourses";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import Grid from "@mui/material/Grid2";
import MainDisplayContainer from "@/components/Layout/MainDisplayContainer.tsx";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

const MyRegistrations = () => {
    const {getCartAlertStatus, resetCartAlertStatus} = useCartContext();
    const [programsState,,clearRegistrationError] = React.useContext(ProgramsProviderContext);

    const cartStatus = getCartAlertStatus();
    let noRegisteredCourses = true;

    return (
        <>
            <NavHeader showNav={true}/>
            <MainDisplayContainer>
                <Grid container justifyContent={'center'}>
                    {programsState.loading ? (
                        <CircularLoadingIndicator/>
                    ) : (
                        <>
                            {programsState.programs.map((program: Program, index) => {
                                const courses = program.courses.filter(
                                    (course ) =>
                                        course.status === STATUS_WAITLISTED ||
                                        course.status === STATUS_REGISTERED ||
                                        course.status === STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT ||
                                        course.status === STATUS_REGISTERED_WITH_CREDITS ||
                                        course.status === STATUS_PARTIAL
                                );

                                if (courses.length > 0) {
                                    noRegisteredCourses = false;
                                }

                                return <ProgramCourses
                                    key={`${index}-${program.name}`}
                                    programName={program.name}
                                    myRegistrations={true}
                                    courses={courses}
                                />
                            })}
                            {noRegisteredCourses && (
                                <NoRegisteredCourses/>
                            )}
                        </>
                    )}
                </Grid>
            </MainDisplayContainer>
            <GlenSnackBar
                onClose={clearRegistrationError}
                show={programsState.showAlert}
                severity={programsState.alertVariant}
                message={programsState.message}/>
            <GlenSnackBar onClose={resetCartAlertStatus} show={cartStatus.showAlert} severity={cartStatus.alertVariant} message={cartStatus.message}/>
        </>
    );
};

export default MyRegistrations;
