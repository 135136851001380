import {Container} from "react-bootstrap";
import SignInHeader from '../components/Navigation/SignInHeader';
import {Stack} from "@mui/material";
import {ActionType, UserType} from "@/types/auth.ts";
import {useState} from "react";
import {UserTypeSelection} from "@/components/Home/UserTypeSelection.tsx";
import {ActionTypeSelection} from "@/components/Home/ActionTypeSelection.tsx";
import {useNavigate} from "@tanstack/react-router";

export const signInError = 'Invalid Student ID or Birth Date';

const HomePage = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [userType, setUserType] = useState<UserType | null>(null);

    const handleUserTypeSelect = (type: UserType) => {
        if (type === 'guest') {
            navigate({to: '/register-guest'});
            return;
        }

        setUserType(type);
        setStep(2);
    };

    const handleActionTypeSelect = (actionType: ActionType) => {
        switch (actionType) {
            case 'view-activities':
                navigate({to: '/instructor/'});
                break;
            case 'fee-request':
                navigate({to: '/instructor/fee-proposals'});
                break;
            case 'staff-fees':
                navigate({to: '/instructor/fees'});
                break;
            case 'donate':
                navigate({to: '/fees'});
                break;
            case 'register':
                navigate({to: '/programs'});
                break;
            case 'fees':
                navigate({to: '/fees'});
                break;
        }
        setStep(1);
        setUserType(null);
    };

    const handleSkipToLogin = () => {
        if (userType === 'faculty') {
            navigate({to: '/instructor/sign-in'});
        } else {
            navigate({to: '/programs'});
        }
        setStep(1);
        setUserType(null);
    };

    return (
        <>
            <SignInHeader showNav={true} />
            <Container>
                <Stack sx={{justifyContent: 'center', alignItems: 'center', p: 5}}>
                    <Stack sx={{maxWidth: '500px'}}>
                        {step === 1 && (
                            <UserTypeSelection
                                onSelect={handleUserTypeSelect}
                                onSkipToLogin={handleSkipToLogin}
                            />
                        )}
                        {step === 2 && userType && (
                            <ActionTypeSelection
                                userType={userType}
                                onSelect={handleActionTypeSelect}
                                onBack={() => setStep(1)}
                                onSkipToLogin={handleSkipToLogin}
                            />
                        )}
                    </Stack>
                </Stack>
            </Container>
        </>
    );
};

export default HomePage;
