import React, {createContext, type ReactNode, useCallback, useEffect, useState} from 'react';
import {loadStripeTerminal, type Reader, type Terminal, type StripeTerminal} from "@stripe/terminal-js";
import {apiEndpoint, useUser} from "@/components/Providers/JWTProvider.tsx";

export const CONNECTING = 'connecting';
export const CONNECTED = 'connected';
export const NOT_CONNECTED = 'not_connected';

export const NOT_READY = 'not_ready';
export const READY = 'ready';
export const WAITING_FOR_INPUT = 'waiting_for_input';
export const PROCESSING = 'processing';

const publishableKey = import.meta.env.VITE_APP_STRIPE_KEY ?? '';

//Note the ConnectionStatus exported from @stripe/terminal-js is not real
export type ConnectionStatus = typeof CONNECTING | typeof CONNECTED | typeof NOT_CONNECTED;

export type PaymentStatus = typeof NOT_READY | typeof READY | typeof WAITING_FOR_INPUT | typeof PROCESSING;
type StripeTerminalContextType = {
    terminal?: Terminal;
    connected?: ConnectionStatus;
    paymentStatus?: PaymentStatus;
    connectReader?: (reader: Reader | undefined) => Promise<void>;
    readers: Reader[];
    reader?: Reader;
    inFileMaker: boolean;
}

// Create a Context
export const StripeTerminalContext = createContext<StripeTerminalContextType>({
    readers: [],
    inFileMaker: false,
});

let StripeTerminalClass : StripeTerminal | null = null;

// Create a Provider component
export const StripeTerminalProvider = ({ children, debugEnabled } : { children : ReactNode, debugEnabled ?: boolean}) => {
    // State that you want to share
    const [inFileMaker, setInFileMaker] = useState<boolean>(false);
    const [terminal, setTerminal] = useState<Terminal | undefined>();
    const [connected, setConnected] = useState<ConnectionStatus | undefined>();
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | undefined>();
    const [readers, setReaders] = useState<Reader[]>([]);
    const [reader, setReader] = useState<Reader | undefined>();
    const {user} = useUser();

    useEffect(() => {
        let repeatCount = 0;
        const repeat = setInterval(() => {
            repeatCount++;
            if ('FileMaker' in window) {
                setInFileMaker(true);
                clearInterval(repeat);
            }
            if (repeatCount > 10) {
                clearInterval(repeat);
            }
        }, 500);//tick every second
        return () => {
            clearInterval(repeat);
        }
    }, []);

    useEffect(() => {
        if (!user) {
            debugEnabled && console.debug('StripeTerminalProvider no user');
            return;
        }
        if (!inFileMaker) {
            return;
        }

        debugEnabled && console.debug('loading loadStripeTerminal');
        if (StripeTerminalClass) {
            initTerminal().catch(e => console.error('error connecting to StripeTerminal', e));
        } else {
            loadStripeTerminal().then(async (StripeTerminal) => {
                debugEnabled && console.debug('loadStripeTerminal loaded', StripeTerminalClass);
                StripeTerminalClass = StripeTerminal;
                await initTerminal();
            });
        }
        return () => {
            if (terminal) {
                terminal.disconnectReader().catch((err) => {
                    console.error('error disconnecting from terminal on unmount', err);
                })
            }
        }
    }, [user, inFileMaker]);

    const initTerminal = async () => {
        setTerminal(undefined);
        setReader(undefined);
        if (!user) {
            return;
        }

        if (!StripeTerminalClass) {
            throw new Error("StripeTerminalProvider requires StripeTerminalProvider");
        }

        try {
            const terminal = StripeTerminalClass.create({
                onFetchConnectionToken: async () => {
                    debugEnabled && console.debug('loadStripeTerminal connectionToken');
                    const response = await user.apiFetch(
                        new URL(`/v1/payment/connectionToken`, apiEndpoint).toString()
                    );
                    const data = await response.json();
                    return data.secret;
                },
                onUnexpectedReaderDisconnect: (e) => {
                    debugEnabled && console.debug('onUnexpectedReaderDisconnect', e);
                    setPaymentStatus(NOT_READY);
                    setConnected(NOT_CONNECTED);
                    setReader(undefined);
                },
                onConnectionStatusChange: (change) => {
                    debugEnabled && console.debug('onConnectionStatusChange', change);
                    if (change.status === NOT_CONNECTED) {
                        setReader(undefined);
                    }
                    setConnected(change.status);
                },
                onPaymentStatusChange: (change) => {
                    setPaymentStatus(change.status);
                    debugEnabled && console.debug('onPaymentStatusChange', change);
                },
            });
            setTerminal(terminal);

            let simulatedReaders: Reader[] = [];
            if (publishableKey.startsWith('pk_test_')) {
                const discoverResult = await terminal.discoverReaders({
                    simulated: true,
                });

                if ('error' in discoverResult) {
                    console.error('failed to discover readers');
                } else {
                    simulatedReaders = discoverResult.discoveredReaders;
                }
            }

            const discoverResult = await terminal.discoverReaders({});

            setReaders([]);
            if ('error' in discoverResult) {
                console.error('failed to discover readers');
            } else {
                const readers = [
                    ...discoverResult.discoveredReaders,
                    ...simulatedReaders
                ];
                setReaders(readers);
                debugEnabled && console.debug('discovered readers', readers);
                const preferredReaderId = localStorage.getItem('PreferredReaderId');
                const preferredReader = readers.find(r => r.id === preferredReaderId);
                debugEnabled && console.debug('preferred reader', preferredReaderId, preferredReader);
                if (preferredReader) {
                    const connectResponse = await terminal.connectReader(preferredReader);
                    if ('error' in connectResponse) {
                        console.error('error connecting to preferred terminal', connectResponse.error);
                    } else {
                        setReader(connectResponse.reader);
                    }
                }
            }
        } catch (e) {
            console.error('error starting stripe terminal', e);
        }
    }

    const connectReader = useCallback(async (newReader: Reader | undefined) => {
        setReader(newReader);
        setConnected(NOT_CONNECTED);
        setPaymentStatus(NOT_READY);
        if (!terminal) {
            throw new Error('Not connected to stripe terminal yet.');
        }
        if (reader) {
            try {
                await terminal.disconnectReader();
            }catch (e) {
                console.error('error disconnecting from terminal on connect to new terminal', e);
            }
        }

        if (!newReader) {
            return;
        }

        const response = await terminal.connectReader(newReader);
        if ('error' in response) {
            setReader(undefined);
            console.error('error connecting to terminal', response.error);
            throw response.error;
        }
        setReader(response.reader);
    }, [terminal, reader, setReader]);

    return (
        <StripeTerminalContext.Provider value={{terminal, connected, paymentStatus, connectReader, reader, readers, inFileMaker}}>
            {children}
        </StripeTerminalContext.Provider>
    );
};

export const useStripeTerminal = () : StripeTerminalContextType => {
    const context = React.useContext(StripeTerminalContext)

    if (Object.keys(context).length === 0) {
        throw new Error('useCartContext must be used within a CartContextProvider')
    }

    return context
}

export const setPreferredReader = (readerId: string) => {
    localStorage.setItem('PreferredReaderId', readerId);
}
