import {useCallback, useState} from 'react';
import SignInForm, {SignInFormSchemaType} from "../components/Forms/SignInForm";
import {JWTUser, useAuthenticateUser} from "../components/Providers/JWTProvider";
import moment from "moment";
import SignInHeader from '../components/Navigation/SignInHeader';
import {Stack} from "@mui/material";
import Grid from "@mui/material/Grid2";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

export const signInError = 'Invalid Student ID or Birth Date';

const allowedRoutes = ['/fees', '/cart', '/programs', '/my-registrations', '/instructions'];

type Props = {
    setUser?: (user : JWTUser | null) => void
}

const SignIn = ({setUser} : Props) => {
    const authenticateUser = useAuthenticateUser();
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const handleSignInSubmit = useCallback(async (values : SignInFormSchemaType) => {
        const dob = moment(values.dob, 'MM/DD/YYYY', true);
        const user : JWTUser | null = await authenticateUser(values.studentId, dob.format('YYYY-MM-DD'));
        setUser && setUser(user);

        if (user) {
            if (!allowedRoutes.includes(window.location.pathname)) {
                window.location.href = '/programs';
            }
            window.location.hash = '#';
        } else {
            setShowAlert(true);
        }
    }, [authenticateUser, setShowAlert]);

    const handleClearAlert = useCallback(async () => {
        setShowAlert(false);
    }, [setShowAlert]);

    return (
        <>
            <SignInHeader showNav={true} />
            <Grid container justifyContent={'center'}>
                <Stack sx={{justifyContent: 'center', alignItems: 'center', p: 5}}>
                    <Stack sx={{maxWidth: '500px'}}>
                        <GlenSnackBar
                            onClose={handleClearAlert}
                            show={showAlert}
                            severity={'error'}
                            message={signInError}/>
                        <SignInForm handleSignInSubmit={handleSignInSubmit}/>
                    </Stack>
                </Stack>
            </Grid>
        </>
    );
};

export default SignIn;
