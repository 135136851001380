import ModalBase from './ModalBase';
import {useNotice} from "../Providers/NoticeProvider";
import ReactMarkdown from "react-markdown";
import {Container} from "react-bootstrap";

const SystemNoticeModal = () => {
    const [notice, closeSystemNotice] = useNotice();

    const handleCloseModal = () => {
        if (closeSystemNotice) {
            closeSystemNotice();
        }
    };

    return (
        <ModalBase
            show={notice.homeMessage.show}
            title=""
            hideConfirmButton={true}
            hideCloseButton={false}
            handleClose={handleCloseModal}
        >
            <Container className="mt-0 px-0">
                <ReactMarkdown className="markdown">{notice.homeMessage.message}</ReactMarkdown>
            </Container>
        </ModalBase>
    );
};

export default SystemNoticeModal;
