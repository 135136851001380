import {IconDisplayOptions} from "@/types/IconDisplayOptions.ts";
import {Fee} from "@/types/Fee.ts";
import {isJWTUser, JWTInstructor, JWTUser} from "@/components/Providers/JWTProvider.tsx";
import {PurchaseHistory} from "@/components/Providers/FeesProvider.tsx";
import {AssessedFee} from "@/types/AssessedFee.ts";

export const getFeeDisplayIconType = (
    isInCart: boolean, fee: Fee, user: JWTUser | JWTInstructor | null, purchaseHistory: PurchaseHistory, assessedFee ?: AssessedFee[]
) : IconDisplayOptions => {
    if (assessedFee) {
        const hasNotPaid = assessedFee.some(fee => fee.status != 'paid')
        if (!hasNotPaid) {
            return 'PaidIcon';
        }
    }

    if (isInCart) {
        return 'InCart';
    }

    if (fee.feeCriteriaError) {
        return 'FeeCriteriaErrorIcon';
    }

    if (fee && user) {
        const timesPreviouslyPurchased = purchaseHistory?.fees[fee.id] ?? 0;

        if (
            isJWTUser(user) &&
            user.financialAid.toLowerCase() === 'free' &&
            typeof fee.freeAmount === 'number' &&
            fee.freeAmount >= 0 &&
            timesPreviouslyPurchased < fee.freeQuantity) {
            return 'CartIcon';
        }

        if (
            isJWTUser(user) &&
            user.financialAid.toLowerCase() === 'reduced' &&
            typeof fee.reducedAmount === 'number' &&
            fee.reducedAmount >= 0 &&
            timesPreviouslyPurchased < fee.reducedQuantity) {
            return 'CartIcon';
        }

        if (
            isJWTUser(user) &&
            user.financialAid.toLowerCase() === 'none' &&
            fee.quantity &&
            typeof fee.amount === 'number' &&
            fee.amount >= 0 &&
            timesPreviouslyPurchased < fee.quantity
        ) {
            return 'CartIcon';
        }

        if (
            fee.quantity &&
            typeof fee.amount === 'number' &&
            fee.amount >= 0 &&
            timesPreviouslyPurchased < fee.quantity
        ) {
            return 'CartIcon';
        }
    }

    const hasPartial = assessedFee?.some(fee => fee.status != 'partial')
    if (hasPartial) {
        return 'CartIcon';
    }

    return 'BlockIcon';
}
