import {ReactNode, useMemo} from "react";
import PaymentFormPaymentAmount from "@/components/Forms/Fields/PaymentFormPaymentAmount.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {useWatch} from "react-hook-form";
import BigNumber from "bignumber.js";
import {Grid2, Typography} from "@mui/material";
import {formatter} from "@/Helpers/formatter.ts";
import {PaymentFormSchemaType} from "@/components/Forms/PaymentForm.tsx";


const CashTender = () : ReactNode => {
    const {getCartTotal} = useCartContext();
    const totalCost = getCartTotal();

    const tenderedWatch = useWatch<PaymentFormSchemaType>({name: 'tendered'});
    const partialPaymentAmountWatch = useWatch<PaymentFormSchemaType>({name: 'partialPaymentAmount'});
    const paymentTypeWatch = useWatch<PaymentFormSchemaType>({name: 'paymentType'});

    const change = useMemo(() => {
        //it's a string but typescript thinks it could be a number so lets make it a string
        const due = paymentTypeWatch === 'partial' ? parseFloat(`${partialPaymentAmountWatch}`) : totalCost;
        if (isNaN(due)) {
            return undefined;
        }
        //it's a string but typescript thinks it could be a number so lets make it a string
        const tendered = parseFloat(`${tenderedWatch}`);
        if (isNaN(tendered)) {
            return undefined;
        }
        if (tendered < due) {
            return undefined;
        }

        return BigNumber(tendered).minus(due).toNumber();
    }, [paymentTypeWatch, partialPaymentAmountWatch, totalCost, tenderedWatch]);

    return <>
        <Grid2 size={12}>
            <PaymentFormPaymentAmount name="tendered" label="Cash Tendered"/>
        </Grid2>
        <Grid2 size={12}>
            {change !== undefined && <Typography variant="subtitle1" sx={{color: 'black'}}>
              Change Due: {formatter.format(change)}
            </Typography>}
        </Grid2>
    </>;
}

export default CashTender;
