import NoticeProvider from "./components/Providers/NoticeProvider.tsx";
import {ScheduledMaintenance} from "./pages/ScheduledMaintenance.tsx";
import {createTheme, ThemeProvider} from "@mui/material";
import {RouterProvider} from "@tanstack/react-router";
import {appRouter} from "@/Routes.tsx";

let theme = createTheme({
    palette: {
        secondary: {
            main: 'rgb(99, 29, 121)',
        },
    },
});

const App = () => {
    return (<>
        <ThemeProvider theme={theme}>
            <ScheduledMaintenance>
                <NoticeProvider>
                    <RouterProvider router={appRouter}/>
                </NoticeProvider>
            </ScheduledMaintenance>
        </ThemeProvider>
    </>);
}

import('./utils/injectMockFilemaker.ts');

export default App;
