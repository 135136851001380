import React from 'react';
import {Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

type Props = {
    show : boolean;
    title : string | null;
    children : React.ReactNode;
    handleClose? : () => void | null | Promise<void>;
    handleConfirm? : () => void | null;
    cancelText? : string;
    confirmText? : string;
    loading? : boolean;
    hideCloseButton? : boolean;
    refForm? : string;
    hideConfirmButton? : boolean;
    btnClass? : string;
    modalClass? : string;
}

const ModalBase = ({
    show,
    handleClose,
    title,
    handleConfirm,
    cancelText,
    confirmText,
    loading,
    hideCloseButton,
    hideConfirmButton,
    btnClass,
    modalClass,
    children,
} : Props) => (
    <Dialog open={show} onClose={handleClose} className={modalClass} maxWidth={'lg'}>
        <DialogTitle sx={{fontSize: '1.5em'}}>
            {title !== null && title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText sx={{pb: 2}} component="div">{children}</DialogContentText>
            <div className="d-flex justify-content-end flex-column-reverse flex-sm-row">
                {!hideCloseButton && (
                    <Button
                        variant={"contained"}
                        onClick={handleClose}
                        className={btnClass}
                        sx={{
                            mr: hideConfirmButton ? 0 : 2,
                        }}
                    >
                        {cancelText ? cancelText : 'Close'}
                    </Button>
                )}
                {!hideConfirmButton && (
                    <Button
                        variant="contained"
                        onClick={handleConfirm}
                        type="submit"
                        className={btnClass}
                    >
                        {(loading ?
                            <React.Fragment>
                                <CircularProgress role="status" aria-hidden="true"/>
                                &nbsp; Loading...
                            </React.Fragment>
                        :
                            <React.Fragment>{confirmText ? confirmText : 'Confirm'}</React.Fragment>
                        )}
                    </Button>
                )}
            </div>
        </DialogContent>
    </Dialog>
);

export default (ModalBase);
