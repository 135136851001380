import React from 'react';
import NavHeader from "../components/Navigation/NavHeader";
import {Program, ProgramsProviderContext} from "../components/Providers/ProgramsProvider";
import ProgramSelection from "../components/Program/ProgramSelection";
import ProgramCourses from "../components/Program/ProgramCourses";
import ProgramSelectField from "../components/Forms/Fields/ProgramSelectField";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import SystemNoticeModal from "../components/Modal/SystemNoticeModal";
import SurveyForm from "../components/Forms/SurveyForm";
import Grid from "@mui/material/Grid2"
import {Box, Typography} from "@mui/material";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

const Programs = () => {
    const [programsState,,clearRegistrationError] = React.useContext(ProgramsProviderContext);
    const [activeProgramName, setActiveProgramName] = React.useState<string | null>(null);
    const [needsSurvey, setNeedsSurvey] = React.useState<boolean>(false);
    const activeProgram = activeProgramName ? programsState.programs.find(
        (program) => program.name === activeProgramName
    ) : undefined;

    React.useEffect(() => {
        setNeedsSurvey(activeProgramName !== null && activeProgram?.requiresSurvey === true && activeProgram?.finishedSurvey === false);
    },[activeProgramName, activeProgram])

    const openPrograms = programsState.programs.filter((program : Program) => program.open);

    let availablePrograms : Array<string> = openPrograms.map((program) => {
       return program.name;
    });

    return (
        <>
            <NavHeader showNav={true}/>
            <Grid container>
                {activeProgramName && (
                    <Grid
                        size={{xs: 6}}
                        offset={{xs: 1}}
                        className="d-flex flex-row justify-content-start"
                        sx={{my: 3}}
                    >
                        <Box sx={{ml: 7, mr: 2, mt: 2}}><Typography sx={{
                            color: "white",
                        }}>Program Selected:</Typography></Box>
                        <ProgramSelectField
                            availablePrograms={availablePrograms}
                            setActiveProgram={setActiveProgramName}
                            defaultProgram={activeProgramName}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container justifyContent={'center'}>
                {programsState.loading ? (
                    <CircularLoadingIndicator/>
                ) : (
                    <>
                        {!activeProgramName || !activeProgram ? (
                            <ProgramSelection
                                availablePrograms={availablePrograms}
                                loading={programsState.loading}
                                setActiveProgram={setActiveProgramName}
                            />
                        ) : (needsSurvey ? <SurveyForm
                            setNeedsFinishSurvey={setNeedsSurvey}
                            program={activeProgram}
                        /> :(
                            <ProgramCourses
                                programName={activeProgram.name}
                                setActiveProgram={setActiveProgramName}
                                courses={activeProgram.courses}
                            />
                        ))}
                    </>
                )}
            </Grid>
            <GlenSnackBar
                onClose={clearRegistrationError}
                show={programsState.showAlert}
                severity={programsState.alertVariant}
                message={programsState.message}/>
            <SystemNoticeModal/>
        </>
    );
};

export default Programs;
