import {Fee} from "@/types/Fee.ts";
import {isJWTUser, JWTInstructor, JWTUser} from "@/components/Providers/JWTProvider.tsx";
import {PurchaseHistory} from "@/components/Providers/FeesProvider.tsx";
import {AssessedFee} from "@/types/AssessedFee.ts";

export type FinancialAid = "none" | "reduced" | "free" | null;

export const buildFeePrice = (
    fee: Fee,
    user: JWTUser | JWTInstructor | null,
    purchaseHistory: PurchaseHistory | undefined,
    assessedFee: AssessedFee | undefined,
) : number => {
    if (fee && purchaseHistory && isJWTUser(user) && user.userType === 'student') {

        const timesPreviouslyPurchased = purchaseHistory?.fees[fee.id] ?? 0;

        if (assessedFee?.assessedCost !== undefined) {
            return assessedFee.assessedCost;
        }

        if (
            user.financialAid.toLowerCase() === 'free' &&
            typeof fee.freeAmount === 'number' &&
            fee.freeAmount >= 0 &&
            timesPreviouslyPurchased < fee.freeQuantity
        ) {
            return fee.freeAmount;
        }

        if (
            user.financialAid.toLowerCase() === 'reduced' &&
            typeof fee.reducedAmount === 'number' &&
            fee.reducedAmount >= 0 &&
            timesPreviouslyPurchased < fee.reducedQuantity) {
            return fee.reducedAmount;
        }

        if (
            user.financialAid.toLowerCase() === 'none' &&
            fee.quantity &&
            timesPreviouslyPurchased < fee.quantity &&
            typeof fee.amount === 'number' &&
            fee.amount >= 0
        ) {
            return fee.amount;
        }

        if (
            fee.quantity &&
            timesPreviouslyPurchased < fee.quantity &&
            typeof fee.amount === 'number' &&
            fee.amount >= 0
        ) {
            return fee.amount;
        }
    }

    return fee.amount ?? 0;
}
