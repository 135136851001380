import * as React from 'react';
import {useMemo} from 'react';
import {Box, Button, Collapse, Stack, Tooltip, Typography} from "@mui/material";
import {formatter} from "@/Helpers/formatter.ts";
import moment from "moment/moment";
import {ChevronDown, ChevronUp, Lock} from 'lucide-react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {NormalizedCartItem, useCartContext} from "@/components/Providers/CartProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {CourseAnswer} from "@/types/AssessedFee.ts";

type Props = {
    normalizedCartItem: NormalizedCartItem;
};

const CartSummaryRegistrationListItem = ({
    normalizedCartItem,
} : Props) : React.ReactElement => {
    const {cart, deleteCartItem} = useCartContext();
    const {allAssessedFees} = useFeeProvider();
    const {
        cartItem,
        quantity,
        itemGroup
    } = normalizedCartItem

    const feeAnswers = useMemo(() : CourseAnswer[] | undefined => {
        if (!normalizedCartItem.cartItem.feeId) {
            return undefined;
        }

        let courseAnswer : CourseAnswer[] = [];
        for (const ci of itemGroup) {
            const assessedFee = allAssessedFees.find(af => af.id === ci.assessedFeeId);
            if (assessedFee?.courseAnswer && assessedFee.courseAnswer.length > 0) {
                courseAnswer = courseAnswer.concat(assessedFee.courseAnswer);
            }
        }

        if (courseAnswer.length === 0) {
            return undefined;
        }

        return courseAnswer;
    }, [allAssessedFees, normalizedCartItem]);

    if (cartItem.itemType === 'fee') {
        return <Box sx={{flexGrow: 1,}}>
            <Stack spacing={1} width="100%">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {!cartItem.allowPartialPayment && (
                        <Tooltip title="Requires Payment in Full" arrow>
                            <Lock size={16} color="#6B21A8" />
                        </Tooltip>
                    )}
                    <Typography variant="body1">{cartItem.name}</Typography>
                </Box>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Select
                        value={quantity}
                        size="small"
                        onChange={(e) => {
                            const newCount = typeof e.target.value === "string"
                                ? Number.parseInt(e.target.value) : e.target.value;

                            const ciKey = cartItem.courseSectionId ?? cartItem.feeId;
                            const cartItems = cart?.cartItems
                                .filter(ci => ci.courseSectionId === ciKey || ci.feeId === ciKey)
                                .map(ci => ci.id) ?? [];
                            deleteCartItem(cartItems.slice(0, quantity - newCount));
                        }}
                        sx={{
                            minWidth: 80,
                            height: '32px',
                            '.MuiSelect-select': {
                                py: '4px'
                            }
                        }}
                    >
                        {[...Array(quantity)].map((_, i)=> {
                            return <MenuItem key={`change-quantity-${i}`} value={i+1}>
                                {i+1}
                            </MenuItem>
                        })}
                    </Select>
                    <Typography variant="body2" color="text.secondary">
                        {formatter.format(cartItem.cost)}
                    </Typography>
                </Stack>
                {feeAnswers && <DisplayFeeAnswers feeAnswers={feeAnswers} />}
            </Stack>


        </Box>;
    }

    return <Box sx={{flexGrow: 1,}}>
        <Box sx={{display: 'inline', pr: 1, color: '#000', fontWeight: 800}}>{cartItem.name}</Box>
        {cartItem.deliveryMethod &&
            <Box sx={{display: 'inline', pr: 1, color: '#000'}}>{cartItem.deliveryMethod && ','}</Box>}
        <Box sx={{display: 'inline', pr: 1, fontWeight: 800, color: '#000'}}>Section {cartItem.sectionNumber}</Box>
        <Box sx={{px: 1}}>{moment(cartItem.dateStartFull).format('MM/DD')} - {moment(cartItem.dateEndFull).format('MM/DD')},</Box>
        <Box sx={{px: 1, display: 'inline'}}>{cartItem.meetingTime}</Box>
        <Box sx={{px: 1, color: '#000', fontWeight: 800}}>{formatter.format(cartItem.cost)}</Box>
    </Box>;
}

const DisplayFeeAnswers = ({feeAnswers} : {feeAnswers: CourseAnswer[]}) => {
    const [hide, setHide] = React.useState(false);
    return <>
        <Button
            size="small"
            onClick={() => setHide(!hide)}
            sx={{
                textTransform: 'none',
                color: 'text.secondary',
                textAlign: 'left',
                justifyContent: "flex-start"
            }}
        >
            <Typography variant="body2">
                {hide ? 'Hide' : 'Show'} details
            </Typography>
            {hide ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </Button>
        <Collapse in={hide}>
            <Box
                sx={{
                    p: 1.5,
                    borderLeft: '2px solid',
                    borderColor: 'primary.main',
                    bgcolor: 'primary.50',
                    borderRadius: '0 4px 4px 0'
                }}
            >
                {feeAnswers.map((response) => {
                    return (
                        <Box key={response.id}>
                            <Typography variant="caption" color="text.secondary" display="block">
                                {response.cachedQuestion}
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {response.answer}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
        </Collapse>
    </>;
}

export default CartSummaryRegistrationListItem;
