import NavButton from "@/components/Navigation/NavButton.tsx";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import {useUser} from "@/components/Providers/JWTProvider.tsx";

type Props = {
    hideBorder?: boolean;
};

const FeesNavButton = ({hideBorder} : Props) => {
    const {isEmployee} = useUser()
    return (<>
            <NavButton
                text='Fees'
                link={isEmployee ? '/instructor/fees' : '/fees'}
                icon={<RequestPageIcon/>}
                hideBorder={hideBorder}
            />
        </>
    );
};

export default FeesNavButton;
