import {CircularProgress} from "@mui/material";

const CircularLoadingIndicator = () => (
    <div className="d-flex flex-column w-100 mt-5 align-items-center">
        <CircularProgress />
        <h3 className="pt-2 pl-3">Loading ...</h3>
    </div>
);

export default CircularLoadingIndicator;
