import NavBase from './NavBase';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import {Nav, Navbar} from "react-bootstrap";
import {jwtInstructorContext, useSignOutInstructor} from "../Providers/JWTProvider";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import InstructorInstructionsModal from "@/components/Modal/InstructorInstructionsModal.tsx";
import {useContext, useState} from "react";
import FeesNavButton from "@/components/Navigation/FeesNavButton.tsx";
import CartNavButton from "@/components/Navigation/CartNavButton.tsx";
import NavButton from "@/components/Navigation/NavButton.tsx";

type Props = {
    showNav ?: boolean;
}

const InstructorHeader = ({showNav} : Props) => {
    const [showInstructorInstructionsModal, setShowInstructorInstructionsModal] = useState(false);
    const signOutInstructor = useSignOutInstructor();
    const {user} = useContext(jwtInstructorContext);

    return (
        <NavBase>
            <div className="text-right d-flex flex-row">
                <Navbar.Toggle aria-controls="chris-nav" />
                {showNav && (
                    <Navbar.Collapse id="glenbrook-nav" className="">
                        <Nav className="mr-auto">
                            <FeesNavButton
                                hideBorder={user?.role.includes('fee_admin')}
                            />

                            {user?.role.includes('fee_admin') && <NavButton
                                text='Fee Requests'
                                link='/instructor/fee-proposals'
                                icon={<FactCheckIcon/>}
                            />}

                            {user?.role.includes('instructor') && <NavButton
                                text='My Activities'
                                link='/instructor'
                                icon={<ContactPageIcon/>}
                            />}

                            <NavButton
                                text='Instructions'
                                onClick={() => {setShowInstructorInstructionsModal(true)}}
                                icon={<InfoIcon/>}
                                hideBorder={true}
                            />

                            <CartNavButton/>

                            <NavButton
                                text='Sign Out'
                                onClick={() => {
                                    signOutInstructor();
                                }}
                                icon={<LogoutIcon/>}
                            />
                        </Nav>
                    </Navbar.Collapse>
                )}
            </div>
            <InstructorInstructionsModal
                show={showInstructorInstructionsModal}
                handleClose={() => setShowInstructorInstructionsModal(false)}
            />
        </NavBase>
    );
};

export default InstructorHeader;
