import {Col, Row} from "react-bootstrap";

const NoRegisteredCourses = () => {

    return (
        <Row style={{width:'100%'}}>
            <Col sm={12} className="bg-white py-3 mx-0 px-0 main-content d-flex flex-row justify-content-center">
                <div className="d-flex flex-column align-items-center">
                        <h2 className="mt-5">You have not registered for any courses.</h2>

                </div>
            </Col>
        </Row>
    );
};

export default NoRegisteredCourses;
