import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Box } from '@mui/material';
import { AlertCircle } from 'lucide-react';

interface PartialPaymentModalProps {
    open: boolean;
    onClose: () => void;
    onViewPurchased: () => void;
}

export const PartialPaymentModal: React.FC<PartialPaymentModalProps> = ({
    open,
    onClose,
    onViewPurchased,
}) => (
    <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
            sx: {
                borderRadius: 2,
                p: 1
            }
        }}
    >
        <DialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <AlertCircle size={24} color="#6B21A8" />
                <Typography variant="h6">Attention: Partial Payments Found</Typography>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Typography variant="body1">
                You have fees with partial payments that require completion. Please review your purchased fees to complete any remaining payments.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                <Button onClick={onClose} variant="outlined" color="secondary">
                    Close
                </Button>
                <Button
                    onClick={onViewPurchased}
                    variant="contained"
                    color="secondary"
                >
                    View Purchased Fees
                </Button>
            </Box>
        </DialogContent>
    </Dialog>
);
