import React from 'react';
import NavHeader from "../components/Navigation/NavHeader";
import {Col, Container, Row} from "react-bootstrap";
import {ProgramsProviderContext} from "../components/Providers/ProgramsProvider";
import EditCommunityMemberForm from "../components/Forms/EditCommunityMemberForm";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

const EditProfile = () => {
    const [programsState,,clearRegistrationError] = React.useContext(ProgramsProviderContext);

    return (
        <>
            <NavHeader showNav={true}/>
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Col sm={12} className="bg-white py-3 mx-0 px-3 main-content mt-5">
                        <EditCommunityMemberForm/>
                    </Col>
                </Row>
            </Container>
            <GlenSnackBar
                onClose={clearRegistrationError}
                show={programsState.showAlert}
                severity={programsState.alertVariant}
                message={programsState.message}/>
        </>
    );
};

export default EditProfile;
