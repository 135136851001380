import {Alert, Button, Divider, Stack, SxProps, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {formatter} from "@/Helpers/formatter.ts";
import {useUser} from "@/components/Providers/JWTProvider.tsx";
import {useNavigate} from "@tanstack/react-router";
import useHandleMailInSubmit from "@/components/Cart/useHandleMailInSubmit.ts";
import {useState} from "react";

type Props = {
    isPayButtonDisabled?: boolean;
    totalCost : number;
    cartIsEmpty : boolean;
    errorMessage : string;
    sx ?: SxProps;
    cartPage ?: boolean;
    triggerPayment?: () => void;
}

const TotalRow = ({
    totalCost, cartIsEmpty, errorMessage, cartPage = false, sx = {}, isPayButtonDisabled, triggerPayment
}: Props) => {
    const navigate = useNavigate();
    const {isEmployee} = useUser();
    const handelMailInSubmit = useHandleMailInSubmit();
    const [submitOrderRunning, setSubmitOrderRunning] = useState(false);

    return <>
        <Divider/>
        <Stack sx={{flexDirection: 'row', py: 1, pr: 2}}>
            <Typography sx={{flexGrow: 1, fontSize: 'h6.fontSize'}}>Total:</Typography>
            <Typography sx={{fontSize: 'h6.fontSize'}}>{formatter.format(totalCost)}</Typography>
        </Stack>
        {!cartIsEmpty && totalCost === 0 && (<Alert severity="info">
            Your cart contains only free items.  No Payment is required.  You can submit your order directly.
        </Alert>)}
        <Stack
            sx={{
                flexDirection: {xs: 'column', lg: 'row'},
                justifyContent: 'center',
                px: 2,
                pb: 2,
                ...sx,
            }}
        >
            {!cartIsEmpty && <Button
                variant="contained"
                type={'submit'}
                color={totalCost > 0 ? "secondary" : 'success'}
                onClick={async () => {
                    if (cartPage) {
                        triggerPayment && triggerPayment();
                        return;
                    } else if (totalCost === 0) {
                        setSubmitOrderRunning(true);
                        handelMailInSubmit(totalCost).finally(() => setSubmitOrderRunning(false));
                        return;
                    }

                    await navigate({to: isEmployee ? '/instructor/cart' : '/cart'});
                }}
                disabled={isPayButtonDisabled || submitOrderRunning}
                sx={{
                    mt: 3,
                    mb: 1,
                    fontWeight: 800,
                    width: '100%'
                }}
            >
                {totalCost > 0 && (cartPage ? `Pay ${formatter.format(totalCost)} Now` : `Proceed to Checkout`)}
                {totalCost === 0  && ('Submit Order')}
            </Button>}
            {cartIsEmpty && <Typography variant={'h6'} sx={{mt: 3, mb: 1, fontWeight: 800}}>
                Your cart is empty.
            </Typography>}
            {errorMessage && <Grid size={{xs: 12}} sx={{color: 'red'}}>
                {errorMessage}
            </Grid>}
        </Stack>
    </>;
}

export default TotalRow;
