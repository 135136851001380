import NavHeader from "../components/Navigation/NavHeader";
import {type ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {Box, Card, Stack, Typography} from "@mui/material";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import TotalRow from "@/components/Cart/TotalRow.tsx";
import MainDisplayContainer from "@/components/Layout/MainDisplayContainer.tsx";
import AssessedFeeList from "@/components/Fees/AssessedFeeList.tsx";
import OptionalFeeList from "@/components/Fees/OptionalFeeList.tsx";
import FeeToggle from "@/components/Fees/FeeToggle.tsx";
import CartSummaryList from "@/components/Cart/CartSummaryList.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import PurchasedFeesList from "@/components/Fees/PurchasedFeesList.tsx";
import {useUser} from "@/components/Providers/JWTProvider.tsx";
import InstructorHeader from "@/components/Navigation/InstructorHeader.tsx";
import {useNavigate, useSearch} from "@tanstack/react-router";
import {PartialPaymentModal} from "@/components/Fees/PartialPaymentModal.tsx";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

const FeePage = () : ReactElement => {
    const search = useSearch({strict: false});
    const [showPartialPaymentModal, setShowPartialPaymentModal] = useState(false);
    const {isEmployee} = useUser();
    const {getCartAlertStatus, resetCartAlertStatus, getCartTotal, cartIsEmpty} = useCartContext();
    const {optionalFees, assessedFees, purchasedFees} = useFeeProvider();
    const totalCost = getCartTotal();
    const cartStatus = getCartAlertStatus();
    const [errorMessage] = useState('');
    const [feeToggleValue, setFeeToggleValue] = useState<number>(0);
    const cartRef = useRef<null | HTMLSpanElement>(null);
    const navigate = useNavigate();

    const hasPartialFees = useMemo(() => {
        return purchasedFees.some(pf => pf.status === "partial")
    }, [purchasedFees]);

    useEffect(() => {
        if (hasPartialFees && feeToggleValue !== 2) {
            setShowPartialPaymentModal(true);
        } else {
            setShowPartialPaymentModal(false);
        }
    }, [hasPartialFees]);

    useEffect(() => {
        if ('purchased' in search) {
            setFeeToggleValue(2);
            navigate({
                search: undefined,
            }).catch(e=> console.error('navigation error', e));
        }
    }, []);

    return <Stack sx={{backgroundColor: '#631d79', flexDirection: 'column', height: '100vh'}}>
        {!isEmployee && <NavHeader showNav={true}/>}
        {isEmployee && <InstructorHeader showNav={true}/>}
        <MainDisplayContainer>
            <Box
                sx={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '60%'},
                        overflowY: {
                            md: 'auto'
                        },
                        px: 2,
                        pt: 2,
                        mb: 2,
                        backgroundColor: '#fff',
                        borderRadius: 1,
                    }}
                    className="marco"
                >
                    <Stack
                        className="pllo"
                        sx={{
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                        <Box>
                            <FeeToggle feeToggleValue={feeToggleValue} setFeeToggleValue={setFeeToggleValue} hasPartialFees={hasPartialFees}/>
                        </Box>
                        <Stack
                            sx={{
                                flexDirection: 'column',
                                flexGrow: 1,
                                overflowY:  'auto',
                            }}
                        >
                            {feeToggleValue === 0 && assessedFees && <AssessedFeeList cartRef={cartRef} assessedFees={assessedFees}/>}
                            {feeToggleValue === 1 && optionalFees && <OptionalFeeList cartRef={cartRef} optionalFees={optionalFees}/>}
                            {feeToggleValue === 2 && purchasedFees && <PurchasedFeesList purchasedFees={purchasedFees}/>}
                        </Stack>
                    </Stack>
                </Stack>
                <Box sx={{
                    width: {xs: '100%', md: '40%'},
                    pl: {md: 2},
                }}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            px: 2,
                            pt: 2,
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            sx={{
                                py: 1,
                                color: '#000',
                            }}
                        >
                            <span ref={cartRef}>Cart Summary</span>
                        </Typography>
                        <CartSummaryList/>
                        <TotalRow
                            totalCost={totalCost}
                            cartIsEmpty={cartIsEmpty}
                            errorMessage={errorMessage}
                        />
                    </Card>
                </Box>
            </Box>
            <GlenSnackBar onClose={resetCartAlertStatus} show={cartStatus.showAlert} severity={cartStatus.alertVariant} message={cartStatus.message}/>
        </MainDisplayContainer>
        {showPartialPaymentModal && <PartialPaymentModal
            open={showPartialPaymentModal}
            onClose={() => {
                setShowPartialPaymentModal(false);
            }}
            onViewPurchased={() => {
                setFeeToggleValue(2);
                setShowPartialPaymentModal(false);
            }}
        />}
    </Stack>
};

export default FeePage;
