import * as React from 'react';
import {Box, IconButton, Stack} from "@mui/material";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import CartSummaryRegistrationListItem from "@/components/Cart/CartSummaryRegistrationListItem.tsx";
import {X} from "lucide-react";

const CartSummaryList = () : React.ReactElement => {
    const {deleteCartItem, normalizedCart} = useCartContext();

    return <Box
        sx={{
            flexGrow: 1,
            overflowY: {xs: 'visible', md: 'auto'},
        }}
    >
        {normalizedCart.map(normalizedCartItem => <Box
            key={`payment-due-${normalizedCartItem.cartItem.id}`}
            sx={{
            }}
        >
            <Stack sx={{flexDirection: 'row', py: 1, pr: 2}}>
                <CartSummaryRegistrationListItem normalizedCartItem={normalizedCartItem}/>
                <Box>
                    <IconButton
                        edge="end"
                        onClick={() => {
                            deleteCartItem(normalizedCartItem.itemGroup.map(ci => ci.id));
                        }}
                        color="error"
                    >
                        <X/>
                    </IconButton>
                </Box>
            </Stack>
        </Box>)}
    </Box>;
}

export default CartSummaryList;
