import {jwtContext} from "../Providers/JWTProvider";
import {Program, ProgramsProviderContext} from "../Providers/ProgramsProvider";
import {z} from "zod";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useCallback, useContext} from "react";
import Grid from "@mui/material/Grid2";
import {RhfRadioGroup} from "mui-rhf-integration";
import {Button, Divider} from "@mui/material";

const schema = z.object({
    isAtRisk : z.coerce.boolean(),
});

type SurveyFormSchemaType = z.infer<typeof schema>;

type Props = {
    setNeedsFinishSurvey: (a : boolean) => void;
    program: Program
};

const SurveyForm = ({setNeedsFinishSurvey, program} : Props) => {
    const user = useContext(jwtContext)
    const [,,,answerSurvey] = useContext(ProgramsProviderContext);


    const initialValues = {
        isAtRisk : undefined,
    };

    const form = useForm<SurveyFormSchemaType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema)
    });

    const studentName = user ? `${user.nameFirst}` : 'Student';

    const handleSurveySubmit = useCallback(async (values: SurveyFormSchemaType) => {
        if (!answerSurvey) return;
        const surveyAnswers = {
            isAtRisk: values.isAtRisk
        }
        const success = await answerSurvey(surveyAnswers, program);
        if (success) {
            setNeedsFinishSurvey(false);
        }
    },[answerSurvey, program, setNeedsFinishSurvey]);

    return <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSurveySubmit)} className="form-bg pt-2 container">
            <Grid container>
                <Grid size={{xs: 12}}>
                    <p>The majority of summer school courses will be held in-person at Glenbrook South High School.
                        These courses have been designated with a delivery format of "In Person".</p>
                    <p>Certain courses have been designated with a delivery format of "Online". These courses will not
                        meet in person, and will solely meet online using Zoom.</p>
                    <p>If <strong>{studentName}</strong> is at increased risk of severe illness or lives with someone at
                        increased risk, you may submit a request to attend in person courses remotely. If this situation
                        applies to you, please indicate so below:</p>
                </Grid>
                <Grid size={{xs: 12}}>
                    <RhfRadioGroup control={form.control} name={'isAtRisk'} label={''} options={[
                        {value: 'true', label: `${studentName} is at increased risk of severe illness or lives with someone at increased risk, and requests to attend in person courses remotely.  (Note: Documentation will be required.)`},
                        {value: 'false', label: `This situation does not apply for ${studentName}`}
                    ]}/>
                </Grid>
                <Grid size={{xs: 12}}>
                    <Divider />
                </Grid>
                <Grid size={{xs: 12}}>
                    <Button type={'submit'}>Save</Button>
                </Grid>
            </Grid>
        </form>
    </FormProvider>
};

export default SurveyForm;
