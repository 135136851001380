import {type ReactElement, useContext, useState} from "react";
import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import FeeProposals from "@/components/Fees/FeeProposals.tsx";
import FeeProposalRequestForm from "@/components/Forms/FeeProposalRequestForm.tsx";
import {FormProvider, useForm} from "react-hook-form";
import {useFeeProposalsProvider} from "@/components/Providers/InstructorFeeProposalsProvider.tsx";
import {FeeRequest} from "@/types/FeeRequest.ts";
import StatusHistoryModal from "@/components/Fees/StatusHistoryModal.tsx";
import {jwtInstructorContext, ROLE_FEE_ADMIN} from "@/components/Providers/JWTProvider.tsx";
import {useNavigate} from "@tanstack/react-router";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import StandardPage from "@/components/StandardPage.tsx";

const FeeProposalsPage = () : ReactElement => {
    const {user} = useContext(jwtInstructorContext);
    const navigate = useNavigate();
    const methods = useForm()
    const {
        feeProposals,
        showAlert,
        alertVariant,
        message,
        clearAlert,
    } = useFeeProposalsProvider();

    const [feeRequestDefaults, setFeeRequestDefaults] = useState<FeeRequest | undefined>();
    const [showStatusHistoryModal, setShowStatusHistoryModal] = useState<boolean>(false);
    const [showFeeProposalForm, setShowFeeProposalForm] = useState<boolean>(false);
    const [selectedFeeRequest, setSelectedFeeRequest] = useState<FeeRequest | null>(null);

    if (user && !user.role.includes(ROLE_FEE_ADMIN)) {
        navigate({
            to: '/instructor/fees'
        }).catch(e => console.error(e));
    }

    return <StandardPage>
            <FormProvider {...methods}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        minHeight: '80vh',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            width: '100%',
                            backgroundColor: '#fff',
                            px: 2,
                            pt: 2,
                            borderRadius: 1,
                        }}
                    >
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-between',
                                mb: 1,
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                sx={{
                                    pt: 1,
                                    pb: 1,
                                    color: '#000',
                                }}
                            >
                                My Fee Requests
                            </Typography>

                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setSelectedFeeRequest(null);
                                    setFeeRequestDefaults(undefined);
                                    setShowFeeProposalForm(true);
                                }}
                                sx={{
                                    p: 1,
                                }}
                            >
                                <AddCircleOutlineIcon sx={{mr: 1}}/>
                                Add Request
                            </Button>
                            {showFeeProposalForm && <FeeProposalRequestForm
                                show={showFeeProposalForm}
                                setShow={(show: boolean) => {
                                    setFeeRequestDefaults(undefined);
                                    setShowFeeProposalForm(show);
                                }}
                                feeRequest={selectedFeeRequest}
                                setFeeRequest={setSelectedFeeRequest}
                                feeRequestDefaults={feeRequestDefaults}
                            />}
                        </Stack>
                        <Divider/>
                        {feeProposals && <FeeProposals
                            feeRequests={feeProposals}
                            setShowForm={setShowFeeProposalForm}
                            setSelectedFeeRequest={setSelectedFeeRequest}
                            setShowStatusHistoryModal={setShowStatusHistoryModal}
                            setFeeRequestDefaults={(feeRequest: FeeRequest) => {
                                setShowFeeProposalForm(true);
                                setFeeRequestDefaults(feeRequest);
                            }}
                        />}
                    </Box>
                </Box>
            </FormProvider>
            <GlenSnackBar
                onClose={clearAlert}
                show={showAlert}
                severity={alertVariant}
                message={message}/>
            <StatusHistoryModal
                show={showStatusHistoryModal}
                handleClose={() => {
                    setShowStatusHistoryModal(false);
                    setSelectedFeeRequest(null);
                }}
                feeRequest={selectedFeeRequest}
            />
        </StandardPage>;
};

export default FeeProposalsPage;
