import {useFieldArray, useFormContext} from "react-hook-form";
import Grid from "@mui/material/Grid2";
import {RhfTextField} from "mui-rhf-integration";
import {MenuItem} from "@mui/material";
import PhoneNumberField from "@/components/Forms/Fields/PhoneNumberField.tsx";

export type Guardian = {
    firstName: string;
    lastName: string;
    relationship: string;
    relationshipOther: string;
    id?: string;
};
export type List = {
    name: string;
    values: Array<StandardFieldOption>;
};

export type StandardFieldOption = {
    label: string;
    value: string;
    checked: boolean;
};

const GuardianForm = () => {
    const {control, register} = useFormContext();

    const {fields} = useFieldArray({
        control,
        name: "guardians"
    });

    return fields.map((guardian, index) => <Grid container spacing={2} sx={{justifyContent: 'center'}} key={`guardian-${index}`}>
        <Grid size={{xs: 12}} sx={{mt: 2}}>
            {index === 0 && <h3 style={{color: 'black'}}>Contact 1 – Parent or Guardian</h3>}
            {index > 0 && <h3 style={{color: 'black'}}>Contact {index + 1} – Parent, Guardian, or Emergency Contact</h3>}
        </Grid>
        {guardian.id && (<input
            type='hidden'
            {...register(`guardians[${index}].id`)}
            name={`guardians[${index}].id`}
            value={guardian.id}
        />)}
        <Grid size={{xs: 12, lg: 6}}>
            <RhfTextField
                control={control}
                name={`guardians.${index}.nameFirst`}
                label={'First Name'}
                fullWidth
            />
        </Grid>
        <Grid size={{xs: 12, lg: 6}}>
            <RhfTextField
                control={control}
                name={`guardians.${index}.nameLast`}
                label={'Last Name'}
                fullWidth
            />
        </Grid>
        <Grid size={{xs: 12, lg: 6}}>
            <RhfTextField
                control={control}
                name={`guardians.${index}.relationship`}
                label={'Relationship'}
                select
                fullWidth
            >
                <MenuItem value={'Mother'}>Mother</MenuItem>
                <MenuItem value={'Father'}>Father</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
            </RhfTextField>
        </Grid>
        <Grid size={{xs: 12, lg: 6}}>
            <RhfTextField
                control={control}
                name={`guardians.${index}.relationshipOther`}
                label={'Other'}
                fullWidth
            />
        </Grid>
        <Grid size={{xs: 12, lg: 6}}>
            <PhoneNumberField
                label={'Phone Number 1'}
                name={`guardians.${index}.phone1`}
                />
        </Grid>

        <Grid size={{xs: 12, lg: 6}}>
            <RhfTextField
                control={control}
                name={`guardians.${index}.phone1Type`}
                label={'Type'}
                select
                fullWidth
            >
                <MenuItem value={'Home'}>Home</MenuItem>
                <MenuItem value={'Work'}>Work</MenuItem>
                <MenuItem value={'Mobile'}>Mobile</MenuItem>
            </RhfTextField>
        </Grid>
        <Grid size={{xs: 12, lg: 6}}>
            <PhoneNumberField
                label={'Phone Number 2'}
                name={`guardians.${index}.phone2`}
            />
        </Grid>

        <Grid size={{xs: 12, lg: 6}}>
            <RhfTextField
                control={control}
                name={`guardians.${index}.phone2Type`}
                label={'Type'}
                select
                fullWidth
            >
                <MenuItem value={'Home'}>Home</MenuItem>
                <MenuItem value={'Work'}>Work</MenuItem>
                <MenuItem value={'Mobile'}>Mobile</MenuItem>
            </RhfTextField>
        </Grid>
        <Grid container size={{xs: 12}} justifyContent={'left'}>
        <Grid size={{xs: 12, lg: 6}}>
            <RhfTextField
                control={control}
                name={`guardians.${index}.email`}
                label={'Email Address'}
                fullWidth
            />
        </Grid>
        </Grid>
    </Grid>);
};

export default GuardianForm;
