import ModalBase from "./ModalBase";
import {CourseQuestion} from "../Providers/ProgramsProvider";
import {Fee} from "@/types/Fee.ts";
import {z} from "zod";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid2";
import {RhfTextField} from "mui-rhf-integration";
import {Button, Divider, MenuItem} from "@mui/material";

type Props = {
    show: boolean;
    handleClose: () => void;
    fee: Fee;
    performActionBasedOnCourseStatus: (fee: Fee, skipQuestions: boolean) => Promise<void>;
};

export const getInitialValuesFromCourseQuestions = (listOfCourseQuestions : CourseQuestion[]) => {
    let initialValues = {};

    listOfCourseQuestions.map((question) => {
        initialValues = {
            ...initialValues,
            [question.id]: '',
        }
    });
    return initialValues;
}

export const getSchemaFromCourseQuestions = (listOfCourseQuestions : CourseQuestion[]) => {
    let validationSchema = {};

    listOfCourseQuestions.map((question) => {
        validationSchema = {
            ...validationSchema,
            [question.id]: z.string(),
        }
    });
    return validationSchema;
}

const FeeQuestionModal = ({
    show,
    handleClose,
    fee,
    performActionBasedOnCourseStatus
}: Props) => {
    const listOfCourseQuestions = fee.courseQuestions;

    if (!listOfCourseQuestions) {
        return <>Error: This modal loaded but the course has no questions</>
    }

    const initialValues = getInitialValuesFromCourseQuestions(listOfCourseQuestions);
    const schema = z.object(getSchemaFromCourseQuestions(listOfCourseQuestions));
    type FeeQuestionSchemaType = z.infer<typeof schema>;

    const form = useForm<FeeQuestionSchemaType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema),
    })

    const handleSubmit = async (values : FeeQuestionSchemaType) => {
        const arrayOfQuestionIDs = Object.keys(values);

        const listOfCourseQuestionsWithAnswers = listOfCourseQuestions.map((question, index) => {
            const key = arrayOfQuestionIDs[index] as keyof FeeQuestionSchemaType;
            return {...question, userSubmittedAnswer: values[key]}
        })

        fee = {
            ...fee,
            courseQuestions: listOfCourseQuestionsWithAnswers,
        };

        await performActionBasedOnCourseStatus(fee, false);
        handleClose();
    }

    return (
        <ModalBase
            show={show}
            title={"Before registering, please answer the following questions:"}
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    {listOfCourseQuestions.map((question) => {
                        return <Grid container className={'chris-form-bg'} key={`question-${question.id}`}>
                            <Grid size={{xs: 6}}>
                                {question.question}
                                {question.responseType === 'Value List' &&
                                    <RhfTextField
                                        control={form.control}
                                        name={question.id as keyof FeeQuestionSchemaType}
                                        select
                                        fullWidth
                                    >
                                        {question.answerList?.map(answer => <MenuItem value={answer}>{answer}</MenuItem>)}
                                    </RhfTextField>
                                }
                                {question.responseType === 'Free Form' &&
                                    <RhfTextField
                                        control={form.control}
                                        name={question.id as keyof FeeQuestionSchemaType}
                                        fullWidth
                                    />
                                }
                            </Grid>
                        </Grid>
                    })}
                    <Grid container>
                        <Grid size={{xs: 12}}><Divider/></Grid>
                    </Grid>
                    <Grid container>
                        <Grid size={{xs: 12}} sx={{
                            justifyContent: 'end',
                            flexDirection: 'row',
                            display: 'flex',
                        }}>
                            <Button
                                variant={"contained"}
                                type="submit"
                                sx={{
                                    m: 1,
                                }}
                            >
                                Continue
                            </Button>
                            <Button
                                sx={{
                                    m: 1,
                                }}
                                variant="outlined"
                                type="button"
                                className="d-flex pt-2 flex-row align-content-center mr-2"
                                onClick={async () => {
                                    handleClose();
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </ModalBase>);
}

export default FeeQuestionModal;
