import * as React from 'react';
import {InputAdornment, SxProps, TextField} from "@mui/material";
import {useFormContext} from "react-hook-form";

type Props = {
    name: string;
    label: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    arrayName?: string;
    iteration?: number;
    sx?: SxProps;
}

const PaymentFormPaymentAmount = ({name, onChange, arrayName, iteration, sx, label}: Props) => {
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const formContext = useFormContext();
    const fieldErrors = formContext.formState.errors[fieldName];

    return <>
        <TextField
            name={name}
            type="text"
            label={label}
            placeholder={label}
            value={formContext.getValues(fieldName)}
            disabled={false}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                let value = event.target.value;
                if (event.target.value) {
                    const periodTail = event.target.value.indexOf('.') === event.target.value.length - 1;
                    const numberValue = parseFloat(event.target.value);
                    const flooredPrice = Math.floor(isNaN(numberValue) ? 0 : numberValue * 100) / 100;
                    value = `${flooredPrice}${periodTail ? '.' : ''}`;
                }
                formContext.setValue(fieldName, value);
                onChange && onChange(event);
            }}
            slotProps={{
                input: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }
            }}
            sx={{
                my: 2,
                ...sx,
            }}
            error={formContext.formState.isSubmitted && !formContext.formState.isValid}
            helperText={fieldErrors?.message !== undefined && (
                    <>{fieldErrors.message.toString()}</>
                )}
        />
    </>
}

export default PaymentFormPaymentAmount;
