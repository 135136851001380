import {useElements, useStripe} from "@stripe/react-stripe-js";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import LocalStorageTimer from "../LocalStorageTimer.tsx";
import {apiEndpoint, jwtContext} from "../Providers/JWTProvider.tsx";
import {
    ProgramCourse,
    ProgramsProviderContext,
    STATUS_WAITLISTED
} from "../Providers/ProgramsProvider.tsx";
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import PaymentFormPaymentAmount from "@/components/Forms/Fields/PaymentFormPaymentAmount.tsx";
import {formatter} from "@/Helpers/formatter.ts";
import {buildPayAmount} from "@/components/Forms/CartPaymentForm.tsx";
import {Alert, Box, Button, CircularProgress, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid2";
import StripeForm from "@/components/StripeForm.tsx";
import useConfirmMailIn from "@/hooks/useConfirmMailIn.tsx";
import ButtonCollection from "@/components/Forms/Fields/ButtonCollection.tsx";
import BigNumber from "bignumber.js";
import {z} from "zod";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfRadioGroup} from "mui-rhf-integration";

type Props = {
    course: ProgramCourse;
    programName: string;
    handleClose: (showCancelModal?: boolean, showConfirmModal?: boolean) => void;
    handleLocalCancelOrClose: () => Promise<void>;
    setConfirmationId: (confirmationId: string) => void;
    switchPayment?: boolean;
    courseHasCreditsApplied: boolean;
    show : boolean;
    cancellationPending : boolean;
    setCancellationPending : Dispatch<SetStateAction<boolean>>
};

const ErrorFallback = ({error , resetErrorBoundary} : FallbackProps) => {
    return (
        <div role="alert">
            <p>There was a issue loading the credit card payments</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
};

export const getPaymentFormSchema = (cost : number, minimum : number) => {
    return z.object({
        paymentMethod: z.string(),
        paymentType: z.string().optional(),
        partialPaymentAmount: z.coerce.number().max(cost).optional(),
        tendered: z.coerce.number().max(cost + 100, `Tendered amount can't be more then 100 over the payment amount`).optional(),
    }).refine((data) => {
            if (data.paymentType !== 'partial') {
                return true;
            } else {
                return data.partialPaymentAmount ? /^\d+(\.\d{1,2})?$/.test(data.partialPaymentAmount.toString()) : false
            }
        },
        {path: ['partialPaymentAmount'], message: 'Payment must have 2 digits after decimal or less'}
    ).refine((data) => {
            if (data.paymentMethod === 'fab') {
                return true;
            } else {
                return data.paymentType && data.paymentType !== '';
            }
        },
        {path: ['paymentType'], message: 'Payment Type is a required field.'}
    ).refine((data) => {
            if (data.paymentType !== 'partial') {
                return true;
            } else {
                return data.partialPaymentAmount ? data.partialPaymentAmount >= (minimum) : false
            }
        },
        {
            path: ['partialPaymentAmount'],
            message: `Payment amount must be at least 10% of the total cost (${formatter.format(minimum)}).`
        }
    ).refine((data) => {
            if (data.paymentMethod === 'cash') {
                if (!data.tendered) {
                    return false;
                }
            }
            return true;
        },
        {
            path: ['tendered'],
            message: `Tendered amount is required for cash payments.`
        }
    ).refine((data) => {
            if (data.paymentMethod === 'cash' && data.tendered) {
                if (data.paymentType === 'partial' && data.partialPaymentAmount) {
                    if (data.tendered < data.partialPaymentAmount) {
                        return false
                    }
                } else if (data.paymentType !== 'partial') {
                    if (data.tendered < cost) {
                        return false
                    }
                }
            }
            return true;
        },
        {
            path: ['tendered'],
            message: `Tendered amount must be greater then or equal to the payment amount.`
        }
    )
}

export type PaymentFormSchemaType = z.infer<ReturnType<typeof getPaymentFormSchema>>;

const PaymentForm = (
    {
        course,
        programName,
        handleClose,
        handleLocalCancelOrClose,
        setConfirmationId,
        switchPayment,
        courseHasCreditsApplied,
        show,
        cancellationPending,
        setCancellationPending
    } : Props) => {
    const stripe = useStripe();
    const {addCartItem} = useCartContext();
    const elements = useElements();
    const user = React.useContext(jwtContext);
    const [, performRegistrationAction] = React.useContext(ProgramsProviderContext);
    const resetRegistrationStatus = React.useContext(ProgramsProviderContext)[5];
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPayNow, setShowPayNow] = useState<boolean>(false);
    const [submitStripeValues, setSubmitStripeValues] = useState<PaymentFormSchemaType | null>(null);
    const [stripeErrorMessage, setStripeErrorMessage] = useState('');
    const confirmMailIn = useConfirmMailIn();

    const freeMode = course.financialAid === 'free' && course.cost === 0;

    useEffect(() => {
        if (freeMode) {setShowPayNow(true)}
    }, [freeMode]);

    const courseCostIsZero = course.cost === 0;
    const waitlistMode = course.status === STATUS_WAITLISTED;

    const timeout = useMemo(() : string | null => {
        const localStorageItemString = localStorage.getItem('registration-course-' + course.courseSelectionUUID);
        const localStorageItem = localStorageItemString ? JSON.parse(localStorageItemString) : null;
        return localStorageItem && 'timeout' in localStorageItem && typeof localStorageItem.timeout === 'string'
            ? localStorageItem.timeout satisfies string : null;
    }, [course, show]);

    useEffect(() => {
        setIsProcessing(false);
        setCancellationPending(false);
    }, [show]);

    const confirmFab = async () => {
        const url = new URL(`/v1/payment/confirm-fab`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        if (resetRegistrationStatus && switchPayment) {
            await resetRegistrationStatus(course);
        }

        return await user?.apiFetch(url.toString(), init);
    };

    const confirmFreeAfterCreditsApplied = async () => {
        const url = new URL(`/v1/payment/confirm-free-after-cc`, apiEndpoint);
        let init : RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        return await user?.apiFetch(url.toString(), init);
    };

    const confirmWaitlist = async () => {
        const url = new URL(`/v1/payment/confirm-waitlist`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        return await user?.apiFetch(url.toString(), init);
    };

    const handleMailInSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmMailIn([course.courseSelectionUUID]);

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json();
                setConfirmationId(responseJson.confirmationIds[0]);

                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                if (resetRegistrationStatus && switchPayment) {
                    await resetRegistrationStatus(course);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID);
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleFabSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmFab();

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);

                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleFreeAfterCourseCredits = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmFreeAfterCreditsApplied();

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);
                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    }

    const handleWaitlistSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmWaitlist();

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const cancelRegistration = async (showCancelModal: boolean, reason : string) => {
        if (performRegistrationAction) {
            // cancel registration
            await performRegistrationAction(course, programName, reason);
        }

        localStorage.removeItem('registration-course-' + course.courseSelectionUUID);
        setCancellationPending(false);
        handleClose(showCancelModal);
    }

    const timeOutCancelRegistration = async () => {
        await cancelRegistration(true, 'Payment Timeout');
    }

    const schema = getPaymentFormSchema(
        course.cost,
        course.programAllowPartial ? course.cost/10 : course.cost,
    );

    const initialValues = {
        paymentMethod: waitlistMode ? 'waitlist' : (freeMode ? 'fab' : ''),
        paymentType: course.programAllowPartial ? '' : 'full',
        partialPaymentAmount: 0,
    }

    const form = useForm<PaymentFormSchemaType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema)
    });

    const paymentMethodWatch = form.watch('paymentMethod');
    const paymentTypeWatch = form.watch('paymentType');
    const partialPaymentAmountWatch = form.watch('partialPaymentAmount');

    const payNowAmount = buildPayAmount(
        paymentTypeWatch,
        course.cost - course.paidOrPendingAmount,
        partialPaymentAmountWatch?.toString()
    );

    const isPayButtonDisabled = !freeMode && (!paymentTypeWatch || !paymentMethodWatch || (paymentTypeWatch === 'partial' && (!partialPaymentAmountWatch || isNaN(partialPaymentAmountWatch))));

    const cost = new BigNumber(course.cost).minus(course.paidAmount).toNumber();
    const displayCost = formatter.format(cost);

    useEffect(() => {
        if (stripeErrorMessage !== '') {
            setIsProcessing(false);
            setSubmitStripeValues(null);
        }
    }, [stripeErrorMessage]);

    return (
        <>
            {timeout && (<Grid container className="chris-form-bg mx-0 py-1 px-2">
                <Grid size={{xs: 12}} style={{color: 'red', textAlign: 'right'}}>
                    You have <LocalStorageTimer timeout={timeout} callback={timeOutCancelRegistration} /> to Complete this step.
                </Grid>
            </Grid>)}
            <Grid container className="chris-form-bg mx-0 py-1 px-2">
                <Grid size={{xs: 12}}>
                    All fees must be paid in full by the due date for this activity. Any registrations with outstanding fees after the due date will be cancelled.
                </Grid>
            </Grid>
            <Grid container className="chris-form-bg mx-0 py-1 px-2">
                <Grid size={{xs: 12}}>
                    <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Activity</div>
                    <div>{user?.nameFirst + ' ' + user?.nameLast + ' - ' + user?.studentId}</div>
                    <div>{course.name + ', Section ' + course.sectionNumber}</div>
                </Grid>
            </Grid>
            <Grid container className="chris-form-bg mx-0 py-1 px-2">
                <Grid size={{xs: 12}}>
                    <div className="mt-2 mb-2">Cost: {displayCost}</div>
                </Grid>
            </Grid>
            {!cancellationPending && <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(async (values, event) => {
                        event?.stopPropagation();

                        setStripeErrorMessage('');
                        if (values.paymentMethod === 'stripe') {
                            setSubmitStripeValues(values);
                        } else if (values.paymentMethod === 'mail') {
                            await handleMailInSubmit();
                        } else if (values.paymentMethod === 'fab') {
                            await handleFabSubmit();
                        } else if (values.paymentMethod === 'freeAfterCourseCreditsApplied') {
                            await handleFreeAfterCourseCredits();
                        } else if (values.paymentMethod === 'waitlist') {
                            await handleWaitlistSubmit();
                        }
                    })}>
                        {waitlistMode && <Grid container className="chris-form-bg ml-2 pt-3">
                            <Grid size={{xs: 12}}>
                                <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                <p>The activity you requested is currently full. You will be added to the waitlist.</p>
                            </Grid>
                        </Grid>}
                        {courseCostIsZero && !waitlistMode && <Grid container className="chris-form-bg ml-2 pt-3">
                            <Grid size={{xs: 12}}>
                                <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                <div className="mt-2 mb-2">
                                    Cost: {displayCost}&nbsp;
                                    {course.courseCredits && (
                                        <div style={{display: 'inline'}}>
                                                <span style={{textDecoration: 'line-through', color: 'red'}}>
                                                        {formatter.format(course.originalCost)}
                                                </span>
                                            <div style={{display: 'inline', color: 'green'}}>&nbsp;Course credits applied!</div>
                                        </div>
                                    )}
                                </div>
                                {freeMode ? <RhfRadioGroup
                                        control={form.control}
                                        name="paymentMethod"
                                        label=""
                                        options={[
                                            {
                                                value: 'fab',
                                                label: `Financial Assistance Benefit`
                                            },
                                        ]}
                                    />
                                    : <RhfRadioGroup
                                        control={form.control}
                                        name="paymentMethod"
                                        label=""
                                        options={[
                                            {
                                                value: 'freeAfterCourseCreditsApplied',
                                                label: `No Payment needed`
                                            },
                                        ]}
                                    />}
                            </Grid>
                        </Grid>}
                        {!courseCostIsZero && !waitlistMode && showPayNow && <>
                            <Grid container className="chris-form-bg ml-2 py-3">
                                <Grid size={{xs: 12}}>
                                    <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                    <div className="mt-2">
                                        Cost: {displayCost}&nbsp;
                                        {course.courseCredits && (
                                            <div style={{display: 'inline'}}>
                                                <span style={{textDecoration: 'line-through', color: 'red'}}>
                                                        {formatter.format(course.originalCost)}
                                                </span>
                                                <div style={{display: 'inline', color: 'green'}}>&nbsp;Course credits applied!</div>
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            {course.programAllowPartial && <Grid container className="chris-form-bg ml-2 ">
                                <Grid size={{xs: 12}}>
                                    <ButtonCollection
                                        name="paymentType"
                                        options={[
                                            {
                                                value: 'full',
                                                label: `Pay ${displayCost} in Full`
                                            },
                                            {
                                                value: 'partial',
                                                label: 'Partial Payment',
                                                disabled: cost < .5 || cost < (course.cost * .1)
                                            }
                                        ]}
                                    />
                                </Grid>
                            </Grid>}
                            {paymentTypeWatch === 'partial' && <Grid container className="chris-form-bg ml-2 pt-3">
                                <Grid size={{xs: 12}}>
                                    <PaymentFormPaymentAmount name="partialPaymentAmount" label="Payment Amount"/>
                                </Grid>
                            </Grid>}
                            <Grid container className="chris-form-bg ml-2 py-3">
                                <Grid size={{xs: 12}}>
                                    <ButtonCollection
                                        name="paymentMethod"
                                        options={[
                                            {
                                                value: 'mail',
                                                label: 'Mail a Paper Check',
                                                disabled: !paymentTypeWatch || paymentTypeWatch === 'partial',
                                                hidden: paymentTypeWatch === 'partial'
                                            },
                                            {
                                                value: 'stripe',
                                                label: 'Pay Online',
                                                disabled: !paymentTypeWatch || payNowAmount < .5,
                                                selected: paymentTypeWatch === 'partial'
                                            }
                                        ]}
                                        sx={{
                                            display: paymentTypeWatch === 'partial' ? 'none' : 'block',
                                            mt: 1,
                                            mb: 2,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {paymentMethodWatch === 'mail' && <Grid container>
                                <Grid size={{xs: 12}}>
                                    <Alert severity="info" className="m-0 pt-0 ml-4">
                                        <div>Submit all checks to:</div>
                                        <div>Glenbrook High School District 225</div>
                                        <div>ATTN: Enrollment Specialist</div>
                                        <div>3801 W Lake Ave.</div>
                                        <div>Glenview, IL  60026</div>
                                        <div>BE SURE TO INDICATE THE PARTICIPANT'S NAME <span style={{textDecoration: 'underline'}}>AND</span> ID NUMBER</div>
                                    </Alert>
                                </Grid>
                            </Grid>}
                            {(paymentMethodWatch === 'stripe' || paymentTypeWatch === 'partial') && <Grid container>
                                <Grid size={{xs: 12}}>
                                    {(stripe === null || elements === null) ?  <p>Loading credit card payment form. If this message persists, try reloading the page or clearing your browser cache.</p> :
                                        <ErrorBoundary
                                            FallbackComponent={ErrorFallback}
                                            onReset={() => {
                                                form.setValue('paymentMethod', '');
                                            }}
                                        >
                                            <StripeForm
                                                cartId={null}
                                                courseSectionId={course.courseSelectionUUID}
                                                submitValues={submitStripeValues}
                                                payNowAmount={payNowAmount}
                                                setIsProcessing={setIsProcessing}
                                                handleModalClose={handleClose}
                                                stripeErrorMessage={stripeErrorMessage}
                                                setStripeErrorMessage={setStripeErrorMessage}
                                            />
                                        </ErrorBoundary>}
                                </Grid>
                            </Grid>}
                        </>}
                        <Grid size={{xs: 12}}><hr /></Grid>
                        {!cancellationPending && <Grid container className="mx-0 mt-2">
                            <Grid size={{xs: 12}} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                                {!isProcessing && <Button
                                    sx={{
                                        backgroundColor: '#6c757d',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#6c757d',
                                        },
                                        mr: 1,
                                    }}
                                    onClick={handleLocalCancelOrClose}
                                >Cancel</Button>}
                                {!freeMode && !isProcessing && <Button
                                    sx={{
                                        backgroundColor: '#631d79',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#631d79',
                                        },
                                        mr: 1,
                                    }}
                                    onClick={()  => {
                                        addCartItem(course.courseSelectionUUID);
                                        handleClose(false, false);
                                    }}
                                >
                                    <ShoppingCartIcon/>
                                    Add to Cart
                                </Button>}
                                {!showPayNow && <Button
                                    sx={{
                                        backgroundColor: '#631d79',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#631d79',
                                        },
                                        mr: 1,
                                    }}
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={() => setShowPayNow(true)}
                                >
                                    Pay Now {formatter.format(payNowAmount)}
                                </Button>}
                                {!isProcessing && showPayNow && <Tooltip title={isPayButtonDisabled ? 'Please fix the validation issues above' : ''}>
                                    <Button
                                        sx={{
                                            backgroundColor: '#631d79',
                                            color: '#fff',
                                            borderRadius: '8px',
                                            fontWeight: 800,
                                            '&:hover': {
                                                backgroundColor: '#631d79',
                                            },
                                        }}
                                        disabled={isPayButtonDisabled}
                                        type={'submit'}
                                    >
                                        <Box sx={{color: '#fff'}}>
                                            {waitlistMode ? 'Join Waitlist' : `Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                                        </Box>
                                    </Button>
                                </Tooltip>}

                                {isProcessing && <Button
                                    sx={{
                                        backgroundColor: '#631d79',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#631d79',
                                        },
                                    }}
                                    disabled={isPayButtonDisabled}
                                    type={'submit'}
                                >
                                    <CircularProgress
                                        size='1rem'
                                        sx={{
                                            mr: 1,
                                            color: '#fff',
                                        }}
                                    />
                                    <Box sx={{color: '#fff'}}>{`Processing Payment`}</Box>
                                </Button>}
                            </Grid>
                        </Grid>}
                    </form>
            </FormProvider>
            }
            {cancellationPending && <>
                <Grid container className="mx-0 mt-2">
                    <Grid size={{xs: 12}} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                        <Alert severity="warning">Closing this window before payment is provided will cancel your current registration. Do you wish to continue?</Alert>
                    </Grid>
                </Grid>
                <Grid container className="mx-0 mt-2">
                    <Grid size={{xs: 12}} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                        <Button
                            sx={{
                                backgroundColor: '#6c757d',
                                color: '#fff',
                                borderRadius: '8px',
                                fontWeight: 800,
                                '&:hover': {
                                    backgroundColor: '#6c757d',
                                },
                                mr: 1,
                            }}
                            onClick={() => setCancellationPending(false)}
                        >No, Provide Payment</Button>
                        <Button
                            sx={{
                                backgroundColor: '#631d79',
                                color: '#fff',
                                borderRadius: '8px',
                                fontWeight: 800,
                                '&:hover': {
                                    backgroundColor: '#631d79',
                                },
                                mr: 1,
                            }}
                            onClick={handleLocalCancelOrClose}
                        >Yes, Cancel Registration</Button>
                    </Grid>
                </Grid>
            </>}
        </>
    );
}

export default PaymentForm;
