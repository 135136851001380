import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {ChangeEvent, useEffect, useMemo} from 'react';
import {Box, Button, List, ListItem, SxProps} from "@mui/material";
import {useFormContext} from "react-hook-form";

type Props = {
    name: string;
    onChange?: (e: ChangeEvent<any>) => void;
    arrayName?: string;
    iteration?: number;
    options: {value: string, label: string, disabled?: boolean, hidden?: boolean, selected?: boolean}[];
    sx ?: SxProps,
}

const ButtonCollection = (
    {
        name,
        onChange,
        arrayName,
        iteration,
        options,
        sx,
    }: Props) => {
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const formContext = useFormContext();

    const defaultSelectedOption = useMemo(() =>
        options.filter((o) => o.selected)
    , [options]);

    useEffect(() => {
        const setDefaultValue = async () => {
            if (defaultSelectedOption.length) {
                formContext.setValue(fieldName, defaultSelectedOption[0].value);
            }
        }

        setDefaultValue().catch(console.error);
    }, [defaultSelectedOption]);

    return <Box sx={sx}>
        {options.map(option => {
            const selected = option.selected || formContext.getValues(fieldName) === option.value;

            return !option.hidden && <Button
                key={`toggle-option-${option.value}`}
                value={option.value}
                disabled={option.disabled}
                onClick={async (event) => {
                    formContext.setValue(fieldName, option.value);
                    onChange && onChange(event)
                }}
                sx={{
                    borderRadius: '8px',
                    mr: 2,
                    mb: 1,
                    border: '1px solid #ddd',
                    p: '18px',
                    backgroundColor: selected ? '#631d79' : '#f8f8f8',
                    color: selected ? '#fff' : '#000',
                    fontWeight: selected ? 800 : 400,
                    '&:hover': {
                        backgroundColor: selected ? '#631d79' : '#f8f8f8',
                    }
                }}
            >
                {selected && <TripOriginIcon fontSize="small" sx={{mr: 1, color: '#fff'}}/>}
                {!selected && <RadioButtonUncheckedIcon fontSize="small" sx={{mr: 1}}/>}
                {option.label}
            </Button>
        })}
        {formContext.formState.isSubmitted && !formContext.formState.isValid && <Box
            sx={{
                color: 'red',
                ml: 2,
                mt: 1,
            }}
        >
            {Object.keys(formContext.formState.errors).length > 0 && (
                <List>
                    {Object.keys(formContext.formState.errors).map((fieldName) => (
                        <ListItem key={fieldName}>
                            {formContext.formState.errors[fieldName]?.message?.toString()}
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>}
    </Box>;
}

export default ButtonCollection;
