import {useCallback} from 'react';
import {User} from "@/components/Providers/JWTProvider.tsx";

const useStripeCompleteSetupIntent = (): (
    user: User,
    setupId: string,
    paymentMethod: string,
    cartId: string | null,
    courseSectionId: string | null,
    payNowAmount: number,
) => Promise<Response> => {
    return useCallback(async (
        user: User,
        setupId: string,
        paymentMethod: string,
        cartId: string | null,
        courseSectionId: string | null,
        payNowAmount: number,
    ): Promise<Response> => {
        const url = new URL('/v1/payment/complete-setup-intent', import.meta.env.VITE_APP_API_ENDPOINT);

        return await user.apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                setupId: setupId,
                paymentMethod: paymentMethod,
                cartId: cartId,
                paymentIntentId: null,
                courseSectionId: courseSectionId,
                payNowAmount: payNowAmount,
                paymentType: 'stripe',
            }),
        });
    }, []);
};

export default useStripeCompleteSetupIntent;
