import {Button, Col, Row} from "react-bootstrap";
import ModalBase from "./ModalBase";

type Props = {
    show: boolean;
    handleClose: () => void;
};

const RegistrationCancelled = ({
    show,
    handleClose,
}: Props) => {
    return (
        <ModalBase
            show={show}
            title=""
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            <Row className="chris-form-bg mx-0 py-3 px-2">
                <Col xs={12}>Your registration payment window has expired and your registration has been cancelled.</Col>
            </Row>
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handleClose();
                        }}
                    >Close</Button>
                </Col>
            </Row>
        </ModalBase>);
}

export default RegistrationCancelled;
