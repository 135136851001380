import {ReactNode, useEffect, useState} from "react";
import NavHeader from "../components/Navigation/NavHeader";
import {Col, Container, Row} from "react-bootstrap";

const OK = 1;
const MISSING = 2;
const FAILED_FETCH = -1;
const BAD_RESULT = -2

const states = [OK, FAILED_FETCH, BAD_RESULT, MISSING]

type Status ={
    state: typeof states[number];
    start?: string;
    startDate?: Date;
    end?: string;
    endDate?: Date;
};


function isStatus(value: unknown): value is Status {
    if (typeof value !== 'object' || value === null || !('state' in value)) {
        return false;
    }

    const stateType = typeof value.state;
    return (stateType === 'number' && states.includes(value.state as typeof states[number]));
}

const refreshStatus = async () : Promise<Status> => {
    try {
        const result = await fetch('/status.json');

        if (!result.ok) {
            return {state: FAILED_FETCH};
        }

        const contentType = result.headers.get('content-type');
        if (contentType === 'text/html') {
            return {state: MISSING};
        }

        const status = await result.json() as unknown;

        if (!isStatus(status)) {
            return {state: BAD_RESULT}
        }
        return status;
    } catch (e) {
        return {state: FAILED_FETCH};
    }
}

const Layout = ({children} : {children : ReactNode}) => {
    return <>
        <NavHeader showNav={false} />
        <Container>
            <Row className="d-flex flex-row justify-content-center">
                <Col sm={12} className="bg-white p-3 mx-0 my-5 rounded-sm">
                    {children}
                </Col>
            </Row>
        </Container>
    </>
}

export const ScheduledMaintenance = ({children} : {children : ReactNode}) => {
    const [status, setStatus] = useState<Status|null>(null);
    const [isMaintenance, setIsMaintenance] = useState<boolean>(false);

    useEffect(() => {
        if (status?.end && status.start) {
            if (!status.endDate || !status.startDate) {
                setIsMaintenance(false);
                return setStatus({
                    ...status,
                    startDate: new Date(status.start),
                    endDate: new Date(status.end),
                })
            }

            const now = new Date();
            setIsMaintenance(now >= status.startDate && now <= status.endDate);
            //every 10 seconds check if we are between the dates
            const interval = setInterval(() => {
                if (!status?.startDate || !status?.endDate) {
                    return setIsMaintenance(false);
                }

                const now = new Date();
                setIsMaintenance(now >= status.startDate && now <= status.endDate);
            }, 10_000)//
            return () => {
                clearInterval(interval);
            }
        }
    }, [status])

    useEffect(() => {
        refreshStatus()
            .then((status) => setStatus(status))
            .catch(() => setStatus({state: FAILED_FETCH}));

        //every 5 minutes fetch the maintenance json
        const interval = setInterval(async () => {
            const status = await refreshStatus()
            setStatus(status);
        }, 300_000)//5 minutes

        return () => {
            clearInterval(interval);
        }
    }, []);

    if (isMaintenance) {
        return <Layout>The site is currently offline for maintenance and is scheduled to be back online at {status?.endDate?.toLocaleString()}.</Layout>;
    }

    return <>{children}</>;
}
