import NavButton from "@/components/Navigation/NavButton.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useUser} from "@/components/Providers/JWTProvider.tsx";

const CartNavButton = () => {
    const {isEmployee} = useUser()
    const {cart} = useCartContext();

    return (<>
            <NavButton
                text='Cart'
                link={isEmployee ? '/instructor/cart' : '/cart'}
                icon={<ShoppingCartIcon/>}
                badges={cart?.cartItems.length ?? 0}
            />
        </>
    );
};

export default CartNavButton;
