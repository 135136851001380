import {Dispatch, SetStateAction} from 'react';
import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable,} from 'material-react-table';
import {FeeRequest} from "@/types/FeeRequest.ts";
import {Box, IconButton, Tooltip} from "@mui/material";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import {DateTimeFormatter} from "@js-joda/core";
import { ContentCopy } from '@mui/icons-material';

type Props = {
    feeRequests: FeeRequest[];
    setShowForm: Dispatch<SetStateAction<boolean>>;
    setSelectedFeeRequest: Dispatch<SetStateAction<FeeRequest | null>>;
    setShowStatusHistoryModal: Dispatch<SetStateAction<boolean>>;
    setFeeRequestDefaults: (request: FeeRequest) => void;
};

const columns : MRT_ColumnDef<FeeRequest>[] = [
    {
        accessorKey: 'feeName',
        header: 'Name',
    },
    {
        accessorKey: 'school.shortName',
        header: 'School',
    },
    {
        accessorKey: 'schoolYear.year',
        header: 'Year',
    },
    {
        accessorKey: 'feeType',
        header: 'Type',
    },
    {
        accessorFn: (originalRow) => originalRow.createdAt.format(DateTimeFormatter.ofPattern('M/d/yyyy')),
        accessorKey: 'createdAt',
        header: 'Submission Date',
    },
    {
        accessorKey: 'requestStatus',
        header: 'Status',
    },
];

const FeeProposals = ({feeRequests, setShowForm, setSelectedFeeRequest, setShowStatusHistoryModal, setFeeRequestDefaults} : Props) => {
    const table = useMaterialReactTable<FeeRequest>({
        columns: columns,
        data: feeRequests,
        enableRowActions: true,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'Actions',
            }
        },
        renderRowActions: (row) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <Tooltip title='Duplicate Request'><IconButton
                    color="primary"
                    onClick={() => {
                        setFeeRequestDefaults(row.row.original);
                    }}
                >
                    <ContentCopy/>
                </IconButton></Tooltip>
                <Tooltip title='Status History'><IconButton
                    color="primary"
                    onClick={() => {
                        setSelectedFeeRequest(row.row.original);
                        setShowStatusHistoryModal(true);
                    }}
                >
                    <StickyNote2Icon />
                </IconButton></Tooltip>
                {row.row.original.requestStatus === 'Denied' && <Tooltip title='Resubmit'><IconButton
                    color="primary"
                    onClick={() => {
                        setShowForm(true);
                        setSelectedFeeRequest(row.row.original);
                    }}
                >
                    <SyncProblemIcon />
                </IconButton></Tooltip>}
            </Box>
        ),
    });

    return <>
        <MaterialReactTable table={table}/>
    </>;
};

export default FeeProposals;
