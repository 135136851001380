import {useState} from 'react';
import ReactMarkdown from 'react-markdown';
import {useNotice} from "../Providers/NoticeProvider";
import PrimaryButton from "@/components/PrimaryButton.tsx";
import {Login} from "@mui/icons-material";
import {Button} from "@mui/material";
import Grid from "@mui/material/Grid2"
import {useRouter} from "@tanstack/react-router";
import {z} from "zod";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfTextField} from "mui-rhf-integration";

type Props = {
    handleSignInSubmit: (values : InstructorSignInFormType) => void;
};

const schema = z.object({
    username: z.string(),
    password: z.string()
});

export type InstructorSignInFormType = z.infer<typeof schema>;

const InstructorSignInForm = ({
    handleSignInSubmit
}: Props) => {
    const [loading, setLoading] = useState(false);
    const {history} = useRouter();
    const [notice] = useNotice();

    const initialValues = {
        username: '',
        password: ''
    };

    const form = useForm<InstructorSignInFormType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema),
    });

    return <>
        <FormProvider {...form}>
            <form className="form-bg pt-2" onSubmit={
                form.handleSubmit(async (values : InstructorSignInFormType) => {
                    setLoading(true);
                    handleSignInSubmit(values);
                    setLoading(false);
                })}>
                <Grid container spacing={2} sx={{px: 2}}>
                    <Grid size={{xs: 12}}>
                        <ReactMarkdown className="markdown">{notice.instructorLogInMessage}</ReactMarkdown>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <RhfTextField
                            control={form.control}
                            name={'username'}
                            label={'Username:'}
                            sx={{
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <RhfTextField
                            control={form.control}
                            type={'password'}
                            name={'password'}
                            label={'Password:'}
                            sx={{
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{mx: 1}}>
                    <Grid size={{xs: 12}}>
                        <hr />
                    </Grid>
                </Grid>
                <Grid container sx={{p: 2}}>
                    <Grid size={{xs: 12}}>
                        <PrimaryButton
                            type="submit"
                            sx={{width: '100%'}}
                            disabled={loading}
                        >
                            <Login sx={{mr: 1}}/> Login
                        </PrimaryButton>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Button variant="outlined" fullWidth onClick={() => {
                            history.back();
                        }} sx={{ mt: 2 }}>
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    </>
};

export default InstructorSignInForm;
