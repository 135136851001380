import * as React from 'react';
import {Box, Tab, Tabs} from '@mui/material';
import {AlertCircle} from "lucide-react";

type Props = {
    feeToggleValue: number;
    setFeeToggleValue: (feeToggleValue: number) => void;
    hasPartialFees: boolean;
};

const FeeToggle = ({
    feeToggleValue, setFeeToggleValue, hasPartialFees
} : Props) : React.ReactElement => {
    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setFeeToggleValue(newValue);
    };

    return (
        <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 1}}>
            <Tabs
                value={feeToggleValue}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
            >
                <Tab label="Assessed Fees"/>
                <Tab label="Optional Fees"/>
                <Tab
                    wrapped={false}
                    label={<>Previous Purchases</>}
                    icon={hasPartialFees ? <AlertCircle/> : undefined}
                    iconPosition="end"
                />
            </Tabs>
        </Box>
    );
}

export default FeeToggle;
