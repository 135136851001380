import {useState} from 'react';
import {guardianValidator, isState, phoneValidation, postalCodeUS, zodDateRegex,} from "@/Helpers/validationHelpers.ts";
import {apiEndpoint} from "../Providers/JWTProvider";
import {z} from "zod";
import {useNavigate} from "@tanstack/react-router";
import Grid from "@mui/material/Grid2";
import {Alert, Button} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import CommunityMemberForm from "@/components/Forms/CommunityMemberForm.tsx";

const schema = z.object({
    nameFirst: z.string().min(1, 'First Name is required'),
    nameLast: z.string().min(1, 'Last Name is required'),
    dob: zodDateRegex,
    grade: z.string().min(1, 'Please select a Grade'),
    gender: z.string().min(1, 'Please select a Gender'),
    address: z.string().nullable(),
    city: z.string().nullable(),
    state: isState.nullable(),
    zip: postalCodeUS.nullable(),
    homePhone: phoneValidation.transform(value => value !== '' ? value : 'null').nullable(),
    email: z.string().email().transform(value => value === null ? '' : value),
    printedSignature: z.string(),
    digitalSignature: z.string(),
    guardians: guardianValidator
})

export type RegisterCommunityMemberFormSchemaType = z.infer<typeof schema>;

const RegisterCommunityMemberForm = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
    const [communityMemberAlreadyExists, setCommunityMemberAlreadyExists] = useState(false);

    const initialValues = {
        nameFirst: '',
        nameLast: '',
        dob: '',
        grade: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        homePhone: '',
        gender: '',
        email: '',
        printedSignature: '',
        digitalSignature: '',
        guardians: [
            {
                nameFirst: '',
                nameLast: '',
                relationship: '',
                relationshipOther: '',
                phone1: '',
                phone1Type: '',
                phone2: '',
                phone2Type: '',
                email: '',
            },
            {
                nameFirst: '',
                nameLast: '',
                relationship: '',
                relationshipOther: '',
                phone1: '',
                phone1Type: '',
                phone2: '',
                phone2Type: '',
                email: '',
            },
        ],
    };

    const form = useForm<RegisterCommunityMemberFormSchemaType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema),
    });

    const handleCreateRegistration = async (values: RegisterCommunityMemberFormSchemaType) => {
        const url = new URL(`/v1/community/register-member`, apiEndpoint);
        let init: RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'PUT'};
        init.body = JSON.stringify(values);
        const response = await fetch(url.toString(), init);

        if (response.status === 409) {
            setCommunityMemberAlreadyExists(true);
            setLoading(false);
            return;
        }

        if (response.status !== 200) {
            return null;
        }

        setLoading(false);
        setShowConfirmationMessage(true);
    };

    if (showConfirmationMessage) {
        return <Grid container>
            <Grid size={{xs: 12}}>
                <h2 className='text-primary'>Success</h2>
                <p>Your registration has been received. Please check your the entered email address for your student id.</p>
                <p>
                    <Button
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center"
                        style={{backgroundColor: '#631d79', color: 'white'}}
                        variant="outlined"
                        onClick={async () => {
                            await navigate({to: `/`});
                        }}
                    >
                        Return to Login
                    </Button>
                </p>
            </Grid>
        </Grid>;
    }

    if (communityMemberAlreadyExists) {
        return <Grid container className="mx-0">
            <Grid size={{xs: 12}}>
                <Alert severity="error">
                    A community member account already exists. Please return to the login screen and enter your
                    community member ID with your date of birth to login,
                    or click on ‘Forgot my community member ID’ to recover your community member ID.
                </Alert>
                <Button
                    type="button"
                    className="d-flex pt-2 flex-row align-content-center"
                    style={{backgroundColor: '#631d79', color: 'white'}}
                    variant="outlined"
                    onClick={async () => {
                        await navigate({to: `/`});
                    }}
                >
                    Return to Login
                </Button>
            </Grid>
        </Grid>;
    }

    return <FormProvider {...form}>
        <CommunityMemberForm
            submitHandlerFunction={handleCreateRegistration}
            communityMemberAlreadyExists={communityMemberAlreadyExists}
            loading={loading}
            requireSignature={true}
        />
    </FormProvider>
};

export default RegisterCommunityMemberForm;
