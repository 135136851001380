import {useFormContext} from "react-hook-form";
import {RhfTextField} from "mui-rhf-integration";
import type {InputBaseComponentProps} from '@mui/material';
import type {ReactNode} from 'react';
import {forwardRef} from 'react';
import PhoneInput from 'react-phone-number-input/input';

const PhoneNumberInput = forwardRef<HTMLInputElement, InputBaseComponentProps>((props, ref) : ReactNode => {
    const {onChange, name, ...rest} = props;
    const {setValue} = useFormContext();

    if (typeof name !== 'string') {
        throw new Error('"name" property must be defined');
    }

    return (
        <PhoneInput
            {...rest}
            name={name}
            onChange={value => {
                setValue(name, value);
            }}
            ref={ref}
            country="US"
            international={false}
        />
    );
});
PhoneNumberInput.displayName = 'PhoneInput';

type Props = {
    label: string;
    name: string;
};

const PhoneNumberField = ({
    label,
    name,
}: Props) => {
    const formContext = useFormContext();

    return (
        <RhfTextField
            name={name}
            label={label}
            control={formContext.control}
            InputProps={{inputComponent: PhoneNumberInput}}
            fullWidth
            />
    );
};

export default PhoneNumberField;
