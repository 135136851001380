import React, {ReactNode, useCallback, useContext, useEffect} from 'react';
import {apiEndpoint} from "./JWTProvider";

export type HomeMessageNotice = {
    message : string;
    show : boolean;
};

export type NoticeState = {
    loading : boolean;
    error : boolean;
    homeMessage : HomeMessageNotice;
    instructions : string;
    logInMessage : string;
    guestLogInMessage : string;
    gradeLabel : string;
    gradeDescription : string;
    registrationPaymentConfirmed : string;
    waitlistPaymentConfirmed : string;
    cancelPaymentRegistration : string;
    programCancellationMessage : string;
    instructorInstructions: string;
    instructorLogInMessage: string;
    cartCountdownTimer: number;
    partiallyCompletedPaymentMessage: string;
}

type Props = {
    children ?: ReactNode;
};

export const NoticeProviderContext = React.createContext<
    [NoticeState, (() => Promise<void>) | null]
>(
    [{
        loading: false,
        error: false,
        homeMessage : {message : '', show : false},
        instructions : '',
        logInMessage : '',
        guestLogInMessage : '',
        gradeLabel : '',
        gradeDescription : '',
        registrationPaymentConfirmed : '',
        waitlistPaymentConfirmed : '',
        cancelPaymentRegistration : '',
        programCancellationMessage : '',
        instructorLogInMessage : '',
        instructorInstructions : '',
        cartCountdownTimer : 0,
        partiallyCompletedPaymentMessage : '',
    }, null]
);

const noticeReducer = (state : NoticeState, action : any) => {
    switch (action.type) {
        case 'LOADING': {
            return {...state, loading : true, error : false}
        }
        case 'SET_NOTICES': {
            return {
                ...state,
                loading : true,
                error : false,
                homeMessage: action.homeMessage,
                instructions: action.instructions,
                logInMessage: action.logInMessage,
                guestLogInMessage: action.guestLogInMessage,
                gradeLabel: action.gradeLabel,
                gradeDescription: action.gradeDescription,
                registrationPaymentConfirmed: action.registrationPaymentConfirmed,
                waitlistPaymentConfirmed: action.waitlistPaymentConfirmed,
                cancelPaymentRegistration: action.cancelPaymentRegistration,
                programCancellationMessage: action.programCancellationMessage,
                instructorInstructions: action.instructorInstructions,
                instructorLogInMessage: action.instructorLogInMessage,
                cartCountdownTimer: action.cartCountdownTimer,
                partiallyCompletedPaymentMessage: action.partiallyCompletedPaymentMessage,
            }
        }
        case 'LOADED': {
            return {...state, loading : false, error : false}
        }
        case 'CLOSE_SYSTEM_NOTICE': {
            return {...state, loading : false, error : false, homeMessage: {...state.homeMessage, show: false}}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

const NoticeProvider : React.FC<Props> = ({children} : Props) => {
    const [noticeState, setNoticeState] = React.useReducer(noticeReducer, {
        loading: false,
        error: false,
        homeMessage : {message : '', show : false},
        instructions : '',
        logInMessage : '',
        guestLogInMessage : '',
        gradeLabel : '',
        gradeDescription : '',
        registrationPaymentConfirmed : '',
        waitlistPaymentConfirmed : '',
        cancelPaymentRegistration : '',
        programCancellationMessage : '',
        instructorLogInMessage : '',
        instructorInstructions : '',
        cartCountdownTimer : 0,
        partiallyCompletedPaymentMessage : '',
    });

    const closeSystemNotice = useCallback(async () => {
        setNoticeState({
            type: 'CLOSE_SYSTEM_NOTICE',
        });
    }, [setNoticeState]);

    const loadNotices = useCallback(async () => {
        setNoticeState({type: 'LOADING'});
        const url = new URL(`/v1/settings`, apiEndpoint);
        const response = await fetch(url.toString());
        const data = await response.json();

        setNoticeState({
            type: 'SET_NOTICES',
            homeMessage: {
                message: data.homeMessage,
                show : data.homeMessage !== ''
            },
            instructions: data.instructions,
            logInMessage: data.logInMessage,
            gradeLabel: data.gradeLabel,
            gradeDescription: data.gradeDescription,
            guestLogInMessage: data.guestLogInMessage,
            registrationPaymentConfirmed: data.registrationPaymentConfirmed,
            waitlistPaymentConfirmed: data.waitlistPaymentConfirmed,
            cancelPaymentRegistration: data.cancelPaymentRegistration,
            programCancellationMessage: data.programCancellationMessage,
            instructorLogInMessage: data.instructorLogInMessage,
            instructorInstructions: data.instructorInstructions,
            cartCountdownTimer: data.cartCountdownTimer,
            partiallyCompletedPaymentMessage: data.partiallyCompletedPaymentMessage,
        });
        setNoticeState({type: 'LOADED'});
    }, [setNoticeState]);

    useEffect( () => {
        void loadNotices();
    }, [loadNotices]);

    return (
        <NoticeProviderContext.Provider value={[noticeState, closeSystemNotice]}>
            {children}
        </NoticeProviderContext.Provider>
    );
};

export const useNotice = () : [NoticeState, (() => Promise<void>) | null] => {
    return useContext(NoticeProviderContext);
};

export default NoticeProvider;

