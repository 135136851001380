import {useContext, useMemo, useState} from 'react';
import {
    InstructorProgramCourseSelection,
    InstructorProgramRegistration,
    InstructorProgramsProviderContext
} from "../../components/Providers/InstructorProgramsProvider";
import {Col, Container, Dropdown, DropdownButton, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InstructorHeader from "../../components/Navigation/InstructorHeader";
import moment from "moment";
import CsvDownloader from "react-csv-downloader";
import {faFileExcel, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {apiEndpoint, jwtInstructorContext} from "../../components/Providers/JWTProvider";
import Spinner from "react-bootstrap/Spinner";
import ActivityRegistrationTable from "@/components/Program/ActivityRegistrationTable.tsx";
import {useNavigate, useParams} from "@tanstack/react-router";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

export type FilterByActiveOrCanceled = 'active' | 'canceled';

type Props = {};

type Roster = {
    courseName : string;
    courseNumber : string;
    sectionNumber : number;
    studentOrCommunityMemberId: string;
    nameFirst : string;
    nameLast : string;
    gender : string;
    gradeLevel : string;
    emergencyContact : string;
    contactRelationship : string;
    emergencyContactPhone : string;
    emergencyContactEmail : string;
    emergencyRelationship : string;
    emergencyContactName : string;
    canceled : boolean;
    registrationDate : string;
    courseQuestions : string;
    courseAnswers : string;
    amountDue : number;
};

const rosterExportColumns = [
    {
        id: "courseName",
        displayName: "Course Name",
    },
    {
        id: "courseNumber",
        displayName: "Course Number",
    },
    {
        id: "sectionNumber",
        displayName: "Section Number",
    },
    {
        id: "studentOrCommunityMemberId",
        displayName: "Student / Community ID",
    },
    {
        id: "nameFirst",
        displayName: "First Name",
    },
    {
        id: "nameLast",
        displayName: "Last Name",
    },
    {
        id: "gender",
        displayName: "Gender",
    },
    {
        id: "gradeLevel",
        displayName: "Grade Level",
    },
    {
        id: "emergencyContactName",
        displayName: "Emergency Contact Name",
    },
    {
        id: "emergencyRelationship",
        displayName: "Emergency Contact Relationship",
    },
    {
        id: "emergencyContactPhone",
        displayName: "Emergency Contact Phone",
    },
    {
        id: "emergencyContactEmail",
        displayName: "Custodian Email",
    },
    {
        id: 'courseQuestions',
        displayName: 'Course Questions'
    },
    {
        id: 'courseAnswers',
        displayName: 'Course Answers'
    }
];


const unpaidExportColumns = [
    {
        id: "courseName",
        displayName: "Course Name",
    },
    {
        id: "courseNumber",
        displayName: "Course Number",
    },
    {
        id: "sectionNumber",
        displayName: "Section Number",
    },
    {
        id: "studentOrCommunityMemberId",
        displayName: "Student / Community ID",
    },
    {
        id: "nameFirst",
        displayName: "First Name",
    },
    {
        id: "nameLast",
        displayName: "Last Name",
    },
    {
        id: "gender",
        displayName: "Gender",
    },
    {
        id: "gradeLevel",
        displayName: "Grade Level",
    },
    {
        id: "emergencyContactName",
        displayName: "Emergency Contact Name",
    },
    {
        id: "emergencyRelationship",
        displayName: "Emergency Contact Relationship",
    },
    {
        id: "emergencyContactPhone",
        displayName: "Emergency Contact Phone",
    },
    {
        id: "emergencyContactEmail",
        displayName: "Custodian Email",
    },
    {
        id: 'amountDue',
        displayName: 'Balance Due'
    }
];

const getFullNamesForWeekdays = (abbreviatedWeekDays : string | null) : string => {
    if (!abbreviatedWeekDays) {
        return '';
    }
    const listOfWeekDays = abbreviatedWeekDays.split('\r');

    return listOfWeekDays.map((singeLetterWeekday) => {
        switch (singeLetterWeekday) {
            case 'M':
                return 'Monday';
            case 'T':
                return 'Tuesday';
            case 'W':
                return 'Wednesday';
            case 'Th':
                return 'Thursday';
            case 'F':
                return 'Friday';
            case 'S':
                return 'Saturday';
            default:
               return 'Sunday';
        }
    }).join(', ');
};

const courseSectionToRoster = (
    registration : InstructorProgramRegistration,
    courseSection : InstructorProgramCourseSelection
) : Roster | null => {
    const courseQuestions = registration.courseAnswer
        .map((ca) => ca.cachedQuestion)
        .join('\n\r');
    const courseAnswers = registration.courseAnswer
        .map((ca) => ca.answer)
        .join('\n\r');
    if (registration.student) {
        const student = registration.student;
        const contactEmail : string[] = [];
        if (student.custodianEmail1) {
            contactEmail.push(student.custodianEmail1);
        }
        if (student.custodianEmail2) {
            contactEmail.push(student.custodianEmail2);
        }
        if (student.custodianEmail3) {
            contactEmail.push(student.custodianEmail3);
        }
        if (student.custodianEmail4) {
            contactEmail.push(student.custodianEmail4);
        }

        return {
            courseName: courseSection.course.courseName,
            courseNumber: courseSection.course.courseNumber,
            sectionNumber: courseSection.sectionNumber,
            studentOrCommunityMemberId: registration.student.id.toString(),
            nameFirst: student.nameFirst,
            nameLast: student.nameLast,
            gender: student.gender,
            gradeLevel: student.grade.toString(),
            canceled: registration.status !== null,
            emergencyContactName: student.emergencyName1,
            emergencyContactPhone: student.emergencyPhone1,
            emergencyRelationship: student.emergencyRelationship1 ?? '',
            emergencyContactEmail: contactEmail.join(','),
            registrationDate : moment(registration.createdAt).format('MM/DD/YYYY h:mm a'),
            courseQuestions,
            courseAnswers,
            amountDue: registration.amountDue,
        } as Roster
    }
    if (registration.communityMember) {
        const contactEmail = registration.communityMember.guardians
            .map((g) => g.email)
            .join(',');
        const firstGuardian = registration.communityMember.guardians[0];
        return {
            courseName: courseSection.course.courseName,
            courseNumber: courseSection.course.courseNumber,
            sectionNumber: courseSection.sectionNumber,
            studentOrCommunityMemberId: registration.communityMember.id.toString(),
            nameFirst: registration.communityMember.nameFirst,
            nameLast: registration.communityMember.nameLast,
            gender: registration.communityMember.gender,
            gradeLevel: registration.communityMember.grade ?? '',
            canceled: registration.status !== null,
            emergencyContactName: `${firstGuardian?.nameFirst} ${firstGuardian?.nameLast}`,
            emergencyContactPhone: firstGuardian?.phone1,
            emergencyRelationship: firstGuardian?.relationship,
            emergencyContactEmail: contactEmail,
            registrationDate : moment(registration.createdAt).format('MM/DD/YYYY h:mm a'),
            courseQuestions,
            courseAnswers,
            amountDue: registration.amountDue,
        } as Roster
    }
    return null;
}

const ActivityDetailView = ({} : Props) => {
    const navigate = useNavigate();
    const {user} = useContext(jwtInstructorContext);
    const [showActiveOrCanceled, setShowActiveOrCanceled] = useState<FilterByActiveOrCanceled>('active');
    const [message, setMessage] = useState<string|null>(null);
    const [downloadingPdf, setDownloadingPdf] = useState<boolean>(false);

    const instructorContext = useContext(InstructorProgramsProviderContext);
    const {programId, courseId} = useParams({strict: false});

    const selectedProgram = instructorContext.programs.find(program => program.id === programId);
    const courseSection = selectedProgram?.courseSelections?.find(courseSection => courseSection.id === courseId);

    const fullRoster : (Roster | null)[] = useMemo(() => {
        if (!courseSection) {
            return [];
        }
        return courseSection.registrations
            .map((r) => courseSectionToRoster(r, courseSection))
            .filter(s => s !== null);
    }, [courseSection]);

    if (!selectedProgram || !courseSection || !user) {
        return <>Error: no programs/section defined</>
    }

    const filename = `${courseSection.course.courseName} - ${selectedProgram?.courseSelections[0].instructorName}`;
    const fullNamesForDaysOfWeek = getFullNamesForWeekdays(courseSection.meetingDaysOfWeek);

    const downloadReport = async (type: string) => {
        try {
            setDownloadingPdf(true);
            const download = await user.apiFetch(
                new URL('/v1/instructor/pdfReport', apiEndpoint).toString(),
                {
                method: 'POST',
                body: JSON.stringify({
                    rosterType: type,
                    sectionIdList: [courseId],
                }),
            });

            if (!download.ok || download.status > 299) {
                const responseJson = await download.json();
                setMessage(responseJson.hint ?? 'There was a error downloading the report.');
                setDownloadingPdf(false);
                return;
            }

            const blob = await download.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `${filename} (${type}).pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
        } catch (e) {
            setMessage('There was a error downloading the report.');
        }

        setDownloadingPdf(false);
    }

    return (
        <>
            <InstructorHeader
                showNav={true}
            />
            <Container className="bg-white mt-4 p-4">
                <Row>
                    <Col className="col-12">
                    <a href='#' onClick={() => {
                        navigate({to: `/instructor/program/${programId}`
                    });
                    }}>Back to Activities</a>
                    </Col>
                </Row>
                <hr className='py-2'/>
                <Row>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Program</h3>
                        {selectedProgram.programName}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Instructor</h3>
                        {courseSection.instructorName}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Restricted to School</h3>
                        {courseSection.restrictToSchool ? 'Yes' : 'No'}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>School</h3>
                        {courseSection.school?.fullName ?? ''}
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Activity</h3>
                        {courseSection.course?.courseName ?? ''}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Section</h3>
                        No. {courseSection.sectionNumber}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Capacity</h3>
                        {courseSection.capacity}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Credit Hours</h3>
                        {courseSection.creditHours}
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Delivery Method</h3>
                        {courseSection.deliveryMethod}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Meeting Days</h3>
                        {fullNamesForDaysOfWeek}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Room #</h3>
                        {courseSection.roomNumber}
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Start and End</h3>
                        {
                            moment(courseSection.meetingDateStart).format('l')
                            + ' - '
                            + moment(courseSection.meetingDateEnd).format('l')
                        }
                    </Col>
                    <Col className="col-lg-3 col-md-4 col-12">
                        <h3 className='instructorHeaders'>Class Time</h3>
                        {
                            moment(courseSection.meetingTimeStart, 'hh:mm:ss').format('LT')
                            + ' - '
                            + moment(courseSection.meetingTimeEnd, 'hh:mm:ss').format('LT')
                        }
                    </Col>
                    <Col>
                        <h3 className='instructorHeaders'>Allow Community Members</h3>
                        {courseSection.allowCommunity ? 'Yes' : 'No'}
                    </Col>
                </Row>
                <hr className='py-2'/>
                <Row>
                    <Col className='col-lg-4 col-12'>
                        Registrations
                        <Form.Check
                            type={'radio'}
                            name={'activeOrCanceled'}
                            label={'Active'}
                            onChange={() => {
                                setShowActiveOrCanceled('active');
                            }}
                            inline
                            className='ml-2'
                            checked={showActiveOrCanceled === 'active'}
                        />
                        <Form.Check
                            type={'radio'}
                            name={'activeOrCanceled'}
                            label={'Canceled'}
                            onChange={() => {
                                setShowActiveOrCanceled('canceled');
                            }}
                            inline
                            checked={showActiveOrCanceled === 'canceled'}
                        />
                    </Col>
                    <Col className='col-12 col-md-8 d-flex justify-content-md-end mb-2'>
                        <DropdownButton
                            id="dropdown-basic-button"
                            title={downloadingPdf ? <Spinner animation="border" size="sm" /> : 'Download'}
                            variant='outline-primary'
                            className='mobile-full-width'
                        >
                            <CsvDownloader
                                filename={`${filename} (Active)`}
                                extension=".csv"
                                columns={rosterExportColumns}
                                separator=","
                                wrapColumnChar='"'
                                // @ts-ignore
                                datas={fullRoster.filter((s) =>s?.canceled === false)}
                            >
                                <Dropdown.Item className='mr-lg-2'>
                                    Download Active Roster
                                    <FontAwesomeIcon icon={faFileExcel} className="ml-2"/>
                                </Dropdown.Item>
                            </CsvDownloader>
                            <Dropdown.Item
                                onClick={() => {
                                    downloadReport('registration')
                                }}
                            >
                                Download Active Roster
                                <FontAwesomeIcon icon={faFilePdf} className="ml-2"/>
                            </Dropdown.Item>
                            <CsvDownloader
                                filename={`${filename} (Canceled)`}
                                extension=".csv"
                                columns={rosterExportColumns}
                                separator=","
                                wrapColumnChar='"'
                                // @ts-ignore
                                datas={fullRoster.filter((s) =>s?.canceled === true)}
                            >
                                <Dropdown.Item className='mr-lg-2'>
                                    Download Canceled Roster
                                    <FontAwesomeIcon icon={faFileExcel} className="ml-2"/>
                                </Dropdown.Item>
                            </CsvDownloader>
                            <CsvDownloader
                                filename={filename}
                                extension=".csv"
                                columns={unpaidExportColumns}
                                separator=","
                                wrapColumnChar='"'
                                // @ts-ignore
                                datas={fullRoster.filter((s) =>s?.amountDue > 0 && s?.canceled === false)}
                            >
                                <Dropdown.Item>
                                    Download Unpaid Registrations
                                    <FontAwesomeIcon icon={faFileExcel} className="ml-2"/>
                                </Dropdown.Item>
                            </CsvDownloader>
                            <Dropdown.Item
                                onClick={() => {
                                    downloadReport('unpaid')
                                }}
                            >
                                Download Unpaid Registrations
                                <FontAwesomeIcon icon={faFilePdf} className="ml-2"/>
                            </Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Row>
                <ActivityRegistrationTable
                    courseSection={courseSection}
                    showActiveOrCanceled={showActiveOrCanceled}
                />
            </Container>
            <GlenSnackBar
                onClose={() => setMessage(null)}
                show={message !== null}
                severity={'error'}
                message={message}/>
        </>
    );
};

export default ActivityDetailView;
