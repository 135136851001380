import {useEffect, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {FormControlLabel, FormGroup} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useFormContext} from "react-hook-form";

type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    className?: string;
    fieldLabelClass?: string;
};

const DigitalSignatureField = ({
    name,
    label,
    disabled,
    className,
    fieldLabelClass,
}: Props) => {
    const [signatureError, setSignatureError] = useState<boolean>(false);
    const sigRef = useRef<any>(null);
    const formContext = useFormContext();

    const onEndSigning = (): void => {
        if (sigRef.current !== undefined) {
            if (sigRef.current.isEmpty()) {
                setSignatureError(true);
                return;
            }

            setSignatureError(false);
            const signature = sigRef.current.toDataURL('image/png');
            formContext.setValue(name, signature);
            // setFieldModified();
        }
    };

    const clearSignature = (): void => {
        if (sigRef.current !== undefined) {
            sigRef.current.clear();
            formContext.setValue(name, '');
            // setFieldModified();
        }
    }

    useEffect(() => {
        if (sigRef.current !== undefined && sigRef.current.isEmpty() && formContext.getValues(name) !== undefined) {
            sigRef.current.fromDataURL(formContext.getValues(name))
            return;
        }
    }, [formContext.getValues(name)]);

    return (
        <FormGroup>
            <Grid container>
                {label && (
                    <FormControlLabel label={label} className={fieldLabelClass} control={
                        <input type="hidden"
                               name={name}
                               value={formContext.getValues(name) === null ? '' : formContext.getValues(name)}
                               // isInvalid={!!errorsName || !!errorNoticeOverride}
                               disabled={disabled}
                               className={className ? className : ''}
                        />
                    } />
                )}
            </Grid>
            <Grid container>
                <div>
                    <SignatureCanvas
                        penColor='black'
                        canvasProps={{width: '300px', height: 200, className: 'sigCanvas'}}
                        ref={sigRef}
                        onEnd={onEndSigning}
                    />
                </div>
            </Grid>
            <Grid container>
                {/*<Form.Control.Feedback type="invalid">*/}
                {/*    {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}*/}
                {/*</Form.Control.Feedback>*/}
                <div className="d-flex flex-row pl-4 pr-4 justify-content-beginning">
                    {signatureError && <p className="text-danger w-75">Add</p>}
                    <div className="text-primary cursor-pointer">
                        <p onClick={clearSignature}>Clear</p>
                    </div>
                </div>
            </Grid>
        </FormGroup>
    );
};

export default DigitalSignatureField;
