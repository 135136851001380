import {BaseSyntheticEvent} from "react";
import ModalBase from "./ModalBase";
import {ProgramCourse} from "../Providers/ProgramsProvider";
import {
    getInitialValuesFromCourseQuestions,
    getSchemaFromCourseQuestions
} from "@/components/Modal/FeeQuestionModal.tsx";
import {z} from "zod";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid2";
import {Button, MenuItem} from "@mui/material";
import {RhfTextField} from "mui-rhf-integration";

type Props = {
    show: boolean;
    handleClose: () => void;
    course: ProgramCourse;
    performActionBasedOnCourseStatus: (course: ProgramCourse) => Promise<void>;
};

const CourseQuestionModal = ({
    show,
    handleClose,
    course,
    performActionBasedOnCourseStatus
}: Props) => {

    const listOfCourseQuestions = course.courseQuestions;

    if (!listOfCourseQuestions) {
        return <>Error: This modal loaded but the course has no questions</>
    }

    const initialValues = getInitialValuesFromCourseQuestions(listOfCourseQuestions);
    const schema = z.object(getSchemaFromCourseQuestions(listOfCourseQuestions));
    type courseQuestionSchemaType = z.infer<typeof schema>;

    const form = useForm({
        defaultValues: initialValues,
        resolver: zodResolver(schema)
    });

    const onSubmit: SubmitHandler<courseQuestionSchemaType> = async (
        values: courseQuestionSchemaType,
        event?: BaseSyntheticEvent
    ) => {
        event?.stopPropagation();
        const arrayOfQuestionIDs = Object.keys(values);

        const listOfCourseQuestionsWithAnswers = listOfCourseQuestions.map((question, index) => {
            const key = arrayOfQuestionIDs[index] as keyof courseQuestionSchemaType;
            return {...question, userSubmittedAnswer: values[key]}
        });

        course = {
            ...course,
            courseQuestions: listOfCourseQuestionsWithAnswers,
        };

        await performActionBasedOnCourseStatus(course);
        handleClose();
    }

    return (
        <ModalBase
            show={show}
            title={"Before registering, please answer the following questions:"}
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(async(data, event) => {
                await onSubmit(data, event);
            })}>
                    {listOfCourseQuestions.map((question) => {
                        return (<Grid container key={`question-${question.id}`} className="chris-form-bg mx-0 py-3 px-2">
                            <Grid size={{xs: 6}}>
                                {question.question}
                                {question.responseType === 'Value List' &&
                                    <RhfTextField
                                        control={form.control}
                                        name={question.id as keyof courseQuestionSchemaType}
                                        select={true}
                                        label={'-- Select --'}
                                        fullWidth={true}
                                    >
                                        {question.answerList?.map((answer) => {
                                            return <MenuItem value={answer}>{answer}</MenuItem>
                                        })}
                                    </RhfTextField>}
                                {question.responseType === 'Free Form' &&
                                    <RhfTextField
                                        control={form.control}
                                        name={question.id as keyof courseQuestionSchemaType}
                                        sx={{mt: 2}}
                                    />}
                            </Grid>
                        </Grid>)
                    })}
                    <Grid size={{xs: 12}}><hr /></Grid>
                    <Grid container sx={{mt: 2}}>
                        <Grid size={{xs: 12}} sx={{
                            justifyContent: 'end',
                            flexDirection: 'row',
                            display: 'flex',
                        }}>
                            <Button
                                variant={"contained"}
                                type="submit"
                                sx={{mr: 2}}
                            >Continue to Registration</Button>
                            <Button
                                variant="outlined"
                                type="button"
                                sx={{mr: 2}}
                                onClick={async () => {
                                    handleClose();
                                }}
                            >Close</Button>
                        </Grid>
                    </Grid>
            </form>
            </FormProvider>
        </ModalBase>);
}

export default CourseQuestionModal;
