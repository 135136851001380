import {FormProvider, useForm} from "react-hook-form";
import {RhfTextField} from "mui-rhf-integration";
import {MenuItem} from "@mui/material";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect} from "react";

type Props = {
    availablePrograms : Array<string>;
    setActiveProgram : (activeProgram : string) => void;
    defaultProgram? : string;
    className? : string;
};

const schema = z.object({
    availablePrograms: z.string(),
});

type ProgramSelectFormSchemaType = z.infer<typeof schema>;
const ProgramSelectField = ({availablePrograms, setActiveProgram, className, defaultProgram} : Props) => {
    const form = useForm<ProgramSelectFormSchemaType>({
        defaultValues: {availablePrograms: defaultProgram},
        resolver: zodResolver(schema),
    });

    const selectedProgram = form.watch('availablePrograms');
    useEffect(() => {
        setActiveProgram(selectedProgram);
    }, [selectedProgram]);

    return (
        <FormProvider {...form}>
            <form style={{width: '50%'}}>
                <RhfTextField
                    control={form.control}
                    name={'availablePrograms'}
                    select={true}
                    className={`${className}`}
                    fullWidth={true}
                    slotProps={{select: {displayEmpty: true}}}
                >
                    <MenuItem value={""}>--Select--</MenuItem>
                    {availablePrograms && availablePrograms.map((option , index) => {
                        return <MenuItem
                            value={option}
                            key={index}
                        >{option}</MenuItem>;
                    })}
                </RhfTextField>
            </form>
        </FormProvider>
    );
};

export default ProgramSelectField;
