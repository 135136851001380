import type {CartItem, RawCartItem} from "@/types/CartItem.ts";
import {CartState} from "@/components/Providers/CartProvider.tsx";

export type Cart = {
    id: string;
    cartTimeout: null | string;
    cartItems: CartItem[];
};

export type RawCart = {
    id: string;
    cartTimeout: null | string;
    cartItems: RawCartItem[];
}

export const initialCartState : CartState = {
    loading: true,
    showAlert: false,
    message: '',
    alertVariant: 'success'
};
