import {AlertCircle, BookOpen, DollarSign, Lock} from 'lucide-react';
import * as React from 'react';
import {RefObject, useEffect, useMemo, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Fee} from "@/types/Fee.ts";
import ViewMoreModalLink from "@/components/Fees/ViewMoreModalLink.tsx";
import {apiEndpoint, useUser} from "@/components/Providers/JWTProvider.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {getFeeDisplayIconType} from "@/Helpers/getFeeDisplayIconType.ts";
import {buildFeePrice} from "@/Helpers/buildFeePrice.ts";
import {formatter} from "@/Helpers/formatter.ts";
import FeeQuestionModal from "@/components/Modal/FeeQuestionModal.tsx";
import type {AssessedFee} from "@/types/AssessedFee.ts";
import BigNumber from "bignumber.js";

type Props = {
    fee: Fee;
    alternateBackground?: boolean;
    assessedFee?: AssessedFee[];
    cartRef?: RefObject<HTMLSpanElement | null>
};

const OptionalFeeListItem = ({
    fee, alternateBackground, assessedFee, cartRef
} : Props) : React.ReactElement => {
    const [quantity, setQuantity] = useState<number>(1);
    const {user} = useUser()
    const {cart, refreshCart} = useCartContext();
    const {refreshFees, purchaseHistory} = useFeeProvider();
    const [showCourseQuestionModal, setShowCourseQuestionModal] = useState<boolean>(false);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const isInCart = cart?.cartItems
        .some(item => item.itemType === 'fee' && item.feeId === fee.id) ?? false;

    const displayIcon = getFeeDisplayIconType(isInCart, fee, user, purchaseHistory, assessedFee);

    const currentPrice = useMemo(() => {
        return buildFeePrice(fee, user, purchaseHistory, assessedFee ? assessedFee[0] : undefined);
    }, [fee, user, purchaseHistory]);

    const {remainingDue, paid, original} = useMemo(() => {
        if (!assessedFee || assessedFee.length === 0 || assessedFee.some(af => af.status === 'due')) {
            return {
                original: null,
                paid: null,
                remainingDue: null,
            };
        }

        const sums = assessedFee.reduce((sum, fee) => {
            sum.original = sum.original.plus(fee.assessedCost);
            for (const payment of fee.studentFeePayments) {
                sum.paid = sum.paid.plus(payment.cost);
            }
            return sum;
        }, {
            original: new BigNumber(0),
            paid: new BigNumber(0),
        });
        return {
            original: sums.original.toNumber(),
            paid: sums.paid.toNumber(),
            remainingDue: sums.original.minus(sums.paid).toNumber(),
        }
    }, [assessedFee])



    const remainingQuantity = useMemo(() : number => {
        if (Array.isArray(assessedFee)) {
            return assessedFee.filter(af => af.status !== 'paid').length;
        }

        const timesPreviouslyPurchased = purchaseHistory?.fees[fee.id] ?? 0;
        let quantity = fee.quantity - timesPreviouslyPurchased;
        return quantity > 20 ? 20 : quantity;
    }, [fee, purchaseHistory, assessedFee]);

    useEffect(() => {
        if (remainingDue) {
            setQuantity(remainingQuantity);
        }
    }, [remainingDue, remainingQuantity]);

    const handleAddToCart = async (fee : Fee, skipQuestions: boolean) => {
        const url = new URL('/v1/fees/add-to-cart', apiEndpoint);

        let init : RequestInit = {
            method: 'PUT',
            signal: AbortSignal.timeout(10000),
            body: JSON.stringify({
                feeId: fee.id,
                courseQuestions: skipQuestions ? undefined : fee.courseQuestions,
                quantity,
            })
        };

        const response = await user?.apiFetch(url.toString(), init);

        if (response && response.ok) {
            await refreshFees();
            await refreshCart();
            if (mdDown && cartRef && cartRef.current !== null) {
                cartRef.current.scrollIntoView();
            }
        } else {
            // setErrorMessage('An unknown error has occurred.');
            console.info('An unknown error has occurred.');
        }
    }

    const handleFeeQuestionsModal = async () => {
        if (
            fee.courseQuestions.length
            && (assessedFee === undefined || assessedFee[0]?.courseAnswer?.length != fee.courseQuestions.length)
        ) {
            setShowCourseQuestionModal(true);
            return;
        }

        await handleAddToCart(fee, true);
    }

    return <>
        {user && <Card
            variant="outlined"
            sx={{
                boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.12)'
                },
                border: '1px solid rgba(0,0,0,0.12)',
                bgcolor: alternateBackground ? 'background.alternate' : 'background.paper',
                mb: 2,
                mr: 2,
            }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: {xs: 'column', md: 'row'},}}>
              <Box sx={{ display: 'flex', gap: 2, flex: 1, mr: 4 }}>
                <Box sx={{ color: '#6B21A8' }}><BookOpen /></Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom>{fee.name}</Typography>
                  <Typography variant="body2" component="div" color="text.secondary" gutterBottom>
                      {fee.description.substring(0, 120).split('¶').map((line) => <Box style={{display: 'inherit'}}>{line}</Box>)}
                      {fee.description.length > 120 && (
                          <ViewMoreModalLink fee={fee}/>
                      )}
                  </Typography>
                    {remainingDue !== null && remainingDue > 0 && (<Alert
                        severity="info"
                        icon={<DollarSign size={20} />}
                        sx={{ mb: 2 }}
                    >
                        Partial payment of {formatter.format(paid)} applied. Remaining balance available for purchase.
                    </Alert>)}
                    {!fee.allowPartialPayment && <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: 'primary.main',
                        fontSize: '0.875rem',
                        mb: 1
                    }}>
                        <Lock size={16} />
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Requires Payment in Full
                        </Typography>
                    </Box>}
                  <Stack direction="row" spacing={1}>
                    <Chip
                        label={fee.feeCategory}
                        size="small"
                        color={'secondary'}
                        sx={{ height: '24px' }}
                    />
                    <Chip
                        label={'Optional'}
                        size="small"
                        color="default"
                        variant="outlined"
                        sx={{ height: '24px' }}
                    />
                  </Stack>
                </Box>
              </Box>
              <Box sx={{ minWidth: 160, textAlign: 'right', width: {xs: '100%', md: 'auto'}}}>

                  <RenderPriceDisplay currentPrice={currentPrice} remainingDue={remainingDue} paid={paid} original={original} retailPrice={fee.amount ?? 0}/>
                  {displayIcon === 'PaidIcon' && assessedFee !== undefined && (<>
                      <Typography>Quanity: {assessedFee.length}</Typography>
                  </>)}
                  {displayIcon === 'BlockIcon' && (
                    <Tooltip title="Fee has been added the maximum allowable times" arrow>
                        <AlertCircle size={24} color="#6B21A8" />
                    </Tooltip>
                  )}
                  {['CartIcon', 'InCart'].includes(displayIcon) && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-end' }}>
                          <>
                              {remainingDue === null && <FormControl fullWidth size="small">
                                  <Select
                                      value={quantity}
                                      onChange={(e) => setQuantity(Number(e.target.value))}
                                      disabled={isInCart}
                                      sx={{
                                          height: '32px',
                                          '.MuiSelect-select': {
                                              py: '4px'
                                          }
                                      }}
                                  >
                                      {[...Array(remainingQuantity)].map((_, i) => (
                                          <MenuItem key={`option-${fee.id}-${i}`} value={i + 1}>
                                              Qty: {i + 1}
                                          </MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>}
                              {remainingDue !== null && <Typography>Quanity: {assessedFee?.length ?? remainingQuantity}</Typography>}
                              <Button
                                  variant="contained"
                                  color="secondary"
                                  disabled={isInCart}
                                  onClick={() => handleFeeQuestionsModal()}
                                  size="small"
                                  fullWidth
                              >
                                  {isInCart
                                      ? 'Added'
                                      : (remainingDue !== null ? 'Pay Remaining Balance' : 'Add to Cart')
                                  }
                              </Button>
                          </>
                      </Box>
                  )}
              </Box>
            </Box>
          </CardContent>
        </Card>}
        {fee.courseQuestions && <FeeQuestionModal
            show={showCourseQuestionModal}
            handleClose={() => setShowCourseQuestionModal(false)}
            fee={fee}
            performActionBasedOnCourseStatus={handleAddToCart ?? (async () => {})}
        />}
    </>;
}

type RenderPriceProps = {
    remainingDue : number | null
    paid : number | null
    original : number | null
    currentPrice: number
    retailPrice : number
}
const RenderPriceDisplay = (
    {remainingDue, paid, original, currentPrice, retailPrice} : RenderPriceProps
) => {
    if (remainingDue && original && paid) {
        return (
            <Box sx={{ textAlign: 'right' }}>
                <Box sx={{
                    bgcolor: 'secondary.50',
                    p: 1.5,
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'secondary.main',
                    mb: 1
                }}>
                    <Typography variant="body2" color="text.secondary">
                        Original Fee: {formatter.format(original)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Amount Paid: {formatter.format(paid)}
                    </Typography>
                    <Typography variant="h6" color="secondary" sx={{ fontWeight: 'bold', mt: 0.5 }}>
                        Balance Due: {formatter.format(remainingDue)}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (currentPrice != retailPrice) {
        return (<>
            <Typography variant="h6" sx={{
                textDecoration: 'line-through',
            }}>
                {formatter.format(retailPrice)}
            </Typography>
            <Typography variant="h6" gutterBottom>
                {formatter.format(currentPrice)}
            </Typography>
        </>)
    }

    return (
        <Typography variant="h6" gutterBottom>
            {formatter.format(currentPrice)}
        </Typography>
    );
};

export default OptionalFeeListItem;
