import * as React from 'react';
import {RefObject, useMemo, useState} from 'react';
import {Grid, Stack} from "@mui/material";
import OptionalFeeListItem from "@/components/Fees/OptionalFeeListItem.tsx";
import {Fee} from "@/types/Fee.ts";
import CategorySelect from "@/components/Fees/CategorySelect.tsx";
import SearchFees from "@/components/Fees/SearchFees.tsx";

type Props = {
    optionalFees: Fee[];
    cartRef: RefObject<HTMLSpanElement | null>
};

const groupFeesByCategory = (filteredFees: Fee[]) => {
    return filteredFees.reduce((acc, fee) => {
        if (!acc[fee.feeCategory]) {
            acc[fee.feeCategory] = [];
        }

        acc[fee.feeCategory].push(fee);

        return acc;
    }, {} as Record<string, Fee[]>);
};

const OptionalFeeList = ({optionalFees, cartRef} : Props) : React.ReactElement => {
    const [searchFeesValue, setSearchFeesValue] = React.useState('');
    const [feeCategory, setFeeCategory] = useState<string>('');

    const feesByCategory = useMemo(() => {
        const filteredFees = optionalFees
            ?.filter(fee => feeCategory === '' || fee.feeCategory === feeCategory)
            ?.filter(fee => searchFeesValue === '' || fee.name.toLowerCase().includes(searchFeesValue.toLowerCase()))
            ?.sort((a, b) => a.feeCategory.localeCompare(b.feeCategory));

        return groupFeesByCategory(filteredFees);
    }, [optionalFees, feeCategory, searchFeesValue]);

    return <Stack
        sx={{
            flexDirection: 'column',
            width: '100%',
            height: '100%',
        }}
    >
        <Grid container spacing={2}>
            <Grid item md={7} xs={12} mt={2} mb={2}>
                <SearchFees searchFeesValue={searchFeesValue} setSearchFeesValue={setSearchFeesValue}/>
            </Grid>
            <Grid item md={5} xs={12}  mt={2} mb={2}>
                <CategorySelect
                    feeCategory={feeCategory}
                    setFeeCategory={setFeeCategory}
                    feeCategories={
                        Array.from(new Set(optionalFees?.map(fee => fee.feeCategory)))
                            .sort((a, b) => a.localeCompare(b))
                    }
                />
            </Grid>
        </Grid>
        <Stack
            sx={{
                flexGrow: 1,
                mb: 2,
                overflow: 'auto',
            }}
        >
            {Object.keys(feesByCategory).map(category => {
                return <Stack
                    key={`category-${category}`}
                    sx={{
                        flexDirection: 'column',
                    }}
                >
                    {feesByCategory[category].map(fee => {
                        return <OptionalFeeListItem
                            key={`optional-fee-${fee.id}`}
                            fee={fee}
                            cartRef={cartRef}
                        />
                    })}
                </Stack>
            })}
        </Stack>
    </Stack>
}

export default OptionalFeeList;
