import {useCallback, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import RegisterCommunityMemberForm from '../components/Forms/RegisterCommunityMemberForm';
import NavHeader from "../components/Navigation/NavHeader";
import NoticeProvider from '../components/Providers/NoticeProvider';
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

export const signInError = 'Invalid Student ID or Birth Date';

const RegisterCommunityMember = () => {
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const handleClearAlert = useCallback(async () => {
        setShowAlert(false);
    }, [setShowAlert]);

    return (
        <NoticeProvider>
            <NavHeader showNav={false} />
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Col sm={12} className="bg-white p-3 mx-0 my-5 rounded-sm">
                        <GlenSnackBar onClose={handleClearAlert} show={showAlert} severity={'error'} message={signInError}/>
                        <RegisterCommunityMemberForm />
                    </Col>
                </Row>
            </Container>
        </NoticeProvider>
    );
};

export default RegisterCommunityMember;
