import RegisterCommunityMember from './pages/RegisterCommunityMember';
import HomePage from "@/pages/HomePage.tsx";
import RegisterGuest from "@/pages/RegisterGuest.tsx";
import {createRootRoute, createRoute, createRouter, Outlet} from "@tanstack/react-router";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import JWTProvider, {JWTInstructorProvider} from "@/components/Providers/JWTProvider.tsx";
import InstructorProgramList from "@/pages/instructor/InstructorProgramList.tsx";
import InstructorSignIn from "@/pages/instructor/InstructorSignIn.tsx";
import InstructorProgramsProvider from "@/components/Providers/InstructorProgramsProvider.tsx";
import InstructorFeeProposalsProvider from "@/components/Providers/InstructorFeeProposalsProvider.tsx";
import FeeProvider from "@/components/Providers/FeesProvider.tsx";
import {CartContextProvider} from "@/components/Providers/CartProvider.tsx";
import FeeProposalsPage from "@/pages/instructor/FeeProposalsPage.tsx";
import ProgramsProvider from "@/components/Providers/ProgramsProvider.tsx";
import Programs from "@/pages/Programs.tsx";
import SignIn from "@/pages/SignIn.tsx";
import InstructorProgram from "@/pages/instructor/InstructorProgram.tsx";
import ActivityDetailView from "@/pages/instructor/ActivityDetailView.tsx";
import CartPage from "@/pages/CartPage.tsx";
import FeePage from "@/pages/FeePage.tsx";
import PaymentComplete from "@/pages/PaymentComplete.tsx";
import EditProfile from "@/pages/EditProfile.tsx";
import MyRegistrations from "@/pages/MyRegistrations.tsx";
import Instructions from "@/pages/Instructions.tsx";
import SignOut from "@/pages/SignOut.tsx";
import NotFound from "@/pages/NotFound.tsx";
import {StripeTerminalProvider} from "@/components/Providers/StripeTerminalProvider.tsx";

const rootRoute = createRootRoute({
    component: () => <Outlet/>
});

const registerCommunityMemberRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/register-community-member',
    component: RegisterCommunityMember,
});
const registerGuestRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/register-guest',
    component: RegisterGuest,
});
const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    component: HomePage,
});

/**
 * Instructor Routes
 */
const instructorRoutes = createRoute({
    getParentRoute: () => rootRoute,
    path: '/instructor',
    component: () => <LocalizationProvider dateAdapter={AdapterMoment}>
        <JWTInstructorProvider><Outlet /></JWTInstructorProvider>
    </LocalizationProvider>
});

const instructorSignInRoute = createRoute({
    getParentRoute: () => instructorRoutes,
    path: '/sign-in',
    component: InstructorSignIn
});

const instructorGuardRoutes = createRoute({
    getParentRoute: () => instructorRoutes,
    id: 'instructor-guard',
    component: () =>
        <InstructorProgramsProvider><InstructorFeeProposalsProvider><FeeProvider><CartContextProvider>
            <Outlet />
        </CartContextProvider></FeeProvider></InstructorFeeProposalsProvider></InstructorProgramsProvider>
});

const instructorProgramListRoute = createRoute({
    getParentRoute: () => instructorGuardRoutes,
    path: '/',
    component: InstructorProgramList
});

const instructorProgramRoute = createRoute({
    getParentRoute: () => instructorGuardRoutes,
    path: '/program/$programId',
    component: InstructorProgram
});

const instructorActivityDetailRoute = createRoute({
    getParentRoute: () => instructorGuardRoutes,
    path: '/course/$programId/$courseId',
    component: ActivityDetailView
});

const instructorFeeProposalsRoute = createRoute({
    getParentRoute: () => instructorGuardRoutes,
    path: '/fee-proposals',
    component: FeeProposalsPage
});

const instructorCartRoute = createRoute({
    getParentRoute: () => instructorGuardRoutes,
    path: '/cart',
    component: CartPage
});

const instructorFeesRoute = createRoute({
    getParentRoute: () => instructorGuardRoutes,
    path: '/fees',
    component: FeePage
});

const instructorPaymentCompleteRoute = createRoute({
    getParentRoute: () => instructorGuardRoutes,
    path: '/payment-complete',
    component: PaymentComplete
});

/**
 * User Routes
 */
const userRoutes = createRoute({
    getParentRoute: () => rootRoute,
    id: 'user-routes',
    component: () => <JWTProvider><StripeTerminalProvider debugEnabled={true}><Outlet/></StripeTerminalProvider></JWTProvider>
});

const userSignInRoute = createRoute({
    getParentRoute: () => userRoutes,
    path: '/signin',
    component: SignIn
});

const userGuardRoutes = createRoute({
    getParentRoute: () => userRoutes,
    id: 'user-guard',
    component: () =>
        <ProgramsProvider><FeeProvider><CartContextProvider>
            <Outlet />
        </CartContextProvider></FeeProvider></ProgramsProvider>
});

const programsRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/programs',
    component: Programs
});

const editProfileRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/edit-profile',
    component: EditProfile
});

const myRegistrationsRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/my-registrations',
    component: MyRegistrations
});

const cartRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/cart',
    component: CartPage
});

const feesRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/fees',
    component: FeePage
});

const paymentCompleteRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/payment-complete',
    component: PaymentComplete
});

const instructionsRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/instructions',
    component: Instructions
});

const signOutRoute = createRoute({
    getParentRoute: () => userGuardRoutes,
    path: '/signout',
    component: SignOut
});

const routeTree = rootRoute.addChildren([
    indexRoute,
    registerCommunityMemberRoute,
    registerGuestRoute,
    instructorRoutes.addChildren([
        instructorSignInRoute,
        instructorGuardRoutes.addChildren([
            instructorProgramListRoute,
            instructorProgramRoute,
            instructorActivityDetailRoute,
            instructorFeeProposalsRoute,
            instructorCartRoute,
            instructorFeesRoute,
            instructorPaymentCompleteRoute,
        ]),
    ]),
    userRoutes.addChildren([
        userSignInRoute,
        userGuardRoutes.addChildren([
            programsRoute,
            editProfileRoute,
            myRegistrationsRoute,
            cartRoute,
            feesRoute,
            paymentCompleteRoute,
            instructionsRoute,
            signOutRoute
        ]),
    ]),
]);

export const appRouter = createRouter({
    routeTree: routeTree,
    defaultNotFoundComponent: NotFound
});
