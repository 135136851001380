import {useContext, useEffect, useState} from 'react';
import {guardianValidator, isState, phoneValidation, postalCodeUS, zodDateRegex} from "../../Helpers/validationHelpers";
import {apiEndpoint, jwtContext} from "../Providers/JWTProvider";
import {Guardian} from "./GuardianForm";
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import {z} from "zod";
import CommunityMemberForm from "@/components/Forms/CommunityMemberForm.tsx";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";

const schema = z.object({
    nameFirst: z.string(),
    nameLast: z.string(),
    dob: zodDateRegex,
    grade: z.string(),
    gender: z.string(),
    address: z.string().nullable(),
    city: z.string().nullable(),
    state: isState.nullable(),
    zip: postalCodeUS.nullable(),
    homePhone: phoneValidation.transform(value => value !== '' ? value : 'null').nullable(),
    email: z.string().email("Email must be valid.").transform(value => value === null ? '' : value),
    guardians: guardianValidator
});

type EditCommunityMemberFormSchemaType = z.infer<typeof schema>;

type State = {
    nameFirst: string;
    nameLast: string;
    dob: string;
    grade: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    homePhone: string;
    gender: string;
    email: string;
    guardians: Guardian[];
}

const EditCommunityMemberForm = () => {
    const user = useContext(jwtContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<null|State>(null);

    const form = useForm<EditCommunityMemberFormSchemaType>({
        // defaultValues set in useEffect
        resolver: zodResolver(schema)
    });

    useEffect(() => {
        if (!user){
            return;
        }

        const url = new URL(`/v1/profile/community`, apiEndpoint);
        user.apiFetch(url.toString()).then(async (response) => {
            const result = await response.json();
            setInitialValues(result);
            // default form values
            form.reset(result);
            //needed to trigger the validator
            await form.trigger('dob');
        });
    }, [user]);

    const handleSaveCM = async (values: EditCommunityMemberFormSchemaType) => {
        if (!user){
            setLoading(false);
            return;
        }
        setLoading(true);

        const url = new URL(`/v1/profile/community`, apiEndpoint);
        let init: RequestInit = {method: 'PUT'};
        init.body = JSON.stringify(values);
        const response = await user.apiFetch(url.toString(), init);

        if (response.status !== 200) {
            setLoading(false);
            return null;
        }

        setLoading(false);
    };

    if (!initialValues) {
        return <CircularLoadingIndicator/>;
    }

    return <FormProvider {...form}>
        <CommunityMemberForm
            submitHandlerFunction={handleSaveCM}
            communityMemberAlreadyExists={false}
            loading={loading}
            requireSignature={false}
        />
    </FormProvider>
};

export default EditCommunityMemberForm;
