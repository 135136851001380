import {ReactElement, useEffect, useState} from "react";
import {
    CONNECTED,
    CONNECTING,
    NOT_READY,
    PaymentStatus,
    PROCESSING,
    READY,
    useStripeTerminal,
    WAITING_FOR_INPUT
} from "@/components/Providers/StripeTerminalProvider.tsx";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {Box, Button, Grid} from "@mui/material";
import type {SetupIntent} from "@/types/SetupIntent.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {green, red, yellow} from '@mui/material/colors';
import {apiEndpoint, useUser} from "@/components/Providers/JWTProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {useNavigate} from "@tanstack/react-router";
import {CreateSetupIntentResponse} from "@/types/CreateSetupIntentResponse.ts";
import FormControl from "@mui/material/FormControl";
import {useFormContext, useWatch} from "react-hook-form";
import {PaymentFormSchemaType} from "@/components/Forms/PaymentForm.tsx";
import {buildPayAmount} from "@/components/Forms/CartPaymentForm.tsx";
import {closeFMWindow} from "@/utils/fmScripts.ts";

type Props = {
    setupIntent: SetupIntent;
    cost: number;
    courseSectionId: string | null;
    stripeErrorMessage: string;
    setStripeErrorMessage: (stripeErrorMessage: string) => void;
    cartHasFees?: boolean;
    setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
    isProcessing: boolean;
}

type TerminalPaymentIntent = {
    paymentIntent: string;
    clientSecret: string;
}

const mapPaymentStatus = (status: PaymentStatus | undefined, capturedPayment : CompleteSetupintent | null) : String | undefined => {
    if (capturedPayment?.paymentIntentId) {
        return "Ready to pay now";
    }
    switch (status) {
        case WAITING_FOR_INPUT:
            return "Tap or Insert card";
        case READY:
            return "Ready For Card";
        case NOT_READY:
            return 'Reader Not Ready for card';
        case PROCESSING:
            return "Processing card";
    }
    return status;
}

type CompleteSetupintent = {
    setupId: null;
    paymentIntentId: string;
    cartId: string;
    paymentMethod: 'card';
    courseSectionId: null;
    payNowAmount: number;
    paymentType: 'stripe';
}

//https://docs.stripe.com/terminal/quickstart?lang=node
export const StripeTerminal = ({
    cost,
    courseSectionId,
    setStripeErrorMessage,
    stripeErrorMessage,
    cartHasFees,
    setIsProcessing,
    isProcessing,
} : Props) : ReactElement => {
    const {user, isEmployee} = useUser();
    const navigate = useNavigate();
    const {cart, cartPaymentSuccess, refreshCart} = useCartContext();
    const {refreshPurchaseHistory, refreshFees} = useFeeProvider();
    const {terminal, connected, paymentStatus, connectReader, reader, readers} = useStripeTerminal();
    const form = useFormContext<PaymentFormSchemaType>();
    const paymentType = useWatch<PaymentFormSchemaType>({name: 'paymentType'});
    const partialPaymentAmount = useWatch<PaymentFormSchemaType>({name: 'partialPaymentAmount'});
    const [capturedPayment, setCapturedPayment] = useState<CompleteSetupintent | null>(null);

    const collectPayment = async (): Promise<undefined | CompleteSetupintent> => {
        setCapturedPayment(null);
        setStripeErrorMessage("");
        if (!user || !cart || !terminal) {
            setStripeErrorMessage("Error collecting payment.")
            return;
        }

        const formValidation = await form.trigger();
        if (!formValidation) {
            setStripeErrorMessage("Fix form Error and try terminal again.");
            return;
        }
        const payNowAmount = buildPayAmount(
            paymentType?.toString(),
            cost,
            partialPaymentAmount?.toString()
        );

        if (paymentStatus === WAITING_FOR_INPUT) {
            await terminal.clearReaderDisplay();
            setStripeErrorMessage("");
        }

        const paymentIntentRequest = await user.apiFetch(
            new URL(`/v1/payment/create-terminal-payment-intent`, apiEndpoint).toString(),
            {
                method: 'POST',
                body: JSON.stringify({
                    cartId: cart.id,
                    payNowAmount,
                }),
            }
        );
        const terminalPaymentIntent = await paymentIntentRequest.json() as TerminalPaymentIntent;
        if (terminal.getPaymentStatus() === 'waiting_for_input') {
            try {
                await terminal.cancelCollectPaymentMethod();
            } catch (error) {
                console.error('error canceling other payment', error);
            }
        }

        const response = await terminal.collectPaymentMethod(terminalPaymentIntent.clientSecret, {
            config_override: {
                request_dynamic_currency_conversion: false,
            },
        });

        if ('error' in response) {
            if (response.error.code === 'canceled' || response.error.message === 'Transaction is cancelled by the user.') {
                return;
            }
            console.error('Error collecting payment', response.error);
            setStripeErrorMessage(`There was an error collecting the card: ${response.error.message}`);
        } else {
            const processedPayment = await terminal.processPayment(response.paymentIntent);
            if ('error' in processedPayment) {
                setStripeErrorMessage(`There was an error collecting the card: ${processedPayment.error.message}`);
            } else {
                const capturedPayment = {
                    setupId: null,
                    paymentIntentId: processedPayment.paymentIntent.id,
                    cartId: cart.id,
                    paymentMethod: 'card',
                    courseSectionId: null,
                    payNowAmount,
                    paymentType: 'stripe',
                } satisfies CompleteSetupintent;
                setCapturedPayment(capturedPayment);
                return capturedPayment;
            }
            setIsProcessing(false);
        }
    }

    const processCard = async () => {
        if (!user) {
            setStripeErrorMessage("User is not ready.  Please reload page.");
            setIsProcessing(false);
            return;
        }

        let capture : CompleteSetupintent | null | undefined = capturedPayment
        if (!capture) {
            setStripeErrorMessage("Reader is not ready to proccess card");
            setIsProcessing(false);
            return;
        }
        const url = new URL('/v1/payment/complete-setup-intent', import.meta.env.VITE_APP_API_ENDPOINT);

        const response = await user.apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify(capture),
        });

        const json = await response.json() as CreateSetupIntentResponse;

        if (json.errorCode !== 0) {
            setStripeErrorMessage(json.message);
            return;
        }

        localStorage.removeItem('registration-course-' + courseSectionId)

        await refreshCart();
        await refreshPurchaseHistory();
        await refreshFees();

        closeFMWindow();

        await cartPaymentSuccess(`Payment of $${capture.payNowAmount} completed.`);
        setIsProcessing(false);
        const route : string = isEmployee ? '/instructor/fees' : cartHasFees ? '/fees' : '/my-registrations';
        await navigate({to: route, search: {purchased : 'purchased'}});
    }

    useEffect(() => {
        if (!isProcessing) {
            setStripeErrorMessage("Card is not ready to process");
            setIsProcessing(false);
        }
        processCard().catch(error => {
            if (error instanceof Error) {
                setStripeErrorMessage(`Error processing card: ${error.message}`);
            } else {
                setStripeErrorMessage(`Error processing card`);
            }
        });
    }, [isProcessing]);

    useEffect(() => {
        setCapturedPayment(null);
        collectPayment().catch(error => {
            if (error instanceof Error) {
                setStripeErrorMessage(`Error processing card: ${error.message}`);
            } else {
                setStripeErrorMessage(`Error processing card`);
            }
        });
    }, [partialPaymentAmount, cost, partialPaymentAmount]);

    useEffect(() => {
        setCapturedPayment(null);
    }, [stripeErrorMessage]);

    useEffect(() => {
        if (!reader || !terminal || connected != CONNECTED) {
            return;
        }

        collectPayment().catch((err) => {
            // if we disconnect while waiting for a payment intent we likely don't want to display an error
            if (terminal.getConnectedReader() === reader) {
                setStripeErrorMessage(`There was an error collecting the card.`);
                console.error(`There was an error collecting the card.`, err);
            }
            setIsProcessing(false);
        });

    }, [reader, cost, connected]);

    useEffect(() => {
        setIsProcessing(false);
    }, [connected]);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>Select Reader</Typography>
                <FormControl>
                    <Select
                        displayEmpty={true}
                        id="select-card-reader"
                        size="small"
                        value={reader?.id ?? ""}
                        onChange={(event) => {
                            terminal?.disconnectReader();
                            const reader = readers.find(r => r.id == event.target.value);
                            if (reader && connectReader) {
                                connectReader(reader).catch(e => {
                                    if (e && 'message' in e && typeof e.message === 'string') {
                                        setStripeErrorMessage(e.message);
                                    } else {
                                        setStripeErrorMessage('Error connecting to stripe reader');
                                    }
                                })
                            } else if (connectReader) {
                                connectReader(undefined).catch(e => {
                                    if (e && 'message' in e && typeof e.message === 'string') {
                                        setStripeErrorMessage(e.message);
                                    } else {
                                        setStripeErrorMessage('Error connecting to stripe reader');
                                    }
                                })
                            }
                        }}
                    >
                        <MenuItem value={""} sx={{width: '100%'}}>-- Select Reader --</MenuItem>
                        {readers.map((r) => (<MenuItem key={`reader-${r.id}`} value={r.id}>{r.label}</MenuItem>))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography color={connected === CONNECTED ? green["800"] : connected === CONNECTING ? yellow['800'] : red['800']}>
                    Card Reader Status: {reader?.label} {connected}
                </Typography>
            </Grid>
            {connected === CONNECTED && <Grid item xs={12}>
                <Typography color={capturedPayment?.paymentMethod !== undefined ? green["800"] : yellow['800']}>
                    Payment status: {mapPaymentStatus(paymentStatus, capturedPayment)}
                </Typography>
            </Grid>}
            <Grid item xs={12}>
                {stripeErrorMessage !== "" && <Box sx={{color: 'red'}}>{stripeErrorMessage}</Box>}
                {(capturedPayment !== null || stripeErrorMessage !== "") && <Button
                    sx={{
                        backgroundColor: '#631d79',
                        color: '#fff',
                        pl: 2,
                        pr:2,
                        borderRadius: '8px',
                        fontWeight: 800,
                        mt: 2,
                    }}
                    variant="contained"
                    onClick={() => {
                        collectPayment().catch(e => {
                            console.error('error retrying payment', e);
                        })
                    }}
                >
                    Re-Capture payment
                </Button>}
            </Grid>
        </Grid>
    </>
}
