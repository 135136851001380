import * as React from 'react';
import {AssessedFee} from "@/types/AssessedFee.ts";
import {Stack} from "@mui/material";
import OptionalFeeListItem from "@/components/Fees/OptionalFeeListItem.tsx";
import {useGroupFees} from "@/hooks/Fees/useGroupFees.ts";

type Props = {
    purchasedFees: AssessedFee[];
};

const PurchasedFeesList = ({purchasedFees} : Props) : React.ReactElement => {
    const orderedPurchasedFees =useGroupFees(purchasedFees);

    return <Stack>
        {orderedPurchasedFees?.map((studentFee) => {
            return <OptionalFeeListItem
                key={`purchased-fee-${studentFee[0].id}`}
                fee={studentFee[0].fee}
                assessedFee={studentFee}
            />
        })}
    </Stack>
}

export default PurchasedFeesList;
