import NavHeader from "@/components/Navigation/NavHeader.tsx";
import InstructorHeader from "@/components/Navigation/InstructorHeader.tsx";
import { ReactNode } from "react";
import MainDisplayContainer from "./Layout/MainDisplayContainer";
import {useUser} from "@/components/Providers/JWTProvider.tsx";
import {Stack} from "@mui/material";

const StandardPage = ({children} : {children: ReactNode}) => {
    const {isEmployee} = useUser();
    return <Stack sx={{backgroundColor: '#631d79', flexDirection: 'column', height: '100vh'}}>
        {!isEmployee && <NavHeader showNav={true}/>}
        {isEmployee && <InstructorHeader
            showNav={true}
        />}
        <MainDisplayContainer>
            {children}
        </MainDisplayContainer>
    </Stack>
}

export default StandardPage;
