import {createContext, type ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {apiEndpoint, useUser} from "@/components/Providers/JWTProvider.tsx";
import {Cart, initialCartState, RawCart} from "@/types/Cart.ts";
import {ProgramsProviderContext} from "@/components/Providers/ProgramsProvider.tsx";
import BigNumber from "bignumber.js";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {useStripeTerminal} from "@/components/Providers/StripeTerminalProvider.tsx";
import {CartItem} from "@/types/CartItem.ts";
import type {OverridableStringUnion} from "@mui/types";
import type {AlertColor, AlertPropsColorOverrides} from "@mui/material";

export type CartState = {
    loading: boolean;
    showAlert: boolean;
    message: string;
    alertVariant: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
};

export type NormalizedCartItem = {
    cartItem: CartItem;
    quantity: number;
    itemGroup: CartItem[];
};

type CartContextInterface = {
    cart : Cart | undefined;
    deleteCartItem : (cartItemId: string[]) => void;
    emptyCart : () => void;
    addCartItem : (courseSectionId: string) => void;
    refreshCart : () => Promise<void>;
    cartPaymentSuccess : (message: string) => Promise<void>;
    getCartAlertStatus : () => CartState;
    resetCartAlertStatus : () => void;
    getCartTotal : () => number;
    getOriginalCartTotal : () => number;
    cartIsEmpty : boolean;
    normalizedCart : NormalizedCartItem[];
};

type CartContextProviderProps = {children : ReactNode}

const CartContext = createContext<CartContextInterface>({} as CartContextInterface);

const CartContextProvider = ({children} : CartContextProviderProps) => {
    const {user} = useUser();
    const {allAssessedFees, purchaseHistory, refreshFees} = useFeeProvider();
    const [programsState, , , , , , ,refreshPrograms] = useContext(ProgramsProviderContext);
    const [cartStatus, setCartAlertStatus] = useState<CartState>(initialCartState);
    const apiFetch = user?.apiFetch;
    const {terminal, reader} = useStripeTerminal();
    const userId = useMemo(() => {
        return user ? 'employeeID' in user ? user.employeeID : user.studentId : null;
    }, [user]);

    const [rawCart, setCart] = useState<RawCart>();
    const [cartError, setCartError] = useState<string | null>(null);

    const cart = useMemo(() : Cart | undefined => {
        if (!rawCart) return undefined;
        return {
            id: rawCart.id,
            cartTimeout: rawCart.cartTimeout,
            cartItems: rawCart.cartItems.map(cartItem => {
                if (cartItem.studentFeeId !== undefined) {
                    const assessedFee = allAssessedFees?.find(fee => fee.id === cartItem.studentFeeId);
                    const amountPaid = assessedFee ? (purchaseHistory?.partialStudentFeeIdsAndAmountRemaining[assessedFee.id] ?? 0) : 0;

                    return {
                        id: cartItem.id,
                        name: assessedFee?.fee.name ?? 'error',
                        description: assessedFee?.fee.description ?? 'error',
                        courseSectionId: '',
                        deliveryMethod: '',
                        sectionNumber: 0,
                        dateStartFull: '',
                        dateEndFull: '',
                        meetingTime: '',
                        cost: (assessedFee) ? BigNumber(assessedFee.assessedCost).minus(amountPaid).toNumber() : 0,
                        originalCost: 0,
                        itemType: 'fee',
                        assessedFeeId: assessedFee?.id ?? 'error',
                        feeId: assessedFee?.fee.id ?? 'error',
                        allowPartialPayment: cartItem.allowPartialPayment,
                        studentFeeId: cartItem.studentFeeId,
                    }
                }

                const program = programsState.programs.find(p => p.programUUID === cartItem.programId);
                const courseSection = program?.courses.find(c => c.courseSelectionUUID === cartItem.courseSectionId);

                if (!courseSection) console.error('cannot find course section', program, courseSection, cartItem);

                return {
                    id: cartItem.id,
                    name: courseSection?.name ?? 'error',
                    courseSectionId: courseSection?.courseSelectionUUID ?? 'error',
                    deliveryMethod: courseSection?.deliveryMethod ?? '',
                    sectionNumber: courseSection?.sectionNumber ?? 0,
                    dateStartFull: courseSection?.dateStartFull ?? 'error',
                    dateEndFull: courseSection?.dateEndFull ?? 'error',
                    meetingTime: courseSection?.meetingTime ?? 'error',
                    cost: BigNumber(courseSection?.cost ?? 0).minus(courseSection?.paidOrPendingAmount ?? 0).toNumber(),
                    originalCost: courseSection?.cost ?? 0,
                    itemType: 'registration',
                    allowPartialPayment: cartItem.allowPartialPayment,
                }
            })
        }
    }, [rawCart, programsState, purchaseHistory, allAssessedFees]);

    const normalizedCart = useMemo(() : NormalizedCartItem[] => {
        const nc = new Map<string, NormalizedCartItem>();
        if (!cart || !cart.cartItems) {
            return [];
        }

        for (const cartItem of cart.cartItems) {
            const ciKey = `${cartItem.courseSectionId ? cartItem.courseSectionId : cartItem.feeId}-${cartItem.cost}`;
            const ci = nc.get(ciKey);
            if (!ci) {
                nc.set(ciKey, {
                    cartItem,
                    quantity: 1,
                    itemGroup: [cartItem],
                });
            } else {
                nc.set(
                    ciKey,
                    {
                        cartItem,
                        quantity: (ci.quantity ?? 0) + 1,
                        itemGroup: [
                            ...ci.itemGroup,
                            cartItem,
                        ],
                    }
                )
            }
        }

        return Array.from(nc.values());
    }, [cart])

    useEffect(() => {
        if (!terminal || !terminal.getConnectedReader() || !cart) {
            return;
        }
        if (normalizedCart.length === 0) {
            terminal.clearReaderDisplay().catch(e => {
                console.error('error clearing terminal display', e);
            });
            return;
        }
        if (terminal.getPaymentStatus() === 'waiting_for_input') {
            return;
        }

        terminal.setReaderDisplay({
            type: "cart",
            cart: {
                currency: 'USD',
                total: getCartTotal() * 100,
                line_items: normalizedCart.map(ci => ({
                    amount: Math.floor(ci.cartItem.cost * 100),
                    description: ci.cartItem.name,
                    quantity: ci.quantity,
                })),
            }
        }).catch(e => {
            console.error('error updating terminal display', e);
        });
    }, [cart, terminal, reader]);

    const fetchCart = async () => {
        if (!apiFetch) {
            return {
                id: '',
                cartItems : [],
            }
        }
        const url = new URL('/v1/cart/', apiEndpoint);
        let init: RequestInit = {method: 'GET'};

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error fetching the cart')
            return null;
        }

        const cartResponse = await response.json() as RawCart;

        setCart(cartResponse);
        return cartResponse;
    };

    const deleteCartItem = async (cartItemIds: string[]) => {
        if (!apiFetch) {
            return;
        }

        const url = new URL(`/v1/cart/cartItems/`, apiEndpoint);
        let init: RequestInit = {
            method: 'DELETE',
            body: JSON.stringify({
                cartItemIds
            })
        };

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error deleting the cart item');
            return null;
        }

        await refreshCart()
    };

    const emptyCart = async () => {
        if (!apiFetch) {
            return;
        }
        const url = new URL(`/v1/cart/empty`, apiEndpoint);
        let init: RequestInit = {method: 'DELETE'};

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error emptying the cart');
            return null;
        }

        localStorage.removeItem('cart-' + userId);
        await refreshCart()
    }

    const addCartItem = async (courseSectionId: string) => {
        if (!apiFetch) {
            return;
        }

        const url = new URL(`/v1/cart/add`, apiEndpoint);
        let init: RequestInit = {method: 'PUT'};

        init.body = JSON.stringify({
            courseSectionId: courseSectionId,
        });

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error adding cart item');
            return null;
        }

        localStorage.removeItem('registration-course-' + courseSectionId);

        await refreshCart()
    }

    const refreshCart = async () => {
        await Promise.all([
            refreshPrograms(),
            fetchCart(),
            refreshFees(),
        ])
    }

    const cartPaymentSuccess = async (message: string) => {
        setCartAlertStatus({
            loading: false,
            showAlert: true,
            message: message,
            alertVariant: 'success',
        });
    }

    const getCartAlertStatus = () => {
        return cartStatus;
    }

    const resetCartAlertStatus = () => {
        setCartAlertStatus(initialCartState);
    }

    const getOriginalCartTotal = () => {
        const costs = cart?.cartItems.map(item => item.cost);
        return costs?.length ? costs.reduce((sum, num) => sum.plus(num), new BigNumber(0)).toNumber() : 0;
    }

    const getCartTotal = () => {
        const costs = cart?.cartItems.map(item => item.cost);
        return costs?.length ? costs.reduce((sum, num) => sum.plus(num), new BigNumber(0)).toNumber() : 0;
    }

    const cartIsEmpty = useMemo(() => {
        return !(cart && cart.cartItems.length > 0);
    }, [cart])

    const value = {
        cart,
        deleteCartItem,
        addCartItem,
        refreshCart,
        emptyCart,
        cartPaymentSuccess,
        getCartAlertStatus,
        resetCartAlertStatus,
        getCartTotal,
        getOriginalCartTotal,
        cartIsEmpty,
        normalizedCart
    };

    useEffect(() => {
        if (user) {
            void fetchCart()
        }

        if (cartError) {
            throw new Error(cartError);
        }
    }, []);

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
};

const useCartContext = () : CartContextInterface => {
    const context = useContext(CartContext)

    if (Object.keys(context).length === 0) {
        throw new Error('useCartContext must be used within a CartContextProvider')
    }

    return context
}

export {CartContextProvider, useCartContext};
