import {Badge, IconButton, SvgIconProps, Typography} from "@mui/material";
import {ReactElement} from "react";
import {useNavigate} from "@tanstack/react-router";
import { grey } from '@mui/material/colors';

type Props = {
    text : string;
    link ?: string;
    icon ?: ReactElement<SvgIconProps>;
    badges ?: number;
    onClick ?: () => void;
    hideBorder?: boolean;
}

const displayIcon = (icon ?: ReactElement<SvgIconProps>, badges ?: number) : ReactElement => {
    if (icon && badges) {
        return <Badge badgeContent={badges} color="primary">
            {icon}
        </Badge>
    }

    if (icon) {
        return icon;
    }

    return <></>;

}

const NavButton = ({text, link, icon, badges, onClick, hideBorder} : Props) => {
    const navigate = useNavigate();

    return <IconButton
        color="inherit"
        aria-label={link}
        onClick={() => {
            link && navigate({to: link});
            onClick && onClick();
        }}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            "&:hover": {
                backgroundColor: "#FFF"
            },
            paddingX: {
                md: 2,
            },
            borderRadius: 0,
            borderRight: hideBorder ? undefined : {
                xs: 0,
                md: `2px solid ${grey[400]}`,
            },
            borderBottom: hideBorder ? undefined :{
                xs: `2px solid ${grey[400]}`,
                md: 0,
            },
            "&:last-child": {
                borderBottom: 0,
                borderRight: 0,
            }
        }}
        disableRipple
    >
        {displayIcon(icon, badges)}
        <Typography variant='body1' sx={{pt: 1, bp: 0}}>{text}</Typography>
    </IconButton>;
};

export default NavButton;
