import {useMemo, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import RecoverEmailModal from "../Modal/RecoverEmailModal";
import {useNotice} from "../Providers/NoticeProvider";
import PrimaryButton from "@/components/PrimaryButton.tsx";
import {Login} from "@mui/icons-material";
import {Button, Link} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useRouter} from "@tanstack/react-router";
import {z} from "zod";
import {FormProvider, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RhfTextField} from "mui-rhf-integration";
import moment from "moment";
import {setPreferredReader} from "@/components/Providers/StripeTerminalProvider.tsx";

type Props = {
    handleSignInSubmit: (values: SignInFormSchemaType) => void;
};

const parseDateString = (orginalValue: string): null | Date => {
    const date = moment(orginalValue, 'MM/DD/YYYY', true);
    return date.isValid() ? date.toDate() : null;
}

const schema = z.object({
    studentId: z.string(),
    dob: z.string().refine(
        (dateString) => {
            const date = parseDateString(dateString);
            return date !== null && date < new Date();
        },
        {message: 'must be formatted MM/DD/YYYY'}
    ),
});

export type SignInFormSchemaType = z.infer<typeof schema>;

const SignInForm = ({
    handleSignInSubmit
}: Props) => {
    const {history} = useRouter();
    const [loading, setLoading] = useState<boolean>(false);
    const [showForgotCommunityMemberIdModal, setShowForgotCommunityMemberIdModal] = useState<boolean>(false);
    const [isForgotStudent, setIsForgotStudentId] = useState<boolean>(false);
    const [notice] = useNotice();

    const initialValues = useMemo(()=> {
        const defaultValue = {
            studentId: '',
            dob: ''
        };

        if (window.location.hash) {
            const searchUrl = new URLSearchParams(window.location.hash.replace('#', ''));
            const studentId = searchUrl.get('studentId');
            if (studentId) {
                defaultValue.studentId = studentId;
            }

            const dob = searchUrl.get('dob');
            if (dob) {
                defaultValue.dob = dob;
            }

            const readerId = searchUrl.get('readerId');
            if (readerId) {
                setPreferredReader(readerId);
            }

            if (schema.safeParse(defaultValue).success) {
                handleSignInSubmit(defaultValue);
            }
        }

        return defaultValue;
    }, []);

    const form = useForm<SignInFormSchemaType>({
        defaultValues: initialValues,
        resolver: zodResolver(schema)
    });

    return <>
        <FormProvider {...form}>
            <form className="form-bg pt-2" onSubmit={
                form.handleSubmit(async (values) => {
                    setLoading(true);
                    handleSignInSubmit(values);
                    setLoading(false);
                })
            }>
                <Grid container spacing={2} sx={{px: 2}}>
                    <Grid size={{xs: 12}}>
                        <ReactMarkdown className="markdown">{notice.logInMessage}</ReactMarkdown>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <RhfTextField
                            control={form.control}
                            name={'studentId'}
                            label={'Student or Community Member ID Number:'}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <RhfTextField
                            control={form.control}
                            name={'dob'}
                            label={'Birth Date (MM/DD/YYYY):'}
                            placeholder={'MM/DD/YYYY'}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{mx: 1}}>
                    <Grid size={{xs: 12}}>
                        <hr />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{px: 2}}>
                    <Grid size={{xs: 12}}>
                        <PrimaryButton
                            type="submit"
                            sx={{width: '100%'}}
                            disabled={loading}
                        >
                            <Login sx={{mr: 1}}/> Login
                        </PrimaryButton>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Button variant="outlined" fullWidth onClick={() => {
                            history.back();
                        }} sx={{ mt: 2 }}>
                            Back
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{px: 2}}>
                    <Grid size={{xs: 12}}>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                setIsForgotStudentId(true);
                                setShowForgotCommunityMemberIdModal(true);
                            }}
                            sx={{pb: 1}}
                        >
                            Forgot Student ID Number
                        </Link>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                setIsForgotStudentId(false);
                                setShowForgotCommunityMemberIdModal(true);
                            }}
                        >
                            Forgot Community Member ID Number
                        </Link>
                        <RecoverEmailModal
                            show={showForgotCommunityMemberIdModal}
                            handleYes={() => setShowForgotCommunityMemberIdModal(false)}
                            handleClose={() => setShowForgotCommunityMemberIdModal(false)}
                            isStudent={isForgotStudent}
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    </>
};

export default SignInForm;
