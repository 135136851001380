import Grid from "@mui/material/Grid2";
import {RhfTextField} from "mui-rhf-integration";
import {Alert, Button, CircularProgress, MenuItem} from "@mui/material";
import {states} from "@/Helpers/validationHelpers.ts";
import PhoneNumberField from "@/components/Forms/Fields/PhoneNumberField.tsx";
import GuardianForm from "@/components/Forms/GuardianForm.tsx";
import DigitalSignatureField from "@/components/Forms/Fields/DigitalSignatureField.tsx";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {SubmitHandler, useFormContext} from "react-hook-form";
import {useNotice} from "@/components/Providers/NoticeProvider.tsx";

type Props = {
    submitHandlerFunction: SubmitHandler<any>,
    communityMemberAlreadyExists: boolean,
    loading: boolean,
    requireSignature: boolean,
};
const CommunityMemberForm = ({submitHandlerFunction, communityMemberAlreadyExists, loading, requireSignature} : Props) => {
    const form = useFormContext();
    const [notice] = useNotice();

    return <form onSubmit={form.handleSubmit(submitHandlerFunction)}>
        <Grid container sx={{justifyContent: 'center'}}>
            <Grid size={{xs: 12}}>
                <h2 className='text-primary'>Community Member Registration Form</h2>
            </Grid>
        </Grid>
        <Grid container sx={{justifyContent: 'center'}}>
            <Grid size={{xs: 12}}>
                <h3 className='text-primary'>Step 1: Participant Information</h3>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid size={{xs: 12, lg: 4}}>
                <RhfTextField
                    control={form.control}
                    name={'nameFirst'}
                    label={'First Name'}
                    fullWidth
                />
            </Grid>
            <Grid size={{xs: 12, lg: 4}}>
                <RhfTextField
                    control={form.control}
                    name={'nameLast'}
                    label={'Last Name'}
                    fullWidth
                />
            </Grid>
            <Grid size={{xs: 12, lg: 4}}>
                <RhfTextField
                    control={form.control}
                    name={'dob'}
                    label={'Date of Birth'}
                    type={'date'}
                    fullWidth
                />
            </Grid>
            <Grid size={{xs: 12, lg: 4}} className={'align-without-label'}>
                <RhfTextField
                    control={form.control}
                    name={'grade'}
                    label={notice.gradeLabel}
                    select
                    fullWidth
                >
                    {['Pre-K', 'K', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Adult'].map((grade) =>
                        <MenuItem value={grade.toString()} key={grade.toString()}>{grade.toString()}</MenuItem>
                    )}
                </RhfTextField>
                <p>{notice.gradeDescription}</p>
            </Grid>
            <Grid size={{xs: 12, lg: 4}}>
                <RhfTextField
                    control={form.control}
                    name={'gender'}
                    label={'Gender'}
                    select
                    fullWidth
                >
                    <MenuItem value={'M'}>Male</MenuItem>
                    <MenuItem value={'F'}>Female</MenuItem>
                </RhfTextField>
            </Grid>
        </Grid>
        <Grid container sx={{my: 2}}>
            <Grid size={{xs: 12, lg: 9}}>
                <RhfTextField
                    control={form.control}
                    name={'address'}
                    label={'Street Address'}
                    fullWidth
                />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid size={{xs: 12, lg: 4}}>
                <RhfTextField
                    control={form.control}
                    name={'city'}
                    label={'City'}
                    fullWidth
                />
            </Grid>
            <Grid size={{xs: 12, lg: 4}}>
                <RhfTextField
                    control={form.control}
                    name={'state'}
                    label={'State'}
                    select
                    fullWidth
                >
                    {states.map((state) =>
                        <MenuItem value={state} key={state}>{state}</MenuItem>
                    )}
                </RhfTextField>
            </Grid>
            <Grid size={{xs: 12, lg: 3}}>
                <RhfTextField
                    control={form.control}
                    name={'zip'}
                    label={'Zip Code'}
                    fullWidth
                />
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{my:2}}>
            <Grid size={{xs: 12, lg: 4}}>
                <PhoneNumberField
                    label={'Home Phone Number'}
                    name={'homePhone'}
                />
            </Grid>
            <Grid size={{xs: 12, lg: 5}}>
                <RhfTextField
                    control={form.control}
                    name={'email'}
                    label={'Email Address'}
                    fullWidth
                />
            </Grid>
        </Grid>
        <Grid container sx={{mt: 4, justifyContent: 'center'}}>
            <Grid size={{xs: 12}}>
                <h3 className='text-primary'>Step 2: Parent/Guardian and Emergency Contact Information</h3>
                <span className='text-primary'>All participants must have two contacts on file.</span>
            </Grid>
        </Grid>
        <Grid container>
            <Grid size={{xs: 12}}>
                <GuardianForm/>
            </Grid>
        </Grid>
        {requireSignature && <>
            <Grid container sx={{justifyContent: 'center', mt: 2}}>
                <Grid size={{xs: 12}}>
                    <h3 className='text-primary'>Step 5: Waiver</h3>
                </Grid>
            </Grid>
            <Grid container >
                <Grid size={{xs: 12}}>
                    <div>
                        By signing below, I acknowledge that all of the information contained or associated with this application is accurate and truthful. You acknowledge that by signing up and participating in this program(s), you will be waiving and releasing all claims for injuries you might sustain arising out of this program. As a participant in the program(s), I recognize and acknowledge that there are certain risks of physical injury and I agree to assume the full risk of injuries, damages or loss which I may sustain as a result of participating in any and all activities with or associated with such program(s). I agree to waive and relinquish all claims that I may have as a result of participating in the program(s) against Glenbrook High School District 225 and its officers, agents, and employees. I do hereby release and discharge the the school district and its officers, agents, and employees from any and all claims from injuries, damage, or loss which may accrue to me on account of my participation in the program(s), including transportation services and vehicle operations, when provided. I further agree to indemnify and hold harmless and defend the school district and its officers, agents, and employees from any and all claims resulting from injuries, damages, and losses sustained by me and arising out of, connected with, or in any way associated with the activities of the program(s). I have read and fully understand the above Program Details and Waiver and Release of all Claims. Participant(s) or their legal guardian must sign this Waiver.
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid size={{xs: 12}} sx={{mt: 3}}>
                    <RhfTextField
                        control={form.control}
                        name={'printedSignature'}
                        label={'Parent/Guardian Name'}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid size={{xs: 12}} sx={{mt: 3}}>
                    <DigitalSignatureField
                        name="digitalSignature"
                        label="Signature"
                    />
                </Grid>
            </Grid>
        </>}
        {form.formState.isSubmitted && !form.formState.isValid && <Grid container>
            <Grid size={{xs: 12}} sx={{mt: 1}}>
                <Alert severity="error">Please fix the validation issues above</Alert>
            </Grid>
        </Grid>}
        {communityMemberAlreadyExists && <Grid container>
            <Grid size={{xs: 12}}>
                <Alert severity="error">
                    A community member account already exists. Please use your community member credentials,
                    or click 'forgot password' to retrieve that information.
                </Alert>
            </Grid>
        </Grid>}
        <Grid container>
            <Grid size={{xs: 12}} sx={{mt: 2}}>
                <Button
                    // variant={formIsModified ? (!form.formState.isValid ? 'danger' : 'secondary') : (!form.formState.isValid ? 'outline-danger' : 'outline-primary')}
                    variant={'contained'}
                    type="submit"
                    className="d-flex pt-2 flex-row align-content-center"
                    disabled={form.formState.isLoading}
                >
                    {loading ? (
                        <React.Fragment>
                            <CircularProgress size="sm" className="mt-1" />
                            &nbsp; Saving
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <FontAwesomeIcon icon={faSignInAlt} size="sm" className="mt-1" />
                            &nbsp; Submit
                        </React.Fragment>
                    )}</Button>
            </Grid>
        </Grid>
    </form>
}

export default CommunityMemberForm;
