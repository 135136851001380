import {Box, Button, Link, Paper, Typography} from '@mui/material';
import {ActionType, UserType} from "@/types/auth.ts";
import PrimaryButton from "@/components/PrimaryButton.tsx";


interface ActionTypeSelectionProps {
  onSelect: (type: ActionType) => void;
  onBack: () => void;
  onSkipToLogin: () => void;
  userType: UserType;
}

export const ActionTypeSelection = ({ onSelect, onBack, onSkipToLogin, userType }: ActionTypeSelectionProps) => {
  const getOptionsForUserType = (type: UserType) => {
    switch (type) {
      case 'faculty':
        return [
          { value: 'view-activities', label: 'View My Activities' },
          { value: 'fee-request', label: 'Submit a Fee Request' },
          { value: 'staff-fees', label: 'I want to pay Fees' },
        ];
       case 'guest':
         return [
           //{ value: 'donate', label: 'I want to Make a Donation' },
         ];
      case 'community':
        return [
          { value: 'fees', label: 'I want to pay Fees' },
          { value: 'register', label: 'Register for Programs' },
          // { value: 'donate', label: 'I want to Make a Donation' },
        ];
      default:
        return [
          { value: 'fees', label: 'Pay Fees or Purchase Items' },
          { value: 'register', label: 'Register for Programs' },
          // { value: 'donate', label: 'I want to Make a Donation' },
        ];
    }
  };

  const options = getOptionsForUserType(userType);

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%' }}>
      <Typography variant="h5" component="h2" gutterBottom align="center">
        {userType === 'guest' ? <>Coming Soon</> : <>What would you like to do?</>}
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} mt={3}>
        {options.map((option) => (
          <PrimaryButton
            key={option.value}
            onClick={() => onSelect(option.value as ActionType)}
            sx={{ py: 1.5 }}
          >
            {option.label}
          </PrimaryButton>
        ))}
        <Button variant="outlined" fullWidth onClick={onBack} sx={{ mt: 2, py: 1.5}}>
          Back
        </Button>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link
            component="button"
            variant="body2"
            onClick={onSkipToLogin}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Skip questionnaire and go directly to login
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};
