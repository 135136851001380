import {useMemo} from "react";
import {AssessedFee} from "@/types/AssessedFee.ts";

export const useGroupFees = (assessedFees: AssessedFee[]) => {
    return useMemo(() => {
        return assessedFees.reduce<AssessedFee[][]>((accumulator, assessedFee) => {
            const accumulatorIndex = accumulator.findIndex(
                e => e.length > 0 && e[0].fee.id === assessedFee.fee.id
            );
            if (accumulatorIndex >= 0) {
                accumulator[accumulatorIndex].push(assessedFee);
            } else {
                accumulator.push([assessedFee]);
            }
            return accumulator;
        }, []).sort((a, b) => {
            if (a.length < 1) {
                return -1;
            }
            if (b.length < 1) {
                return 1;
            }

            return a[0].fee.feeCategory.toUpperCase().localeCompare(b[0].fee.feeCategory.toUpperCase());
        });
    }, [assessedFees]);
}
