import {Nav, Navbar} from "react-bootstrap";
import NavBase from './NavBase';
import NavButton from "@/components/Navigation/NavButton.tsx";
import {Badge, PersonAdd} from "@mui/icons-material";

type Props = {
    showNav ?: boolean;
}

const SignInHeader = ({showNav} : Props) => {
    return (
        <NavBase>
            <div className="text-right d-flex flex-row">
                <Navbar.Toggle aria-controls="chris-nav" />
                {showNav && (
                <Navbar.Collapse id="glenbrook-nav" className="">
                    <Nav className="mr-auto">
                        <NavButton
                            text='Create Community Member Account'
                            link='/register-community-member'
                            icon={<PersonAdd/>}
                        />
                        <NavButton
                            text='Instructor / Faculty Login'
                            link='/instructor/sign-in'
                            icon={<Badge/>}
                        />
                    </Nav>
                </Navbar.Collapse>
                )}
            </div>
        </NavBase>
    );
};

export default SignInHeader;
