import {Col, Container, Row} from "react-bootstrap";
import ModalBase from "./ModalBase";
import {useNotice} from "../Providers/NoticeProvider";
import ReactMarkdown from "react-markdown";

type Props = {
    show: boolean;
    handleClose: () => void;
};

const CourseQuestionModal = ({
    show,
    handleClose,
} : Props) => {
    const [notice] = useNotice();
    return (
        <ModalBase
            show={show}
            title={"Instructions"}
            hideConfirmButton={true}
            hideCloseButton={false}
            handleClose={handleClose}
        >
            <Container>
                <Row className="chris-form-bg mx-0 py-1 px-2">
                    <hr/>
                    <Col xs={12}>
                        <ReactMarkdown className="markdown">{notice.instructorInstructions}</ReactMarkdown>
                    </Col>
                </Row>
            </Container>
            <Col xs={12}><hr /></Col>
        </ModalBase>);
}

export default CourseQuestionModal;
