import moment from "moment";
import {z} from "zod";


export const optionalPhoneValidation = z
    .string()
    .refine(
        (val) => {
            return val === '' || /^\+1[0-9]{10}$/.test(val)
        },
        {
            message: 'Must be a full US phone number',
        }
    )
    .transform((value) => {
        return value === '' ? null : value;
    })
    .nullable();

export const phoneValidation = z
    .string()
    .regex(
        /\+1[0-9]{9}/,
        'Must be a full US phone number'
    )
    .transform((value) => {
        return value === '' ? null : value;
    })
    .nullable();


export const zodDateRegex = z
    .string()
    .transform((value) => {
        if (value === null || value === '') {
            return null;
        }

        if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            return moment(value).format('YYYY-MM-DD');
        }

        return null;
    })
    .nullable();

export const postalCodeUS = z.string().regex(/^\d{5}([ -]\d{4})?/, 'Invalid postal code.');

export const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming",];

export const isState = z.string().refine(str => states.includes(str), {message: 'Invalid state.'});

export const guardianValidator = z.array(z.object({
        id: z.string().optional(),
        nameFirst: z.string().transform(value => value === null ? '' : value),
        nameLast: z.string().transform(value => value === null ? '' : value),
        relationship: z.string().transform(value => value === null ? '' : value),
        relationshipOther: z.string().optional().transform(value => value === null ? '' : value),
        phone1: phoneValidation,
        phone1Type: z.string().min(1, 'You must select a phone type'),
        phone2: optionalPhoneValidation,
        phone2Type: z.string().transform(value => value === null ? '' : value),
        email: z.string().email().transform(value => value === null ? '' : value)
    })
        .refine((obj) =>
                obj.relationship !== 'Other' || (obj.relationship === 'Other' && obj.relationshipOther),
            {path: ['relationshipOther'], message: 'You must enter Relationship Other when Relationship is Other.'})
        .refine((obj) => {
            return obj.phone2 === null && obj.phone2Type === '' || obj.phone2 && obj.phone2Type;
        },
            {path: ['phone2Type'], message: 'You must enter Phone 2 Type when Phone 2 is entered.'})
).min(2, {message: "You must have at least two guardians."});
