import {closeFMWindow} from "@/utils/fmScripts.ts";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import useConfirmMailIn from "@/hooks/useConfirmMailIn.tsx";
import { useCallback } from "react";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {useNavigate} from "@tanstack/react-router";
import {useUser} from "@/components/Providers/JWTProvider.tsx";

const useHandleMailInSubmit = ()=>  {
    const {cart, refreshCart, cartPaymentSuccess} = useCartContext();
    const {isEmployee} = useUser();
    const confirmMailIn = useConfirmMailIn();
    const {refreshPurchaseHistory, doGetStudentFees} = useFeeProvider();
    const navigate = useNavigate();

    return useCallback(async (payNowAmount: number) => {
        if (cart?.cartItems.length && cart.cartItems.length > 0) {
            const confirmResponse = await confirmMailIn([], cart.id);
            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json();
                if (responseJson) {
                    let hasFees = false;
                    if (cart.cartItems.find(i => i.feeId !== undefined) !== undefined) {
                        hasFees = true;
                        await Promise.all([doGetStudentFees(), refreshPurchaseHistory()])
                    }

                    await refreshCart();
                    await cartPaymentSuccess(`Mail In payment of $${payNowAmount.toString()} submitted.`);

                    closeFMWindow();

                    await navigate({
                        to: isEmployee ? '/instructor/fees' : hasFees ? '/fees' : '/my-registrations',
                        search: {purchased: 'purchased'},
                    });
                }
            }
        }
    }, [cart, refreshCart, cartPaymentSuccess, confirmMailIn, refreshPurchaseHistory, doGetStudentFees, navigate, isEmployee])
}

export default useHandleMailInSubmit;
