import {RhfCheckbox} from "mui-rhf-integration";
import {useForm} from "react-hook-form";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect} from "react";
import {FormControlLabel} from "@mui/material";

type Props = {
    showPartialPayments : boolean;
    setShowPartialPayments : (showPartialPayments : boolean) => void;
};

const schema = z.object({
    showPartialPayments: z.boolean()
});

type PartialPaymentsCheckboxSchema = z.infer<typeof schema>;

const PartialPaymentsCheckbox = ({showPartialPayments, setShowPartialPayments} : Props) => {
    const form = useForm<PartialPaymentsCheckboxSchema>({
        defaultValues: {showPartialPayments},
        resolver: zodResolver(schema)
    });

    const showPpWatch = form.watch('showPartialPayments');

    useEffect(() => {
        setShowPartialPayments(showPpWatch);
    }, [showPpWatch]);

    return (
        <FormControlLabel
            control={<RhfCheckbox name={'showPartialPayments'} control={form.control}/>}
            label={'View partially completed payments'}
            sx={{width: '100%', pl: 2}}
        />
    );
};

export default PartialPaymentsCheckbox;
