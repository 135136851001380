import * as React from 'react'
import { createLink, LinkComponent } from '@tanstack/react-router'
import { Link, LinkProps } from '@mui/material'

interface MUILinkProps extends Omit<LinkProps, 'href'> {
}

const MUILinkComponent = React.forwardRef<HTMLAnchorElement, MUILinkProps>(
    (props, ref) => {
        return <Link component={'a'} ref={ref} {...props} />
    },
)

const CreatedLinkComponent = createLink(MUILinkComponent)

const TanMuiLink: LinkComponent<typeof MUILinkComponent> = (props) => {
    return <CreatedLinkComponent preload={'intent'} {...props} />
}

export default TanMuiLink;
