import {useCallback, useContext, useState} from 'react';
import {JWTInstructor, jwtInstructorContext, useAuthenticateInstructor,} from "../../components/Providers/JWTProvider";
import InstructorSignInForm, {InstructorSignInFormType} from "../../components/Forms/InstructorSignInForm";
import SignInHeader from "@/components/Navigation/SignInHeader.tsx";
import {Stack} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useNavigate} from "@tanstack/react-router";
import GlenSnackBar from "@/components/GlenSnackBar.tsx";

export const signInError = 'Invalid Username or Password';
const allowedRoutes = ['/instructor', '/instructor/fee-proposals', '/instructor/fees'];

const InstructorSignIn = () => {
    const {setUser} = useContext(jwtInstructorContext);
    const authenticateInstructor = useAuthenticateInstructor();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleSignInSubmit = useCallback(async (values : InstructorSignInFormType) => {
        const user : JWTInstructor | null = await authenticateInstructor(values.username, values.password);
        if (user) {
            setUser(user);
            if (!allowedRoutes.includes(window.location.pathname)) {
                if (user.role.includes('instructor')) {
                    await navigate({to: '/instructor/'});
                } else if (user.role.includes('fee_admin')) {
                    await navigate({to: '/instructor/fee-proposals'});
                } else {
                    await navigate({to: '/instructor/'});
                }
            }//else wait for the next render due to setUser above
        } else {
            setShowAlert(true);
        }
    }, [authenticateInstructor, setShowAlert]);

    const handleClearAlert = useCallback(async () => {
        setShowAlert(false);
    }, [setShowAlert]);

    return (
        <>
            <SignInHeader showNav={true} />
            <Grid container justifyContent={'center'}>
                <Stack sx={{justifyContent: 'center', alignItems: 'center', p: 5}}>
                    <Stack sx={{maxWidth: '500px'}}>
                        <GlenSnackBar
                            onClose={handleClearAlert}
                            show={showAlert}
                            severity={'error'}
                            message={signInError}/>
                        <InstructorSignInForm handleSignInSubmit={handleSignInSubmit}/>
                    </Stack>
                </Stack>
            </Grid>
        </>
    );
};

export default InstructorSignIn;
